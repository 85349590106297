import React, {useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import DepositManually from "./DepositManually";
import { columns } from "./columns";
import { colorHex } from "../../../utils/constants";
import ImageModal from "./ImageModal";
import { useGetDepositListMutation } from "../../../api/mainApi/mainApiSlice";
import DepositList from "./DepositList";

interface DepositListInterface {
  image: string;
  time: string;
  status: "Rejected" | "Pending" | "APPROVED" ;
  amount: number;
}

const colorStatus = {
  Rejected: "error.main",
  Pending: "warning.main",
  APPROVED: "success.main",
};

const Deposit = () => {
  const [getDepositList, {data:depositList}] = useGetDepositListMutation()
  const [imageSelected, setImageSelected] = useState("");

  const handleClose = () => {
    setImageSelected("");
  };

  useEffect(() => {
    getDepositList();
  }, []);
  return (
      <Box
        marginX="auto"
        rowGap={5}
        position="relative"
        display={"flex"}
        flexDirection="column"
        width={{ xs: "95%" }}
        minHeight={"90vh"}
      >
        <DepositManually getDepositList={getDepositList} />
        <ImageModal
          open={Boolean(imageSelected)}
          src={imageSelected}
          handleClose={handleClose}
        />
        <DepositList data={depositList?.data} setImageSelected={setImageSelected}/>
      </Box>
  );
};

export default Deposit;

export function StatusTypography({
  status,
}: {
  status: DepositListInterface["status"];
}) {
  return (
    <Typography
      sx={{
        fontSize: { xs: "0.8rem", lf: "0.9rem" },
        bgcolor: colorHex.bg3,
        borderRadius: 1,
        p: 0.5,
        width: "min-content",
      }}
      color={colorStatus[status]}
    >
      {status}
    </Typography>
  );
}
