import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import mainApi from "./api/mainApi/mainApiSlice";
import authReducer from "./features/auth/authslice";
import casinoReducer from './features/casino/casinoslice'
import betFairApiSlice from "./api/betFairApi/betFairApiSlice";
import qtechApiSlice from "./api/qtech/qtechApiSlice";
import sportdatalength from './api/sportdatalength/sportdataslice'

export const store = configureStore({
    reducer: {
        auth: authReducer,
        sportdatalength,
        casino: casinoReducer,
        [mainApi.reducerPath]: mainApi.reducer,
        [betFairApiSlice.reducerPath]: betFairApiSlice.reducer,
        [qtechApiSlice.reducerPath]: qtechApiSlice.reducer

    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(mainApi.middleware).concat(betFairApiSlice.middleware).concat(qtechApiSlice.middleware),

    devTools: process.env.NODE_ENV !== 'production', // Enable Redux DevTools only in development
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

setupListeners(store.dispatch);
