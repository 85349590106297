import {
  Box,
  TextField,
  Button,
  InputLabel,
  InputAdornment,
  Typography,
  Checkbox,
  FormControlLabel,
} from "@mui/material"
import { useForm } from "react-hook-form"
import LockIcon from "@mui/icons-material/Lock"
import Person from "@mui/icons-material/person"

import "./loginstyle.css"
import type { FC} from "react";
import { useEffect, useState } from "react"
import { Login } from "@mui/icons-material"
import { FaEye } from "react-icons/fa"
import { FaEyeSlash } from "react-icons/fa"
import Logo from "/images/fairplayofficial.png"
import {
  useDemologinMutation,
  useGetdataQuery,
  useIsSelfByAppUrlMutation,
  useLoginMutation,
} from "../../../api/mainApi/mainApiSlice"
import { useNavigate } from "react-router"
import snackBarUtil from "../../../utils/snackbar"
import { SnackbarContent } from "notistack"
import { useMyIpQuery } from "../../../api/betFairApi/betFairApiSlice"
import { useAppDispatch } from "../../../hooks/useAppDispatch"
import { demoLogin, login } from "../../../features/auth/authslice"

interface LoginPageProps {
  isSelfData: isSelfRes | undefined
}

const LoginPage: FC<LoginPageProps> = ({ isSelfData }) => {
  const navigate = useNavigate()
  const [showPass, setShowPass] = useState(false)
  const [checked, setKeepLoggedIn] = useState<boolean>(false)
  const [LoginTrigger, { data: loginData }] = useLoginMutation()
  const { data } = useMyIpQuery()

  const dispatch = useAppDispatch()
  const [demoLoginTrigger, { data: demoLoginData }] = useDemologinMutation();
  const { data: getData, isLoading } = useGetdataQuery({
    // appUrl: "fairplays24.com",
    appUrl: window.location.hostname,
  })

  const {
    handleSubmit,
    reset,
    register,
    formState: { errors },
  } = useForm({
    defaultValues: {
      userId: "",
      password: "",
      appUrl: window.location.hostname,
      // appUrl: "fairplays24.com",
    },
  })

  const handleShowPass = () => {
    setShowPass(!showPass)
  }

  const handleDemoLogin = () => {
    demoLoginTrigger({
      appUrl: window.location.hostname,
      // appUrl: "fairplays24.com",
    })
  }

  useEffect(() => {
    if (demoLoginData) {
      if (demoLoginData?.status === false) {
        snackBarUtil.error(demoLoginData?.message)
        return
      } else if (demoLoginData?.token) {
        dispatch(demoLogin(demoLoginData))
        navigate("/")
        localStorage.setItem("loginData", JSON.stringify(demoLoginData))
      }
    }
  }, [demoLoginData])

  useEffect(() => {
    if (loginData) {
      if (loginData?.status === false) {
        snackBarUtil.error(loginData?.message)
        return
      } else if (loginData?.token) {
        dispatch(login(loginData))
        navigate("/")
      }
    }
  }, [loginData])

  useEffect(() => {
    if (data) {
    }
  }, [data])

  const onSubmit = async (data: any) => {
    const { userId, password, appUrl } = data
    const payload = {
      userId,
      password,
      appUrl,
      checked,
    }

    try {
      const response = await LoginTrigger(payload).unwrap()

      if (response.token) {
        localStorage.setItem("token", response.token)
        localStorage.setItem("loginData", JSON.stringify(response))
        snackBarUtil.success(
          <SnackbarContent role="alert" style={{ width: "800px" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <Typography
                sx={{ color: "#fff", fontSize: "16px", textAlign: "left" }}
              >
                Success!
              </Typography>
              <Typography
                sx={{ color: "#fff", fontSize: "12px", textAlign: "left" }}
              >
                Successfully Login
              </Typography>
            </Box>
          </SnackbarContent>,
        )
        navigate("/")
      } else {
        snackBarUtil.error(
          <SnackbarContent role="alert" style={{ width: "800px" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <Typography
                sx={{ color: "#fff", fontSize: "16px", textAlign: "left" }}
              >
                failed!
              </Typography>
              <Typography
                sx={{ color: "#fff", fontSize: "12px", textAlign: "left" }}
              >
                Login failed
              </Typography>
            </Box>
          </SnackbarContent>,
        )
      }
    } catch (error) {
      console.log(`login error ${error}`)
    }

    reset()
  }


  // console.log(isSelfData?.data?.selfAllowed, "isSelfDataisSelfDataisSelfData")
  return (
    <Box
      className="bg-img-holder"
      sx={{
        backgroundImage: `url("https://s3buket.blr1.cdn.digitaloceanspaces.com/images/login-background.webp")`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "50%",
        padding: "50px 0",
      }}
    >
      <Box
        className="form-holderr"
        sx={{
          maxWidth: 480,
          margin: "auto",
          backgroundColor: "#000000B3",
          padding: 3,
          borderRadius: "5px",
          border: "2px solid #f36c21",
        }}
      >
        <div className="form-box">
          <div className="formlogoholder">
            <img
              src={isSelfData?.data?.logo}
              alt=""
              className="w-[150px] h-[40px] m-auto"
            />
          </div>
          <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <div className="relative">
              <label
                htmlFor="user-Id"
                className="mr-auto w-full block text-left text-[13px] text-white"
              >
                User ID
              </label>
              <Box
                className="input-wrapper"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <InputAdornment
                  position="start"
                  sx={{
                    // position: "absolute",
                    left: 10,
                    bottom: 35,
                    // transform: "translateY(-50%)",
                  }}
                >
                  <Person sx={{ color: "white" }} />
                </InputAdornment>
                <input
                  type="text"
                  id="user-id"
                  {...register("userId", {
                    required: "userId is required",
                  })}
                  autoComplete="off"
                  placeholder="Enter User id*"
                />
                {errors.userId && (
                  <Typography
                    sx={{
                      color: "red",
                      fontSize: "12px",
                      position: "absolute",
                      bottom: "-20px",
                    }}
                  >
                    {errors.userId.message}
                  </Typography>
                )}
              </Box>
            </div>
            <div className="passparent relative">
              <label
                htmlFor="password"
                className="mr-auto w-full block text-left text-[13px] lg:mt-8 mt-4 text-white"
              >
                Password <sup className="text-white text-lg top-0">*</sup>
              </label>
              <Box
                className="input-wrapper"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <InputAdornment
                  position="start"
                  sx={{
                    // position: "absolute",
                    left: 10,
                    bottom: 35,
                    // transform: "translateY(-50%)",
                  }}
                >
                  <LockIcon sx={{ color: "white" }} />
                </InputAdornment>

                <input
                  className="bg-white"
                  type={showPass ? "text" : "password"}
                  id="password"
                  {...register("password", {
                    required: "Password is required",
                  })}
                  autoComplete="new-password"
                  autoSave="off"
                  placeholder="Password"
                />

                <InputAdornment
                  onClick={handleShowPass}
                  position="end"
                  sx={{
                    cursor: "pointer",
                    position: "absolute",
                    fontSize: "20px",
                    right: 10,
                    bottom: 35,
                  }}
                >
                  {!showPass ? (
                    <FaEye color="#fff" />
                  ) : (
                    <FaEyeSlash color="#fff" />
                  )}
                </InputAdornment>

                {errors.password && (
                  <Typography
                    sx={{
                      color: "red",
                      fontSize: "12px",
                      position: "absolute",
                      bottom: "-20px",
                    }}
                  >
                    {errors.password.message}
                  </Typography>
                )}
              </Box>
            </div>
            <div className="flex mt-5 items-center">
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    onChange={event => setKeepLoggedIn(event.target.checked)}
                    checked={checked}
                    sx={{
                      color: "#f36c21",
                      "&.Mui-checked": {
                        color: "#f36c21",
                      },
                    }}
                  />
                }
                label="Keep Me Logged In"
                sx={{
                  color: "#fff",
                  fontSize: "14px",
                  "& .MuiFormControlLabel-label": {
                    fontSize: "14px",
                    fontWeight: 600,
                  },
                }}
              />
            </div>
            <button type="submit" className="w-full loginbtn my-2">
              LOGIN <Login fontSize="small" />
            </button>
            <button
              type="button"
              className="w-full demologinbtn"
              onClick={handleDemoLogin}
            >
              DEMO LOGIN
            </button>
            {/* <a
              // href={getData?.data?.s_whatsapp?.link}
              target="_blank"
              rel="noopener noreferrer"
              className="link-click-here"
            >


              <div className="click-here-btn"><img
                src="https://s3buket.blr1.cdn.digitaloceanspaces.com/images/wa-img.png"
                width={35}
                height={35}

                alt="walogo"
              />  </div>
            </a> */}
            {
              isSelfData?.data?.selfAllowed && <p className="what_icon">
              <a target="_blank"
               href={getData?.data?.s_whatsapp?.link}
                rel="noopener noreferrer">

                <div>
                  <img
                    src="https://s3buket.blr1.cdn.digitaloceanspaces.com/images/wa-img.png"
                    width={35}
                    height={35}

                    alt="walogo"
                  />
                </div>
                <div>
                  click here
                </div>

              </a>
            </p>
            }
            
          </form>
        </div>
      </Box>
    </Box>
  )
}

export default LoginPage
