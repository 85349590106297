import { Box } from "@mui/material"
import SecondHeadingBar from "../../../../common/SecondHeadingBar"
import ChangeMobileNoForm from "./ChangeMobileNoForm"

const ChangeMobileNo = () => {
  return (
    <Box className="change-mobile-no">
      <SecondHeadingBar title="Change Mobile Number" />

      <Box className="change-mobile-password">
        <Box className="reset-password-sec" sx={{}}>
          <ChangeMobileNoForm />
        </Box>
      </Box>
    </Box>
  )
}
export default ChangeMobileNo
