import { Box, Button, Grid, Typography } from "@mui/material"

const MatchBetCard = () => {
  return (
    <Box sx={{ my: 2, backgroundColor: "#fff", borderRadius: "3px" }}>
      <Box className="matched-detailcards">
        <Grid container sx={{ borderBottom: "1px solid #dadada", p: 1, my: 0 }}>
          <Grid item xs={6}>
            <Box
              className="horse-list-sec-left"
              sx={{
                height: "100%",
                display: "flex",
                alignItems: "flex-start",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Typography
                className="match-head"
                sx={{
                  color: "#000!important",
                  fontSize: { md: "16px", xs: "15px" },
                  padding: "0 4px 0 0",
                  float: "none",
                  lineHeight: "22px",
                  fontWeight: "600",
                }}
                component="h5"
              >
                Wade Keeping
              </Typography>

              <Typography
                className="match-head"
                sx={{
                  color: "#746969",
                  fontSize: { md: "14px", xs: "12" },
                  fontWeight: "400",
                  display: "inherit",
                  lineHeight: "20px",
                  textTransform: "capitalize",
                  marginTop: 0,
                }}
                component="h5"
              >
                Siphesihle Hlengwa
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box
              className="oddsbutton"
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Button
                sx={{
                  marginRight: "10px",
                  listStyle: "none",
                  background: "#d3ddcc",
                  border: "1px solid #f36c21",
                  borderRadius: "4px",
                  width: "100%",
                  display: "flex",
                  height: "auto",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                  textAlign: "center",
                  padding: { md: "5px 20px", xs: "3px 15px" },
                  lineHeight: "10px",
                  fontSize: "16px",
                  ":hover": {
                    background: "#d3ddcc",
                  },
                }}
              >
                <Typography
                  component="span"
                  sx={{
                    color: "#000",
                    fontSize: { md: "16px", xs: "12px" },
                    display: " block",
                    fontWeight: "600",
                  }}
                >
                  1.56
                </Typography>
                <Typography
                  component="span"
                  sx={{
                    color: "#000",
                    fontSize: { md: "14px", xs: "12px" },
                    display: " block",
                  }}
                >
                  415
                </Typography>
              </Button>
              <Button
                sx={{
                  marginRight: "10px",
                  listStyle: "none",
                  background: "#fff5f7",
                  border: "1px solid #f36c21",
                  borderRadius: "4px",
                  width: "100%",
                  display: "flex",
                  height: "auto",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                  textAlign: "center",
                  padding: "5px 20px",
                  lineHeight: "10px",
                  fontSize: "16px",
                  ":hover": {
                    background: "#fff5f7",
                  },
                }}
              >
                <Typography
                  component="span"
                  sx={{
                    color: "#000",
                    fontSize: { md: "16px", xs: "12px" },
                    display: " block",
                    fontWeight: "600",
                  }}
                >
                  1.56
                </Typography>
                <Typography
                  component="span"
                  sx={{
                    color: "#000",
                    fontSize: { md: "14px", xs: "12px" },
                    display: " block",
                  }}
                >
                  415
                </Typography>
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

export default MatchBetCard
