import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import Select, { SelectChangeEvent } from "@mui/material/Select"
import { Box, Button, Grid, TextField, Typography } from "@mui/material"
import {
  useActivesportlistQuery,
  useBetlisthistoryMutation,
  useProfitlossmatchwiseMutation,
} from "../../../../api/mainApi/mainApiSlice"
import dayjs from "dayjs"
import { useState, useEffect } from "react"
import SettledBetsTable from "./SettledBetsTable"
import NoData from "../../../../common/NoData"

const SettledBetsSearchBar = () => {
  const { data: sportlistdata } = useActivesportlistQuery()
  const [sportlist, setSportList] = useState<number>(4)
  const [isDeleted, setIsDeleted] = useState("false")
  const [fromDate, setFromDate] = useState(
    dayjs().subtract(14, "days").format("YYYY-MM-DD"),
  )
  const [toDate, setToDate] = useState(dayjs().format("YYYY-MM-DD"))

  const [betlisthistoryTrigger, { data: betlisthistoryData }] =
    useBetlisthistoryMutation()

  const isDeletedSports = [
    {
      isDeleted: false,
      name: "Matched",
    },
    {
      isDeleted: true,
      name: "Deleted",
    },
  ]

  const handleChange = (e: any) => {
    setSportList(e.target.value)
  }

  const handleChangeisDeleted = (e: any) => {
    setIsDeleted(e.target.value)
  }

  const handlerFromDate = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFromDate(e.target.value)
  }

  const handlerToDate = (e: React.ChangeEvent<HTMLInputElement>) => {
    setToDate(e.target.value)
  }

  useEffect(() => {
    betlisthistoryTrigger({
      fromDate: fromDate,
      index: 0,
      noOfRecords: 50,
      sportId: sportlist,
      toDate: toDate,
      isdeleted: isDeleted == "true" ? true : false,
    })
  }, [])

  const handleSearch = () => {
    betlisthistoryTrigger({
      fromDate: fromDate,
      index: 0,
      noOfRecords: 100,
      sportId: sportlist,
      toDate: toDate,
      isdeleted: isDeleted == "true" ? true : false,
    })
  }

  return (
    <div className="openbetinputs py-4 lg:py-0">
      <Grid
        container
        rowSpacing={{ xs: 1 }}
        columnSpacing={1}
        sx={{
          padding: { md: "25px 25px 0 25px", xs: "0 0 0px 0" },
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Grid
          item
          xs={6}
          md={2.5}
          sx={{ display: "flex", alignItems: "center" }}
        >
          <FormControl sx={{ m: 1, maxWidth: 314, width: "100%" }} size="small">
            <InputLabel
              id="game-type-select-small-label"
              sx={{ color: "#cccccc58", fontSize: { md: "1rem", xs: "12px" } }}
            >
              Game type
            </InputLabel>
            <Select
              sx={{
                borderRadius: "30px",
                fontSize: { md: "1rem", xs: "12px" },
              }}
              labelId="game-type-select-small-label"
              id="game-type-select-small"
              value={sportlist}
              label="game type"
              onChange={handleChange}
            >
              {sportlistdata?.data?.map(activeData => (
                <MenuItem
                  key={activeData?.sportId}
                  sx={{ fontSize: { md: "1rem", xs: "12px" } }}
                  value={activeData?.sportId.toString()}
                >
                  {activeData?.sportName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid
          item
          xs={6}
          md={2.5}
          sx={{ display: "flex", alignItems: "center" }}
        >
          <FormControl sx={{ m: 1, maxWidth: 314, width: "100%" }} size="small">
            <InputLabel
              id="game-type-select-small-label"
              sx={{ color: "#cccccc58", fontSize: { md: "1rem", xs: "12px" } }}
            >
              Match type
            </InputLabel>
            <Select
              sx={{
                borderRadius: "30px",
                fontSize: { md: "1rem", xs: "12px" },
              }}
              labelId="game-type-select-small-label"
              id="game-type-select-small"
              value={isDeleted}
              label="match type"
              onChange={handleChangeisDeleted}
            >
              {isDeletedSports?.map((activeData, idx) => (
                <MenuItem
                  key={idx}
                  sx={{ fontSize: { md: "1rem", xs: "12px" } }}
                  value={activeData?.isDeleted.toString()}
                >
                  {activeData?.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6} md={2} sx={{ display: "flex", alignItems: "center" }}>
          <TextField
            className="calendarcls"
            label={"From Dates"}
            sx={{
              fontSize: { md: "1rem", xs: "12px" },
              maxWidth: 314,
              width: "100%",
              "& .MuiInputBase-root": {
                borderRadius: "30px",
                fontSize: { md: "1rem", xs: "12px" },
              },
            }}
            value={fromDate}
            onChange={handlerFromDate}
            type="date"
            size="small"
            InputLabelProps={{
              sx: { color: "#cccccc58", fontSize: { md: "1rem", xs: "12px" } },
            }}
          />
        </Grid>
        <Grid item xs={6} md={2} sx={{ display: "flex", alignItems: "center" }}>
          <TextField
            className="calendarcls"
            label={"To Dates"}
            sx={{
              fontSize: { md: "1rem", xs: "12px" },
              maxWidth: 314,
              width: "100%",
              "& .MuiInputBase-root": {
                borderRadius: "30px",
                fontSize: { md: "1rem", xs: "12px" },
              },
            }}
            value={toDate}
            onChange={handlerToDate}
            type="date"
            size="small"
            InputLabelProps={{
              sx: { color: "#cccccc58 ", fontSize: { md: "1rem", xs: "12px" } },
            }}
          />
        </Grid>
        <Grid
          item
          xs={6}
          md={3}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Button
            onClick={handleSearch}
            disableFocusRipple
            disableTouchRipple
            disableRipple
            sx={{
              borderRadius: "30px",
              width: "100%",
              display: "inline-block",
              color: "#fff",
              textTransform: "uppercase",
              fontSize: { md: "13px", xs: "12px" },
              textAlign: "center",
              backgroundColor: "#fc6321",
              fontWeight: "600",
              minHeight: { md: "40px", xs: "35px" },
              border: "2px solid #fc6321",
              maxWidth: 314,
              ":hover": {
                backgroundColor: "#fc6321",
                border: "2px solid #fc6321",
              },
            }}
            variant="outlined"
            fullWidth
          >
            search
          </Button>
        </Grid>

        <Grid
          container
          className="datashown"
          sx={{
            margin: { md: "0 0 0px 0", xs: "10px 0" },
          }}
        >
          <Box sx={{ width: "100%" }}>
            {betlisthistoryData?.data?.dataList &&
            betlisthistoryData?.data?.dataList.length > 0 ? (
              <SettledBetsTable betlisthistoryData={betlisthistoryData} />
            ) : (
              <NoData />
            )}
          </Box>
        </Grid>
      </Grid>
    </div>
  )
}

export default SettledBetsSearchBar
