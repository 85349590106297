import {
  Box,
  Button,
  Card,
  CircularProgress,
  Container,
  Dialog,
  Grid,
  Typography,
} from "@mui/material"

import {
  useGamelinkMutation,
  useQtechgamelistQuery,
  useQtechproviderQuery,
} from "../../api/qtech/qtechApiSlice"
import { useLocation } from "react-router"
import { useEffect, useState } from "react"
import NoData from "../../common/NoData"
import { isBrowser, isMobile } from "react-device-detect"
import CloseIcon from "@mui/icons-material/Close"

const CrashCasinoGame = () => {
  const token = window.localStorage.getItem("token")
  const auth_Token = window.localStorage.getItem("authToken")
  const [iframeModalOpen, setIframeModalOpen] = useState(false)
  const [gamelistPayload, setGameListPayload] = useState({
    gameCategory: "INSTANTWIN",
    provider: "SPB",
    token: auth_Token || "",
  })
  const location = useLocation()

  const { data: providerData } = useQtechproviderQuery(
    {
      gameType: location?.state?.casinoName,
    },
    { skip: !token },
  )

  const instantWinArr: any[] = []
  const extracting = (data: any, data2: any) => {
    data?.forEach((element: any) => {
      if (element?.id === 146) {
        instantWinArr.push(element)
      }
    }) || []

    data2?.forEach((element: any) => {
      if (element?.id === 138 || element?.id === 106 || element?.id === 139) {
        instantWinArr.push(element)
      }
    }) || []

    return instantWinArr
  }

  extracting(providerData?.data?.instantWin, providerData?.data?.slot)

  const {
    data: gamelistdata,
    isLoading,
    isFetching,
  } = useQtechgamelistQuery(gamelistPayload, { skip: !token })

  const [gamelinkTrigger, { data: gamelinkData, isLoading: loading }] =
    useGamelinkMutation()

  const handleGameListPayload = (payloaddata: any) => {
    setGameListPayload({
      gameCategory: payloaddata?.gameType,
      provider: payloaddata?.providerId,
      token: auth_Token || "",
    })
  }

  const handleIframeModal = (gameId: string) => {
    gamelinkTrigger({
      birthDate: "1986-01-01",
      country: "IN",
      currency: "INR",
      device: `${(isMobile && "mobile") || (isBrowser && "desktop")}`,
      gameName: gameId,
      gender: "M",
      lang: "en_IN",
      mode: "real",
      playerId: "121212",
      returnUrl: window.location.origin,
      token: auth_Token || "",
      walletSessionId: token || "",
    })
  }

  useEffect(() => {
    if (gamelinkData?.status) {
      setIframeModalOpen(true)
    }
  }, [gamelinkData])

  const TabButton = ({ casinodata }: any) => {
    return (
      <Button
        onClick={() => handleGameListPayload(casinodata)}
        variant="outlined"
        size="small"
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          justifyContent: "center",
          minWidth: { md: "200px", xs: "100px" },
          textWrap: "nowrap",
          width: { md: "300px", xs: "49%" },
          padding: { md: ".6rem 0", xs: ".2rem 0" },
          backgroundColor: "#ffffff25",
          gap: 1,
        }}
      >
        <img src={casinodata?.image} alt="" className="h-[18px] w-auto" />
        <Typography component="p" sx={{ fontSize: { md: "15px", xs: "12px" } }}>
          {casinodata?.providerName}
        </Typography>
      </Button>
    )
  }

  const ModalCasino = () => {
    return (
      <Dialog
        sx={{
          height: "100vh",
          width: "100vw",
          maxWidth: "100vw",
          maxHeight: "100vh",
          m: 0,
          p: 0,
        }}
        open={iframeModalOpen}
        onClose={() => setIframeModalOpen(false)}
        fullScreen
      >
        <Box sx={{ width: "100%", height: "100%" }}>
          <Box
            className="closebutton"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              backgroundColor: "#f36c21",
              p: 2,
              borderBottom: "1px solid #fff",
              width: "100%",
            }}
          >
            <CloseIcon
              onClick={() => setIframeModalOpen(false)}
              sx={{
                p: 0.2,
                height: "30px",
                width: "30px",
                color: "#f36c21",
                borderRadius: "10%",
                backgroundColor: "#fff",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            />
          </Box>

          <iframe
            allowFullScreen
            src={gamelinkData?.data?.url}
            style={{ height: "100%", width: "100%", border: "none" }}
            title="desktop"
          />
        </Box>
      </Dialog>
    )
  }

  return (
    <Box className="livecasino">
      <ModalCasino />
      <Box className="cardholder">
        <Box className="tabcontainer" sx={{ mx: { md: 13, xs: 1 } }}>
          <Box
            className="tabs-here"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              width: "100%",
              flexWrap: { md: "nowrap", xs: "nowrap" },
              gap: "0.2rem",
              flexDirection: "row",
              my: 2,
              overflowY: "hidden",
              overflowX: "scroll",
            }}
          >
            {instantWinArr?.map((data: any, idx: number) => (
              <TabButton casinodata={data} key={idx} />
            ))}
            {providerData && !(providerData?.data?.instantWin?.length > 0) && (
              <NoData />
            )}
          </Box>
        </Box>

        <Container maxWidth="lg" sx={{ textAlign: "center" }}>
          {isLoading && isFetching ? (
            <CircularProgress size={"100px"} color="primary" />
          ) : (
            <Grid container spacing={1} sx={{ py: 2 }}>
              {gamelistdata?.data?.items?.map((item, idx) => (
                <Grid item md={2} xs={4} key={idx + 1}>
                  <Card
                    onClick={() => handleIframeModal(item?.id)}
                    sx={{
                      overflow: "hidden",
                      borderRadius: "10px",
                      cursor: "pointer",
                      border: "2px solid #f36c21 !important",
                    }}
                  >
                    <img
                      key={idx}
                      src={item?.images?.[1]?.url}
                      alt={item?.images?.[1]?.type}
                      className="w-full h-full max-w-full rounded-[10px]"
                    />
                  </Card>
                </Grid>
              ))}
              {!gamelistdata && <NoData />}
            </Grid>
          )}
        </Container>
      </Box>
    </Box>
  )
}

export default CrashCasinoGame
