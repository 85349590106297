import * as React from "react"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import Select, { SelectChangeEvent } from "@mui/material/Select"
import { Box, Button, Grid, Slide, Tab, Tabs, TextField } from "@mui/material"
import moment from "moment"
import MatchedBetsAccordion from "./MatchedBetsAccordionSports"
import MatchedDeletedRadio from "./MatchedDeletedRadioSports"
import ShowEntriesSelect from "./ShowEntriesSelectSports"
import MatchedBetsAccordionSports from "./MatchedBetsAccordionSports"
import MatchedBetsAccordionCasino from "./MatchedBetsAccordionCasino"

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

const SearchBar = () => {
  // const [age, setAge] = React.useState("cricket")
  const [tabValue, setTabValue] = React.useState(0)
  // const [show, setShow] = React.useState<boolean>(false)
  // const [fromDate, setFromDate] = React.useState(
  //   moment().subtract(2, "days").format("YYYY-MM-DD"),
  // )
  // const [toDate, setToDate] = React.useState(moment().format("YYYY-MM-DD"))

  const tabsName = ["sports", "casino"]

  // const handleChange = (event: SelectChangeEvent) => {
  //   setAge(event.target.value)
  // }

  // const handlerFromDate = (e: any) => {
  //   setFromDate(e.target.value)
  // }

  // const handlerToDate = (e: any) => {
  //   setToDate(e.target.value)
  // }

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue)
  }

  function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    )
  }

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    }
  }

  return (
    <div className="openbetinputs py-4 lg:py-0">
      <Grid
        container
        rowSpacing={{ xs: 1 }}
        columnSpacing={1}
        sx={{
          padding: { md: "25px 25px 0 25px", xs: "0 0 0px 0" },
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        {/* <Grid item xs={6} md={3} sx={{ display: "flex", alignItems: "center" }}>
          <FormControl sx={{ m: 1, maxWidth: 314, width: "100%" }} size="small">
            <InputLabel
              id="game-type-select-small-label"
              sx={{ color: "#cccccc58", fontSize: { md: "1rem", xs: "12px" } }}
            >
              Game type
            </InputLabel>
            <Select
              sx={{
                borderRadius: "30px",
                fontSize: { md: "1rem", xs: "12px" },
              }}
              labelId="game-type-select-small-label"
              id="game-type-select-small"
              value={age}
              label="game type"
              onChange={handleChange}
            >
              <MenuItem
                sx={{ fontSize: { md: "1rem", xs: "12px" } }}
                value={"cricket"}
              >
                cricket
              </MenuItem>
              <MenuItem
                sx={{ fontSize: { md: "1rem", xs: "12px" } }}
                value={"tennis"}
              >
                tennis
              </MenuItem>
              <MenuItem
                sx={{ fontSize: { md: "1rem", xs: "12px" } }}
                value={"football"}
              >
                football
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6} md={3} sx={{ display: "flex", alignItems: "center" }}>
          <TextField
            className="calendarcls"
            label={"From Dates"}
            sx={{
              fontSize: { md: "1rem", xs: "12px" },
              maxWidth: 314,
              width: "100%",
              "& .MuiInputBase-root": {
                borderRadius: "30px",
                fontSize: { md: "1rem", xs: "12px" },
              },
            }}
            value={fromDate}
            onChange={handlerFromDate}
            type="date"
            size="small"
            InputLabelProps={{
              sx: { color: "#cccccc58", fontSize: { md: "1rem", xs: "12px" } },
            }}
          />
        </Grid>
        <Grid item xs={6} md={3} sx={{ display: "flex", alignItems: "center" }}>
          <TextField
            className="calendarcls"
            label={"To Dates"}
            sx={{
              fontSize: { md: "1rem", xs: "12px" },
              maxWidth: 314,
              width: "100%",
              "& .MuiInputBase-root": {
                borderRadius: "30px",
                fontSize: { md: "1rem", xs: "12px" },
              },
            }}
            value={toDate}
            onChange={handlerToDate}
            type="date"
            size="small"
            InputLabelProps={{
              sx: { color: "#cccccc58 ", fontSize: { md: "1rem", xs: "12px" } },
            }}
          />
        </Grid>
        <Grid item xs={6} md={3} sx={{ display: "flex", alignItems: "center" }}>
          <Button
            disableFocusRipple
            disableTouchRipple
            disableRipple
            sx={{
              borderRadius: "30px",
              width: "100%",
              display: "inline-block",
              color: "#fff",
              textTransform: "uppercase",
              fontSize: { md: "13px", xs: "12px" },
              textAlign: "center",
              backgroundColor: "#fc6321",
              fontWeight: "600",
              minHeight: { md: "40px", xs: "35px" },
              border: "2px solid #fc6321",
              maxWidth: 314,
              ":hover": {
                backgroundColor: "#fc6321",
                border: "2px solid #fc6321",
              },
            }}
            variant="outlined"
            fullWidth
          >
            search
          </Button>
        </Grid> */}

        <Grid
          container
          className="datashown"
          sx={{
            backgroundColor: "#1e1e1e",
            margin: { md: "0 0 0px 0", xs: "10px 0" },
          }}
        >
          <Box sx={{ width: "100%" }}>
            <Box className="openbetstabsholder">
              <Tabs
                centered
                TabIndicatorProps={{
                  style: {
                    backgroundColor: "#f36c21",
                    bottom: "9px",
                  },
                }}
                value={tabValue}
                onChange={handleTabChange}
                aria-label="basic tabs example"
              >
                {tabsName?.map((name, idx) => (
                  <Tab
                    key={idx}
                    label={name}
                    sx={{
                      color: "#fff",
                      fontSize: "12px",
                      padding: "0",
                      minHeight: "40px",
                      textTransform: "capitalize",
                      ":hover": {
                        backgroundColor: "#51311f",
                        opacity: 0.8,
                      },
                    }}
                    {...a11yProps(idx)}
                  />
                ))}
              </Tabs>
            </Box>
            <CustomTabPanel value={tabValue} index={0}>
              <MatchedBetsAccordionSports />
            </CustomTabPanel>
            <CustomTabPanel value={tabValue} index={1}>
              <MatchedBetsAccordionCasino />
            </CustomTabPanel>
          </Box>
        </Grid>
      </Grid>
    </div>
  )
}

export default SearchBar
