import React, { useEffect, useState } from "react"
import {
  TextField,
  IconButton,
  InputAdornment,
  Button,
  Box,
  Typography,
} from "@mui/material"
import { Visibility, VisibilityOff, Lock } from "@mui/icons-material"
import { useChangePasswordMutation } from "../../../../api/mainApi/mainApiSlice"
import { useNavigate } from "react-router"
import snackBarUtil from "../../../../utils/snackbar"
import { SnackbarContent } from "notistack"
import { useAppDispatch } from "../../../../hooks/useAppDispatch"
import { logout } from "../../../../features/auth/authslice"

interface ShowPasswordState {
  currentPassword: boolean
  newPassword: boolean
  confirmPassword: boolean
}

const ResetPasswordForm: React.FC = () => {
  const nav = useNavigate()
  const dispatch = useAppDispatch()
  const token = localStorage.getItem("token")
  const [showPassword, setShowPassword] = useState<ShowPasswordState>({
    currentPassword: false,
    newPassword: false,
    confirmPassword: false,
  })
  const [passwords, setPasswords] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  })
  const [errors, setErrors] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
    general: "",
  })

  const handleClickShowPassword = (field: keyof ShowPasswordState) => {
    setShowPassword(prev => ({ ...prev, [field]: !prev[field] }))
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setPasswords(prev => ({ ...prev, [name]: value }))
  }

  const [changepasswordTrigger, { data: changePassData }] =
    useChangePasswordMutation()

  const validatePasswords = () => {
    const validationErrors = {
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
      general: "",
    }
    if (!passwords.currentPassword)
      validationErrors.currentPassword = "Old password is required"
    if (!passwords.newPassword)
      validationErrors.newPassword = "New password is required"
    if (!passwords.confirmPassword)
      validationErrors.confirmPassword = "Confirm password is required"
    if (
      passwords.newPassword &&
      passwords.confirmPassword &&
      passwords.newPassword !== passwords.confirmPassword
    ) {
      validationErrors.general =
        "New password and confirm password do not match"
    }
    if (passwords.newPassword && passwords.newPassword.length < 6) {
      validationErrors.newPassword =
        "New password must be at least 6 characters long"
    }
    return validationErrors
  }

  const handleChangePassword = () => {
    const validationErrors = validatePasswords()
    if (Object.values(validationErrors).some(error => error)) {
      setErrors(validationErrors)
    } else {
      setErrors({
        currentPassword: "",
        newPassword: "",
        confirmPassword: "",
        general: "",
      })
      changepasswordTrigger({
        currentPassword: passwords.currentPassword,
        newPassword: passwords.newPassword,
        confirmPassword: passwords.confirmPassword,
      })

      snackBarUtil.success(
        <SnackbarContent role="alert" style={{ width: "800px" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Typography
              sx={{ color: "#fff", fontSize: "16px", textAlign: "left" }}
            >
              Success!
            </Typography>
            <Typography
              sx={{ color: "#fff", fontSize: "12px", textAlign: "left" }}
            >
              {changePassData?.message}
            </Typography>
          </Box>
        </SnackbarContent>,
      )

      snackBarUtil.success(
        <SnackbarContent role="alert" style={{ width: "800px" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Typography
              sx={{ color: "#fff", fontSize: "16px", textAlign: "left" }}
            >
              Success!
            </Typography>
            <Typography
              sx={{ color: "#fff", fontSize: "12px", textAlign: "left" }}
            >
              Login again!
            </Typography>
          </Box>
        </SnackbarContent>,
      )
      dispatch(logout())
    }
  }

  useEffect(() => {
    if (!token) {
      localStorage.clear()
      dispatch(logout())
      nav("/")
    }
  }, [token])

  return (
    <Box>
      {errors.general && (
        <Box mb={2}>
          <Typography color="error">{errors.general}</Typography>
        </Box>
      )}
      <TextField
        name="currentPassword"
        placeholder="currentPassword"
        variant="outlined"
        type={showPassword.currentPassword ? "text" : "password"}
        fullWidth
        value={passwords.currentPassword}
        onChange={handleChange}
        error={!!errors.currentPassword}
        helperText={errors.currentPassword}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Lock sx={{ color: "white" }} />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment
              position="end"
              sx={{ position: "absolute", right: { md: 30, xs: 10 } }}
            >
              <IconButton
                disableRipple
                aria-label="toggle current password visibility"
                onClick={() => handleClickShowPassword("currentPassword")}
                edge="end"
                sx={{ color: "white" }}
              >
                {!showPassword.currentPassword ? (
                  <Visibility />
                ) : (
                  <VisibilityOff />
                )}
              </IconButton>
            </InputAdornment>
          ),
        }}
        sx={{
          position: "relative",
          border: "none",
          ":hover": { border: "none" },
          mb: 2,
          "& .MuiInputBase-root": {
            color: "white",
            px: { md: 1, xs: 0 },
            ":hover": { border: "none" },
            "& .MuiInputBase-input": {
              border: "1px solid #fff",
              borderRadius: 1,
              padding: { md: "12px 8px", xs: "7px 14px" },
            },
          },
          "& .MuiInputLabel-root": { color: "white" },
          "& .MuiOutlinedInput-notchedOutline": { border: "none" },
        }}
      />
      <TextField
        name="newPassword"
        placeholder="New Password"
        variant="outlined"
        type={showPassword.newPassword ? "text" : "password"}
        fullWidth
        value={passwords.newPassword}
        onChange={handleChange}
        error={!!errors.newPassword}
        helperText={errors.newPassword}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Lock sx={{ color: "white" }} />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment
              position="end"
              sx={{ position: "absolute", right: { md: 30, xs: 10 } }}
            >
              <IconButton
                disableRipple
                aria-label="toggle new password visibility"
                onClick={() => handleClickShowPassword("newPassword")}
                edge="end"
                sx={{ color: "white" }}
              >
                {!showPassword.newPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        sx={{
          position: "relative",
          border: "none",
          ":hover": { border: "none" },
          mb: 2,
          "& .MuiInputBase-root": {
            color: "white",
            px: { md: 1, xs: 0 },
            ":hover": { border: "none" },
            "& .MuiInputBase-input": {
              border: "1px solid #fff",
              borderRadius: 1,
              padding: { md: "12px 8px", xs: "7px 14px" },
            },
          },
          "& .MuiInputLabel-root": { color: "white" },
          "& .MuiOutlinedInput-notchedOutline": { border: "none" },
        }}
      />
      <TextField
        name="confirmPassword"
        placeholder="Confirm New Password"
        variant="outlined"
        type={showPassword.confirmPassword ? "text" : "password"}
        fullWidth
        value={passwords.confirmPassword}
        onChange={handleChange}
        error={!!errors.confirmPassword}
        helperText={errors.confirmPassword}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Lock sx={{ color: "white" }} />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment
              position="end"
              sx={{ position: "absolute", right: { md: 30, xs: 10 } }}
            >
              <IconButton
                disableRipple
                aria-label="toggle confirm password visibility"
                onClick={() => handleClickShowPassword("confirmPassword")}
                edge="end"
                sx={{ color: "white" }}
              >
                {!showPassword.confirmPassword ? (
                  <Visibility />
                ) : (
                  <VisibilityOff />
                )}
              </IconButton>
            </InputAdornment>
          ),
        }}
        sx={{
          position: "relative",
          border: "none",
          height: "42px",
          ":hover": { border: "none" },
          mb: 2,
          "& .MuiInputBase-root": {
            color: "white",
            px: { md: 1, xs: 0 },

            ":hover": { border: "none" },
            "& .MuiInputBase-input": {
              border: "1px solid #fff",
              borderRadius: 1,
              padding: { md: "12px 8px", xs: "7px 14px" },
            },
          },
          "& .MuiInputLabel-root": { color: "white" },
          "& .MuiOutlinedInput-notchedOutline": { border: "none" },
        }}
      />
      <Button
        variant="contained"
        fullWidth
        onClick={handleChangePassword}
        sx={{
          mt: 2,
          padding: "7px",
          backgroundColor: "#424242",
          color: "white",
          lineHeight: "35px",
          "&:hover": { backgroundColor: "#f36c21" },
        }}
      >
        RESET PASSWORD
      </Button>
    </Box>
  )
}

export default ResetPasswordForm
