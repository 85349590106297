import { Typography } from "@mui/material"

export const positiveNegative = (value: number) => {
  if (null) {
    return
  } else {
    if (value && value > 0) {
      return (
        <Typography
          component="span"
          sx={{ color: "#1e8067", fontSize: "13px" }}
        >
          {value}
        </Typography>
      )
    } else {
      return (
        <Typography
          component="span"
          sx={{ color: "#f36c21", fontSize: "13px" }}
        >
          {value}
        </Typography>
      )
    }
  }
}
