import { Box } from "@mui/material"
import SecondHeadingBar from "../../../../common/SecondHeadingBar"
import AccStatementSearchBar from "./AccStatementSearchBar"

const AccountStatements = () => {
  return (
    <Box className="accountstatements">
      <SecondHeadingBar title="Statements" />
      <Box>
        <AccStatementSearchBar/>
      </Box>
    </Box>
  )
}
export default AccountStatements
