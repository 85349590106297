import * as React from "react"
import Accordion from "@mui/material/Accordion"
import AccordionSummary from "@mui/material/AccordionSummary"
import AccordionDetails from "@mui/material/AccordionDetails"
import Typography from "@mui/material/Typography"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { Box, Grid } from "@mui/material"
import NotUnmatchedBets from "./NotUnmatchedBets"
import { useBetlistbymatchidMutation } from "../../../api/mainApi/mainApiSlice"

interface MatchedBetsAccordionProps {
  matchid: any
}

const SportsEventOpenBetsAccordion: React.FC<MatchedBetsAccordionProps> = ({
  matchid,
}) => {
  const [expanded, setExpanded] = React.useState<string | boolean>(false)
  const [betlistTrigger, { data: betlistbymatchidData }] =
    useBetlistbymatchidMutation()

  React.useEffect(() => {
    betlistTrigger({ matchId: matchid })
  }, [betlistTrigger, matchid])

  const handleExpanded =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false)
    }

    console.log(betlistbymatchidData?.data, "betlistbymatchidDatabetlistbymatchidData ")

  return (
    <>
      {betlistbymatchidData?.data && Object.keys(betlistbymatchidData.data).length > 0 ? (
        Object.keys(betlistbymatchidData.data || {}).map((key: any) => (
          <Accordion
            key={key}
            onChange={handleExpanded(key)}
            defaultExpanded
            expanded={expanded === key}
            sx={{
              backgroundColor: "transparent",
              my: 1,
              "& .MuiPaper-root": {
                backgroundColor: "rgba(255, 255, 255, 0)",
              },
              "&.Mui-expanded": {
                margin: 0,
              },
            }}
          >
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon
                  sx={{
                    borderRadius: "50%",
                    color: "white",
                    backgroundColor: "#f36c21",
                    padding: "4px",
                  }}
                />
              }
              aria-controls="matched-bets-content"
              id="matched-bets-header"
              sx={{
                border: "1px solid #f36c21",
                borderRadius: expanded === key ? "15px 15px 0 0" : "15px",
                minHeight: "46px !important",
                "&.Mui-expanded": {
                  minHeight: "46px !important",
                },
                "& .MuiAccordionSummary-content.Mui-expanded": {
                  minHeight: "46px !important",
                  margin: "0px",
                },
                "& .MuiAccordionSummary-content": {
                  alignItems: "center",
                  margin: "0px",
                },
                "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
                  transform: "rotate(180deg)",
                },
              }}
            >
              <Typography variant="h6" sx={{ fontSize: "13px", color: "#fff" }}>
                {key}
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                border: "1px solid #f36c21",
                borderTop: "none",
                borderRadius: expanded === key ? "0 0 15px 15px" : "15px ",
              }}
            >
              {(betlistbymatchidData as any)?.data?.[key]?.map(
                (item: any, idx: number) => (
                  <Grid
                    key={idx}
                    container
                    sx={{
                      borderLeft: item.back
                        ? "5px solid #a5d9fe"
                        : "5px solid #F8D0CE",
                      fontSize: "0.8rem",
                      width: "100%",
                      display: "flex",
                      flexFlow: "wrap",
                      padding: "12px 8px",
                      marginTop: "8px",
                      marginBottom: "8px",
                      borderRadius: 1,
                      boxSizing: "border-box",
                      color: "#fff",
                      backgroundColor: "#4e4e4e",
                      boxShadow: " rgba(0, 0, 0, 0.1) 0px 4px 12px",
                    }}
                  >
                    <Grid item xs={6}>
                      {item.nation}
                    </Grid>
                    <Grid item xs={3}>
                      {item.rate}
                    </Grid>
                    <Grid item xs={3}>
                      {item.amount}
                    </Grid>
                  </Grid>
                ),
              )}
            </AccordionDetails>
          </Accordion>
        ))
      ) : (
        <NotUnmatchedBets />
      )}
    </>
  )
}

export default SportsEventOpenBetsAccordion
