import * as React from "react"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import Select, { SelectChangeEvent } from "@mui/material/Select"
import { Box, Button, Grid, Slide, Tab, Tabs, TextField } from "@mui/material"
import moment from "moment"
import AccAccordionData from "./AccAccordionData"
import { useAccountStatementMutation } from "../../../../api/mainApi/mainApiSlice"
import dayjs from "dayjs"

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

const AccStatementSearchBar = () => {
  const droplistitems = ["all", "Game Report", "Withdraw/deposit"]
  const [selectVal, setSelectalue] = React.useState(1)
  const [show, setShow] = React.useState<boolean>(false)
  const [fromDate, setFromDate] = React.useState(
    dayjs().subtract(7, "days").format("YYYY-MM-DD"),
  )
  const [toDate, setToDate] = React.useState(dayjs().format("YYYY-MM-DD"))

  const [trigger, { data: accountStatementData, isLoading }] =
    useAccountStatementMutation()

  const handleChange = (event: any) => {
    setSelectalue(event.target.value)
  }

  const handlerFromDate = (e: any) => {
    setFromDate(e.target.value)
  }

  const handlerToDate = (e: any) => {
    setToDate(e.target.value)
  }

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    }
  }

  React.useEffect(() => {
    setShow(true)
  }, [])

  React.useEffect(() => {
    trigger({
      noOfRecords: 100,
      index: 0,
      fromDate: fromDate,
      toDate: toDate,
      type: selectVal,
    })
  }, [])

  return (
    <div className="openbetinputs py-4 lg:py-0">
      <Grid
        container
        rowSpacing={{ xs: 1 }}
        columnSpacing={1}
        sx={{
          padding: { md: "25px 25px 0 25px", xs: "0 0 0px 0" },
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Grid item xs={6} md={3} sx={{ display: "flex", alignItems: "center" }}>
          <FormControl sx={{ m: 1, maxWidth: 314, width: "100%" }} size="small">
            <InputLabel
              id="game-type-select-small-label"
              sx={{ color: "#cccccc58", fontSize: { md: "1rem", xs: "12px" } }}
            >
              Game type
            </InputLabel>
            <Select
              sx={{
                borderRadius: "30px",
                fontSize: { md: "1rem", xs: "12px" },
              }}
              labelId="game-type-select-small-label"
              id="game-type-select-small"
              value={selectVal}
              label="game type"
              onChange={handleChange}
            >
              {droplistitems?.map((list, idx) => (
                <MenuItem
                  key={list}
                  sx={{
                    fontSize: {
                      md: "1rem",
                      xs: "12px",
                      textTransform: "capitalize",
                    },
                  }}
                  value={idx + 1}
                >
                  {list}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6} md={3} sx={{ display: "flex", alignItems: "center" }}>
          <TextField
            className="calendarcls"
            label={"From Dates"}
            sx={{
              fontSize: { md: "1rem", xs: "12px" },
              maxWidth: 314,
              width: "100%",
              "& .MuiInputBase-root": {
                borderRadius: "30px",
                fontSize: { md: "1rem", xs: "12px" },
              },
            }}
            value={fromDate}
            onChange={handlerFromDate}
            type="date"
            size="small"
            InputLabelProps={{
              sx: { color: "#cccccc58", fontSize: { md: "1rem", xs: "12px" } },
            }}
          />
        </Grid>
        <Grid item xs={6} md={3} sx={{ display: "flex", alignItems: "center" }}>
          <TextField
            className="calendarcls"
            label={"To Dates"}
            sx={{
              fontSize: { md: "1rem", xs: "12px" },
              maxWidth: 314,
              width: "100%",
              "& .MuiInputBase-root": {
                borderRadius: "30px",
                fontSize: { md: "1rem", xs: "12px" },
              },
            }}
            value={toDate}
            onChange={handlerToDate}
            type="date"
            size="small"
            InputLabelProps={{
              sx: { color: "#cccccc58 ", fontSize: { md: "1rem", xs: "12px" } },
            }}
          />
        </Grid>
        <Grid item xs={6} md={3} sx={{ display: "flex", alignItems: "center" }}>
          <Button
            onClick={() =>
              trigger({
                noOfRecords: 100,
                index: 0,
                fromDate: fromDate,
                toDate: toDate,
                type: selectVal,
              })
            }
            disableFocusRipple
            disableTouchRipple
            disableRipple
            sx={{
              borderRadius: "30px",
              width: "100%",
              display: "inline-block",
              color: "#fff",
              textTransform: "uppercase",
              fontSize: { md: "13px", xs: "12px" },
              textAlign: "center",
              backgroundColor: "#fc6321",
              fontWeight: "600",
              minHeight: { md: "40px", xs: "35px" },
              border: "2px solid #fc6321",
              maxWidth: 314,
              ":hover": {
                backgroundColor: "#fc6321",
                border: "2px solid #fc6321",
              },
            }}
            variant="outlined"
            fullWidth
          >
            search
          </Button>
        </Grid>

        <Grid
          container
          className="datashown"
          sx={{
            backgroundColor: "#1e1e1e",
            margin: { md: "0 0 0px 0", xs: "10px 0" },
          }}
        >
          <Box sx={{ width: "100%" }}>
            <Box sx={{ px: 2, py: 2 }}>
              <Slide direction="right" in={show} mountOnEnter unmountOnExit>
                <div>
                  <AccAccordionData
                    accountStatementData={accountStatementData}
                  />
                </div>
              </Slide>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </div>
  )
}

export default AccStatementSearchBar
