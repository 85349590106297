import { Box, Button, DialogProps, Grid, Typography } from "@mui/material"
// import inplayimg from "https://s3buket.blr1.cdn.digitaloceanspaces.com/images/inplay.webp"
// import sportexchimg from "https://s3buket.blr1.cdn.digitaloceanspaces.com/images/sportexch.webp"
// import sportbookimg from "https://s3buket.blr1.cdn.digitaloceanspaces.com/images/sportsbookmobile.webp"
// import moremobileimg from "https://s3buket.blr1.cdn.digitaloceanspaces.com/images/moremobile.webp"
import { useNavigate } from "react-router"
import React, { useEffect, useState } from "react"
import MoreModal from "./MoreModal"
import { useLeftMenuListMutation } from "../api/mainApi/mainApiSlice"
import sportIcon from "../utils/sporticon"

type sportsbarmobiledataType = {
  name: string
  link?: string
  ico: string
}[]

const SportsBarMobile = () => {
  const sportsbarmobiledata: sportsbarmobiledataType = [
    {
      name: "inplay",
      link: "/",
      ico: "https://s3buket.blr1.cdn.digitaloceanspaces.com/images/inplay.webp",
    },
    {
      name: "sport exch",
      link: `/sports/cricket/${4}`,
      ico: "https://s3buket.blr1.cdn.digitaloceanspaces.com/images/sportexch.webp",
    },
    {
      name: "sportbook",
      link: "/sports-book",
      ico: "https://s3buket.blr1.cdn.digitaloceanspaces.com/images/sportsbookmobile.webp",
    },
  ]

  const [scroll, setScroll] = React.useState<DialogProps["scroll"]>("paper")
  const [openModal, setModalOpen] = useState(false)
  const nav = useNavigate()
  const [leftmenuTrigger, { data: leftmenuData }] = useLeftMenuListMutation()

  useEffect(() => {
    leftmenuTrigger({})
  }, [])

  const handleNavigate = (navdata: any) => {
    nav(navdata)
  }

  const handleMoreModal = (scrollType: DialogProps["scroll"]) => () => {
    setModalOpen(true)
    setScroll(scrollType)
  }

  const handleRedirect = (directTo: LeftMenuListData) => {
    nav(`/sports/${directTo?.sportName}/${directTo?.sportId}`)
    setModalOpen(false)
  }

  return (
    <Box className="sportsbarmobileparent">
      <MoreModal open={openModal} setModalOpen={setModalOpen} scroll={scroll}>
        <Grid container spacing={1}>
          {leftmenuData?.data?.map((menudata, idx) => (
            <Grid key={idx} item xs={3}>
              <Button
                disableTouchRipple
                disableRipple
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  ":hover": {
                    background: "transparent",
                  },
                }}
                onClick={() => handleRedirect(menudata)}
              >
                <img
                  key={idx}
                  src={sportIcon(menudata.sportName)}
                  alt={menudata.sportName}
                  className={`w-[25px] mb-[5px] h-auto max-w-full ${menudata?.sportName === "Kabaddi" || menudata?.sportName === "Election" ? "filter invert" : ""}`}
                />
                <Typography
                  sx={{
                    fontSize: "12px",
                    textTransform: "capitalize",
                    color: "#fff",
                  }}
                >
                  {menudata?.sportName}
                </Typography>
              </Button>
            </Grid>
          ))}
        </Grid>
      </MoreModal>

      <Box
        className="mobilesportsbar"
        sx={{
          margin: { xs: "10px 5px" },
          display: "flex",
          alignItems: "center",
          backgroundColor: "#3d3d3d",
          borderRadius: "15px",
          padding: "5px",
        }}
      >
        {sportsbarmobiledata?.map((sportsbardata, idx) => (
          <Button
            disableTouchRipple
            disableRipple
            onClick={() => handleNavigate(sportsbardata?.link)}
            key={sportsbardata.name + idx}
            sx={{
              borderRadius: 0,
              width: { xs: "25%" },
              padding: { xs: "0 5px" },
              textAlign: "center",
              borderRight: "1px solid #6e6e6e",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              ":hover": {
                backgroundColor: "transparent",
              },
              ":last-child": {
                borderRight: "0",
              },
            }}
          >
            <img
              src={sportsbardata?.ico}
              alt=""
              className="w-[20px] h-[20px] mb-1"
            />
            <Typography
              component="p"
              sx={{
                fontSize: "10px",
                textTransform: "uppercase",
                color: "#FFFFFF99",
              }}
            >
              {sportsbardata?.name}
            </Typography>
          </Button>
        ))}
        <Button
          disableTouchRipple
          disableRipple
          onClick={handleMoreModal("paper")}
          sx={{
            borderRadius: 0,
            width: { xs: "25%" },
            padding: { xs: "0 5px" },
            textAlign: "center",
            borderRight: "1px solid #6e6e6e",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            ":hover": {
              backgroundColor: "transparent",
            },
            ":last-child": {
              borderRight: "0",
            },
          }}
        >
          <img
            src={
              "https://s3buket.blr1.cdn.digitaloceanspaces.com/images/moremobile.webp"
            }
            alt=""
            className="w-[20px] h-[20px] mb-1"
          />
          <Typography
            component="p"
            sx={{
              fontSize: "10px",
              textTransform: "uppercase",
              color: "#FFFFFF99",
            }}
          >
            more
          </Typography>
        </Button>
      </Box>
    </Box>
  )
}

export default SportsBarMobile
