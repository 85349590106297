import { Box, Container, Grid, List, ListItem, Typography } from "@mui/material"
// import amarakbaranthony from "https://s3buket.blr1.cdn.digitaloceanspaces.com/images/amar-akbar-anthony.webp"
import { Link } from "react-router-dom"

const listyle = {
  padding: 0,
  fontSize: "14px",
  color: "#fff",
  display: "list-item",
  listStyleType: "inherit",
}

const list = {
  p: 0,
  listStyleType: "disc",
  paddingInlineStart: "20px",
}

const AmarAkbharAnthony = () => {
  return (
    <Box className="worlimatka">
      <Container maxWidth="lg" sx={{ py: "10px", m: "17px 59px" }}>
        <Grid container columnSpacing={{ xs: 2 }}>
          <Grid item xs={4}>
            <Typography
              component="h1"
              sx={{
                fontSize: "32px",
                color: "#f36c21",
                fontFamily: "Lato,sans-serif",
                fontWeight: "700",
                paddingBottom: "8px",
                textAlign: "left",
              }}
            >
              Amar Akbar Anthony
            </Typography>

            <div className="teenpattiimg max-w-[420px] mb-[27px]">
              <img
                src={
                  "https://s3buket.blr1.cdn.digitaloceanspaces.com/images/amar-akbar-anthony.webp"
                }
                alt=""
                className="max-w-[100%] h-auto"
              />
            </div>
          </Grid>
          <Grid item xs={8}>
            <Box className="pana">
              <Typography
                component="p"
                sx={{
                  textAlign: "left",
                  color: "#fff",
                  fontSize: "14px",
                  fontFamily: "Lato,sans-serif",
                }}
              >
                {window.location.hostname.split(".")[0]} offers a wide range of{" "}
                <Link
                  to={"#"}
                  className="text-[#fc6321] underline hover:text-[#fff]"
                >
                  Indian Card Games.
                </Link>{" "}
                If you’re looking to play an eight card decks game, a live Amar
                Akbar Anthony game would be the right pick for you. Play and win
                big, only with {window.location.hostname.split(".")[0]}. Concerned about safety? Don’t
                worry. Your money is safe with {window.location.hostname.split(".")[0]}. We offer daily
                exchanges too. Sign up on {window.location.hostname.split(".")[0]}, the best online
                casino, and win big, win daily.
              </Typography>
              <Typography
                component="h2"
                sx={{
                  textAlign: "left",
                  color: "#f36c21",
                  fontSize: "24px",
                  paddingTop: "14px",
                  fontWeight: "500",
                }}
              >
                Objective
              </Typography>
              <List sx={{ p: 0 }}>
                <ListItem sx={listyle}>
                  The objective of Amar Akbar Anthony is to predict the winning
                  card of the game round. Live Amar Akbar Anthony is played with
                  Eight card decks that are stored in a shuffling machine. To
                  win, you need to predict if the next card will be higher,
                  lower or of the same value as the dragon card of the round.
                  You can also choose to bet on other betting positions on the
                  table.
                </ListItem>
              </List>
            </Box>
            <Box className="bettingflow">
              <Typography
                component="h2"
                sx={{
                  textAlign: "left",
                  color: "#f36c21",
                  fontSize: "24px",
                  paddingTop: "14px",
                  fontWeight: "500",
                }}
              >
                Betting flow:
              </Typography>
              <List sx={list}>
                <ListItem sx={listyle}>
                  If a game round is in progress when you enter the table,
                  please wait for the next one, and then, place your bets.
                </ListItem>
                <ListItem sx={listyle}>
                  To place a bet, choose a chip and place it on the betting
                  position.
                </ListItem>
                <ListItem sx={listyle}>
                  You can place several chips on different betting positions
                  simultaneously.
                </ListItem>
                <ListItem sx={listyle}>
                  The timer in the game window shows how much time you have left
                  to place your bets.
                </ListItem>
                <ListItem sx={listyle}>
                  You must confirm your bet. This can be done either manually,
                  by using the Confirm button after each bet, or automatically
                  at the end of betting time. If your service provider has
                  enabled auto-confirmation, it is on by default and the Confirm
                  button does not appear. You can switch the feature off in game
                  settings.
                </ListItem>
                <ListItem sx={listyle}>
                  After the No More Bets signal, the game round begins.
                </ListItem>
                <ListItem sx={listyle}>
                  Winnings are paid for the winning bets at the end of each game
                  round.
                </ListItem>
                <ListItem sx={listyle}>
                  To play a game round, place your bets again or use the Rebet
                  button.
                </ListItem>
                <ListItem sx={listyle}>
                  To skip a turn, simply do not place any bets on the table.
                </ListItem>
              </List>
            </Box>
            <Box className="gameflow">
              <Typography
                component="h2"
                sx={{
                  textAlign: "left",
                  color: "#f36c21",
                  fontSize: "24px",
                  paddingTop: "14px",
                  fontWeight: "500",
                }}
              >
                Game flow:
              </Typography>
              <List sx={list}>
                <ListItem sx={listyle}>
                  When the first game round starts, one card is burned, and
                  second card is placed on the Dragon card position on the
                  table.
                </ListItem>
                <ListItem sx={listyle}>
                  A round begins when there is a Dragon card in front of the
                  dealer.
                </ListItem>
                <ListItem sx={listyle}>
                  After the game round is over, the one burned card is returned
                  into the shuffling machine along with the Tiger card, where
                  they are immediately shuffled. The result card of the round
                  becomes the base card for the next round.
                </ListItem>
                <ListItem sx={listyle}>
                  If you lost the round, the bet amount is taken off the table.
                  If you won the round, the winnings are added to your Balance.
                </ListItem>
                <ListItem sx={listyle}>
                  You must confirm your bet. This can be done either manually,
                  by using the Confirm button after each bet, or automatically
                  at the end of betting time. If your service provider has
                  enabled auto-confirmation, it is on by default and the Confirm
                  button does not appear. You can switch the feature off in game
                  settings.
                </ListItem>
                <ListItem sx={listyle}>
                  If the base card is a 2, Lower position on the table is
                  disabled. If the base card is an Ace, then Higher position is
                  disabled. Also, the 10-value card is not present in the game
                  deck.
                </ListItem>
                <ListItem sx={listyle}>
                  Winnings are paid for the winning bets at the end of each game
                  round.
                </ListItem>
                <ListItem sx={listyle}>
                  A round begins when there is a Dragon card in front of the
                  dealer.
                </ListItem>
              </List>
              <Typography
                component="p"
                sx={{
                  textAlign: "left",
                  color: "#fff",
                  fontSize: "14px",
                  fontFamily: "Lato,sans-serif",
                }}
              >
                If it is possible to bet an amount that could potentially result
                in a win larger than the maximum payout limit, then the bet is
                automatically adjusted to the maximum possible amount for this
                position.
              </Typography>
            </Box>
            <Box className="betposition">
              <Typography
                component="h2"
                sx={{
                  textAlign: "left",
                  color: "#f36c21",
                  fontSize: "24px",
                  paddingTop: "14px",
                  fontWeight: "500",
                }}
              >
                Bet position:
              </Typography>
              <List sx={list}>
                <ListItem sx={listyle}>
                  If the card is ACE, 2, 3, 4, 5 or 6 for all suits then Amar
                  wins.
                </ListItem>
                <ListItem sx={listyle}>
                  If the card is 7, 8, 9 or 10 for all suits then Akbar wins.
                </ListItem>
                <ListItem sx={listyle}>
                  After the game round is over, the one burned card is returned
                  into the shuffling machine along with the Tiger card, where
                  they are immediately shuffled. The result card of the round
                  becomes the base card for the next round.
                </ListItem>
                <ListItem sx={listyle}>
                  If the card is JACK, QUEEN or KING for all suits then Anthony
                  wins.
                </ListItem>
              </List>
            </Box>
            <Box className="evenodd">
              <Typography
                component="h2"
                sx={{
                  textAlign: "left",
                  color: "#f36c21",
                  fontSize: "24px",
                  paddingTop: "14px",
                  fontWeight: "500",
                }}
              >
                Even Odd:
              </Typography>
              <List sx={list}>
                <ListItem sx={listyle}>
                  If the card is 2, 4, 6, 8, 10 or QUEEN for all suits then Even
                  wins.
                </ListItem>
                <ListItem sx={listyle}>
                  If the card is 1, 3, 5, 7, 9, JACK or KING for all suits then
                  Odd wins.
                </ListItem>
              </List>
            </Box>
            <Box className="color">
              <Typography
                component="h2"
                sx={{
                  textAlign: "left",
                  color: "#f36c21",
                  fontSize: "24px",
                  paddingTop: "14px",
                  fontWeight: "500",
                }}
              >
                Color:
              </Typography>
              <List sx={list}>
                <ListItem sx={listyle}>
                  If the card is a SPADE or CLUB then Black wins.
                </ListItem>
                <ListItem sx={listyle}>
                  If the card is a HEART or DIAMOND then Red wins.
                </ListItem>
              </List>
            </Box>
            <Box className="under7over7">
              <Typography
                component="h2"
                sx={{
                  textAlign: "left",
                  color: "#f36c21",
                  fontSize: "24px",
                  paddingTop: "14px",
                  fontWeight: "500",
                }}
              >
                Under 7 Over 7:
              </Typography>
              <List sx={list}>
                <ListItem sx={listyle}>
                  If the card is ACE, 2, 3, 4, 5 or 6 for all suits then Under 7
                  wins.
                </ListItem>
                <ListItem sx={listyle}>
                  If the card is 8, 9, 10, JACK, QUEEN or KING for all suits
                  then Over 7 wins.
                </ListItem>
                <ListItem sx={listyle}>
                  If the card is 7 for any suit, then bets on Under 7 and Over 7
                  will lose 50% of their Bets amount.
                </ListItem>
              </List>
            </Box>
            <Box className="singles">
              <Typography
                component="h2"
                sx={{
                  textAlign: "left",
                  color: "#f36c21",
                  fontSize: "24px",
                  paddingTop: "14px",
                  fontWeight: "500",
                }}
              >
                Singles:
              </Typography>
              <List sx={list}>
                <ListItem sx={listyle}>
                  The respective single card will win for all suits of that
                  number.
                </ListItem>
              </List>
            </Box>
            <Box className="limitranges">
              <Typography
                component="h2"
                sx={{
                  textAlign: "left",
                  color: "#f36c21",
                  fontSize: "24px",
                  paddingTop: "14px",
                  fontWeight: "500",
                }}
              >
                Limit ranges:
              </Typography>
              <List sx={{ p: 0 }}>
                <ListItem sx={listyle}>
                  The limit displayed next to the table name in the Lobby and on
                  the game table UI is the minimum bet limit allowed at the
                  table. The Limits panel in the game menu also shows info about
                  the maximum payouts.
                </ListItem>
              </List>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}
export default AmarAkbharAnthony
