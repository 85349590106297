import { Box } from "@mui/material"
import SecondHeadingBar from "../../../../common/SecondHeadingBar"
import ProfitlossSearchBar from "./ProfitlossSearchBar"

const ProfitLoss = () => {
  return (
    <Box className="profitloss">
      <SecondHeadingBar title="Profit & Loss" />
      <Box>
        <ProfitlossSearchBar />
      </Box>
    </Box>
  )
}
export default ProfitLoss
