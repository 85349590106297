import { FC } from "react"
import "./headingbar.css"
import { Box, Button, Typography } from "@mui/material"
import { useLocation, useNavigate } from "react-router"
import { ChevronLeft } from "@mui/icons-material"

interface HeadingBarProps {
  title: string
}

const SecondHeadingBar: FC<HeadingBarProps> = ({ title }) => {
  const nav = useNavigate()

  return (
    <Box sx={{ backgroundColor: "#f36c21", p: "10px" }}>
      <Box
        sx={{ margin: { md: "0px 50px", xs: 0 } }}
        className="secondheadingbarparent"
      >
        <Typography
          sx={{
            textAlign: "left",
            color: "#fff",
            fontSize: "14px",
            textTransform: "capitalize",
          }}
        >
          <Button
            onClick={() => nav(-1)}
            disableRipple
            disableTouchRipple
            sx={{
              minWidth: 0,
              borderRadius: "50%",
              backgroundColor: "#fff",
              height: { md: 30, xs: 25 },
              width: { md: 30, xs: 25 },
              mr: 1,
              cursor: "default",
              ":hover": {
                backgroundColor: "#0000008b",
              },
            }}
          >
            <ChevronLeft sx={{ color: "#f36c21" }} />
          </Button>
          {title}
        </Typography>
      </Box>
    </Box>
  )
}

export default SecondHeadingBar
