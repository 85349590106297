import React, { Dispatch, FC, SetStateAction, useEffect, useState } from "react"
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Fade,
  Box,
  Typography,
  List,
  ListItem,
} from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import { CiLogout } from "react-icons/ci"
import { useLazyLogoutQuery } from "../api/mainApi/mainApiSlice"
import snackBarUtil from "../utils/snackbar"
import { SnackbarContent } from "notistack"
import { useNavigate } from "react-router"

interface InfoDialogProps {
  setLogoutModal: Dispatch<SetStateAction<boolean>>
  logoutmodal: boolean
  rightDrawerToggle: (newOpen: boolean) => () => void
}

const LogoutModal: FC<InfoDialogProps> = ({
  setLogoutModal,
  logoutmodal,
  rightDrawerToggle,
}) => {
  const handleClose = () => {
    setLogoutModal(false)
  }
  const nav = useNavigate()
  const demotoken = localStorage.getItem("demotoken")
  const token = localStorage.getItem("token")
  const [logoutTrigger, { data: logoutdata, isSuccess, isError }] =
    useLazyLogoutQuery()

  const handleLogout = async () => {
    await logoutTrigger({})
    handleClose()
    rightDrawerToggle(false)
  }

  useEffect(() => {
    if (logoutdata?.status) {
      nav("/")
      snackBarUtil.success(
        <SnackbarContent role="alert">
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Typography
              sx={{ color: "#fff", fontSize: "16px", textAlign: "left" }}
            >
              Success!
            </Typography>
            <Typography
              sx={{ color: "#fff", fontSize: "12px", textAlign: "left" }}
            >
              Successfully Logged Out
            </Typography>
          </Box>
        </SnackbarContent>,
      )
    }
  }, [logoutdata, nav])

  return (
    <Dialog
      open={logoutmodal}
      TransitionComponent={Fade}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
      fullWidth
      maxWidth="sm"
      sx={{
        ".MuiDialog-paper": {
          position: "absolute",
          top: 0,
          backgroundColor: "#fff",
          borderRadius: "8px",
          maxWidth: "500px",
        },
      }}
    >
      <DialogTitle
        sx={{
          backgroundColor: "#f36c21",
          padding: "16px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography
            sx={{
              textTransform: "capitalize",
              fontSize: "16px",
              color: "#000",
              fontWeight: "600",
            }}
          >
            exit
          </Typography>
          <Button
            disableRipple
            disableTouchRipple
            onClick={handleClose}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              minWidth: "unset",
              backgroundColor: "transparent",
              ":hover": {
                backgroundColor: "transparent",
              },
            }}
          >
            <CloseIcon sx={{ color: "#fff" }} />
          </Button>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box className="modalinfo">
          <Box
            sx={{
              m: "0 auto",
              borderRadius: "100%",
              backgroundColor: "rgba(2,0,36,.1)",
              padding: "10px",
              width: "fit-content",
            }}
          >
            <CiLogout color="#02b373" fontSize={25} fontWeight="bold" />
          </Box>
        </Box>

        <Typography
          sx={{
            textAlign: "center",
            color: "#000",
            fontWeight: 600,
            fontSize: { md: "18px", xs: "14px" },
            display: { md: "block", xs: "none" },
          }}
          component="h3"
        >
          Exit {window.location.hostname.split(".")[0]}?
        </Typography>
        <Typography
          component="p"
          sx={{
            color: "#746969",
            textAlign: "center",
            fontWeight: 600,
            fontSize: { md: "14px", xs: "12px" },
          }}
        >
          Are you sure you want to quit?
        </Typography>
      </DialogContent>
      <DialogActions
        sx={{
          display: "flex",
          gap: "10px",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Button
          onClick={handleClose}
          sx={{
            backgroundColor: "#f36c21",
            padding: "10px 15px",
            color: "#fff",
            fontSize: "15px",
            textTransform: "capitalize",
            minWidth: "unset",
            lineHeight: "17px",
            border: "1px solid #f36c21",

            ":hover": {
              color: "#f36c21",
              border: "1px solid #f36c21",
              background: "linear-gradient(0deg,hsla(0,0%,4%,.5) 50%,#323232)",
              backgroundColor: "#1e1e1e",
            },
          }}
        >
          No
        </Button>
        <Button
          onClick={handleLogout}
          sx={{
            backgroundColor: "#f36c21",
            padding: "10px 15px",
            color: "#fff",
            fontSize: "15px",
            textTransform: "capitalize",
            lineHeight: "17px",
            border: "1px solid #f36c21",
            minWidth: "unset",

            ":hover": {
              color: "#f36c21",
              border: "1px solid #f36c21",
              background: "linear-gradient(0deg,hsla(0,0%,4%,.5) 50%,#323232)",
              backgroundColor: "#1e1e1e",
            },
          }}
        >
          yes
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default LogoutModal
