import { AmountForm } from "./AmountForm";
import { PaymentMethods } from "./PaymentMethods";
import type { FC} from "react";
import React, { useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { ImageUploadContainer } from "./styledComponents";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import snackBarUtil from "../../../utils/snackbar";
import { useGetSelfDepositMutation } from "../../../api/mainApi/mainApiSlice";

interface Props {
  getDepositList: any
}
const DepositManually: FC<Props> = ({ getDepositList }) => {
  const [amount, setAmount] = useState(0);
  const [files, setFiles] = useState<Blob | null>(null);
  const [loading, setLoading] = useState(false);

  const [getSelfDeposit, {data:selfDeposit}] = useGetSelfDepositMutation()

  const handleSubmit = async () => {
    if (files?.size && files?.size / (1024 * 1024) > 6) {
      return snackBarUtil.error("File should not be bigger than 6mb !!!");
    }
    const data = new FormData();
    data.append("amount", amount.toString());

    data.append("image", files || "");
    setLoading(true);
    getSelfDeposit(data)
  };

  useEffect(()=>{
    if(selfDeposit?.status){
      setAmount(0);
      setFiles(null);
      getDepositList();
    }
  },[selfDeposit])
  return (
    <>
      <AmountForm amount={amount} setAmount={setAmount} />
      {amount >= 1 && (
        <Box maxWidth={{ md: 500 }} m={{ md: "auto" }}>
          <h2 style={{ color: "white", textAlign:"center", fontSize:"20px" }}>Pay {amount}/-</h2>
          <PaymentMethods />
          <label style={{ width: "100%" }}>
            {files ? (
              <img
                style={{ maxWidth: "90%", margin: "auto", maxHeight: "200px" }}
                src={URL.createObjectURL(files)}
                alt="uploaded_img"
              />
            ) : (
              <ImageUploadContainer>
                <AddCircleIcon htmlColor="white" />
                <Typography>Click here to upload payment screenshot</Typography>
              </ImageUploadContainer>
            )}
            <input
              accept="image/*"
              onChange={(e) => {
                if (e.target.files?.length) {
                  if (e.target.files[0]?.type.includes("image")) {
                    setFiles(e.target.files[0]);
                  } else {
                    snackBarUtil.error("Only image files allowed.");
                  }
                }
              }}
              type="file"
              style={{ display: "none" }}
            />
          </label>
          {files && (
            <Button
              fullWidth
              disabled={loading}
              sx={{
                borderRadius: "30px",
                width: "100%",
                display: "block",
                color: "#fff",
                textTransform: "uppercase",
                fontSize: { md: "13px", xs: "12px" },
                textAlign: "center",
                backgroundColor: "#fc6321",
                fontWeight: "600",
                minHeight: { md: "40px", xs: "35px" },
                border: "2px solid #fc6321",
                // maxWidth: 314,
                margin:"15px auto 0",
                maxWidth:"100%",
                ":hover": {
                  backgroundColor: "#fc6321",
                  border: "2px solid #fc6321",
                },
              }}
              
              onClick={handleSubmit}
              variant="contained"
              color="secondary"
            >
              Submit
            </Button>
          )}
        </Box>
      )}
    </>
  );
};

export default DepositManually;
