import {
  Box,
  Button,
  Tabs,
  Tab,
  Typography,
  Grid,
  List,
  ListItem,
  useMediaQuery,
  Tooltip,
  Grow,
} from "@mui/material"
import { Dispatch, FC, SetStateAction, useState } from "react"
import BottomStripBackLay from "./BottomStripBackLay"
import FancyTab from "./FancyTab"
import { useUserfancypnlQuery } from "../../../api/mainApi/mainApiSlice"
import { BetPlace } from "./SportsEventDetail"

interface BottomStripProps {
  sportDetailOddsData: BetFairSportEventDetailRes | undefined
  sportid: any
  DesktopModal: boolean
  handleClickModals: any
  handleDesktopModalClose: () => void
  matchid: any
  betPlaceData: BetPlace
  setBetPlacedData: Dispatch<SetStateAction<BetPlace>>
  token: string | null
  handleDesktopModalOpen: any
}

const BottomStrip: FC<BottomStripProps> = ({
  sportDetailOddsData,
  sportid,
  DesktopModal,
  handleDesktopModalClose,
  handleDesktopModalOpen,
  handleClickModals,
  matchid,
  setBetPlacedData,
  betPlaceData,
  token,
}) => {
  const [activeTab, setActiveTab] = useState(0)

  const [currtabVal, setCurrTabVal] = useState("Fancy")
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue)
  }

  const keys = Object.keys(sportDetailOddsData || {})
  const values = Object.values(sportDetailOddsData || {})

  const sportsData = keys.map((key, index) => {
    return { key, value: values[index] }
  })

  const tabFancy = ["Fancy", "Other Markets", "Premium"]

  const handleTab = (name: string, index: number) => {
    setCurrTabVal(name)
  }

  const { data: fancypnldata } = useUserfancypnlQuery(
    { matchId: matchid },
    { pollingInterval: 1000, skip: !token },
  )

  return (
    <Box className="wholetabwithtabpanel">
      <Box className="iframscorebtnheaderparent">
        <Tabs
          sx={{
            overflowX: "auto",
            background: "linear-gradient(0deg,hsla(0,0%,4%,.5) 50%,#323232)",
            borderBottom: "1px solid #333",
            borderTop: "1px solid #4c4c4c",
            backgroundColor: "#1e1e1e",
            padding: { md: "10px", xs: "5px" },
            marginBottom: "5px",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            gap: 1,
          }}
          value={activeTab}
          onChange={handleTabChange}
          aria-label="sports data tabs"
          variant="scrollable"
          scrollButtons="auto"
          TabIndicatorProps={{ style: { display: "none" } }}
        >
          {tabFancy?.map((tab, idx) => (
            <Tab
              onClick={() => handleTab(tab, idx)}
              key={tab + idx}
              label={tab}
              sx={{
                height: "32px",
                minHeight: "unset",
                "&.MuiButtonBase-root": {
                  p: 0,
                  mx: 0.5,
                },
                border: "1px solid",
                color: "#fff",
                // background: "linear-gradient(90deg,#2ab12f 50%,#364f37 95%)",
                background:
                  activeTab === idx
                    ? "#f36c21"
                    : "linear-gradient(90deg,#2ab12f 50%,#364f37 95%)",
                padding: "3px 20px !important",
                borderRadius: "30px",
                cursor: "pointer",
                textTransform: "capitalize",
                // minWidth: "unset",
                ":hover": {
                  backgroundColor: "#f36c21",
                },
              }}
            />
          ))}
        </Tabs>

        {/* tabpanel data------- */}

        <Box className="tabpanel">
          {sportsData &&
            sportsData?.map((matches, idx) => {
              if (matches.key != currtabVal) {
                return null
              }

              return (
                <Box
                  key={idx}
                  role="tabpanel"
                  // hidden={activeTab !== idx}
                >
                  <FancyTab
                    token={token}
                    handleDesktopModalOpen={handleDesktopModalOpen}
                    setBetPlacedData={setBetPlacedData}
                    betPlaceData={betPlaceData}
                    fancypnldata={fancypnldata}
                    handleClickModals={handleClickModals}
                    DesktopModal={DesktopModal}
                    handleDesktopModalClose={handleDesktopModalClose}
                    sportsData={sportsData}
                  />
                </Box>
              )
            })}
        </Box>
      </Box>
    </Box>
  )
}

export default BottomStrip
