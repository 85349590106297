import { Box, Grid } from "@mui/material"
import * as React from "react"
import Tabs from "@mui/material/Tabs"
import Tab from "@mui/material/Tab"
import { Container, Typography } from "@mui/material"
import crickimg from "https://s3buket.blr1.cdn.digitaloceanspaces.com/images/cricket.webp"
import footballimg from "https://s3buket.blr1.cdn.digitaloceanspaces.com/images/football.webp"
import tennisimg from "https://s3buket.blr1.cdn.digitaloceanspaces.com/images/tennis.webp"

import { Link } from "react-router-dom"
import { videodata } from "./gameTabsvideodata"
import { cricketdata } from "./cricketdata"
import { footballdata } from "./footballdata"
import { tennisdata } from "./tennisdata"

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ m: 1 }}>{children}</Box>}
    </div>
  )
}

function SecondCustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`second-tabpanel-${index}`}
      aria-labelledby={`second-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ m: 1 }}>{children}</Box>}
    </div>
  )
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  }
}

function secontabprops(index: number) {
  return {
    id: `second-tab-${index}`,
    "aria-controls": `second-tabpanel-${index}`,
  }
}

const tabStyle = (isActive: boolean) => ({
  backgroundColor: isActive ? "#000" : "#272727",
  height: "36px",
  minWidth: "64px",
  borderRadius: "0.375rem",
  padding: "0 16px",
  minHeight: "auto",
  margin: "0 5px", // Add margin for space between tabs
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  ":hover": {
    border: "1px solid #000",
  },
})

const tabData = [
  {
    label: "Cricket",
    index: 0,
    imageUrl:
      "https://s3buket.blr1.cdn.digitaloceanspaces.com/images/cricket.webp",
  },
  {
    label: "Football",
    index: 1,
    imageUrl:
      "https://s3buket.blr1.cdn.digitaloceanspaces.com/images/football.webp",
  },
  {
    label: "Tennis",
    index: 2,
    imageUrl:
      "https://s3buket.blr1.cdn.digitaloceanspaces.com/images/football.webp",
  },
]

const secondTabStyle = {
  fontSize: "12px",
  textTransform: "uppercase",
  lineHeight: "1.25rem",
  cursor: "pointer",
  padding: "0 16px",
  minHeight: "auto",
  height: "35px",
  borderRadius: "3px",
  minWidth: "90px",
  //   ":hover": {
  //     backgroundColor: "#161616",
  //   },
}

const MarketsTabs = () => {
  const [value, setValue] = React.useState(0)
  const [secondVal, setSecondVal] = React.useState(0)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  const handleChildTabchange = (
    event: React.SyntheticEvent,
    newValue: number,
  ) => {
    setSecondVal(newValue)
  }
  return (
    <Box sx={{ backgroundColor: "#000" }}>
      <Container maxWidth="lg" disableGutters sx={{ padding: "17px 0" }}>
        <Box sx={{ width: "100%" }}>
          <Box>
            <Tabs
              sx={{
                minHeight: "auto",
                "& .MuiTabs-flexContainer": {
                  flexWrap: "wrap",
                  rowGap: "5px",
                },
              }}
              TabIndicatorProps={{
                style: {
                  backgroundColor: "#f36c21",
                },
              }}
              value={value}
              onChange={handleChange}
              aria-label="game tabs"
            >
              {tabData.map(tab => (
                <Tab
                  key={tab.index}
                  sx={tabStyle(value === tab.index)}
                  {...a11yProps(tab.index)}
                  label={
                    <Box
                      sx={{ display: "flex", alignItems: "center", gap: "8px" }}
                    >
                      <img
                        src={tab.imageUrl}
                        alt={tab.label}
                        width={22}
                        height={22}
                      />
                      {tab.label}
                    </Box>
                  }
                />
              ))}
            </Tabs>
          </Box>

          <SecondCustomTabPanel value={value} index={0}>
            <Box sx={{ width: "100%" }} className="aboutmarketdatatabpanel">
              <Box className="secondtabparent">
                <Tabs
                  sx={{ minHeight: "auto" }}
                  TabIndicatorProps={{
                    style: {
                      backgroundColor: "#f36c21",
                    },
                  }}
                  value={secondVal}
                  onChange={handleChildTabchange}
                  aria-label="basic tabs example"
                >
                  <Tab
                    disableTouchRipple
                    disableRipple
                    sx={secondTabStyle}
                    label={
                      <Typography
                        component="p"
                        sx={{
                          fontSize: "12px",
                          color: "#9e9e9e",
                          textTransform: "uppercase",
                          fontWeigh: "700",
                        }}
                      >
                        about markets
                      </Typography>
                    }
                    {...secontabprops(0)}
                  />
                </Tabs>
              </Box>

              <CustomTabPanel value={secondVal} index={0}>
                <Box className="aboutdata" sx={{ p: 0 }}>
                  <Typography
                    component="h1"
                    sx={{
                      fontSize: "1.8rem",
                      fontWeight: "600",
                      textAlign: "left",
                      color: "#fff",
                    }}
                  >
                    Put your cricket knowledge to the test. Play online and earn
                    money.
                  </Typography>
                  <Box className="roulette">
                    <Typography
                      component="h2"
                      sx={{
                        fontSize: "20.8px",
                        color: "#fff",
                        fontWeight: "600",
                        mb: "10px",
                        textAlign: "left",
                      }}
                    >
                      Cricket:
                    </Typography>
                    <Typography
                      component="h2"
                      sx={{
                        fontSize: ".85rem",
                        color: "#fff",
                        mb: "10px",
                        textAlign: "left",
                        paddingRight: "20px",
                      }}
                    >
                      Do you play cricket online? Are you an ardent fan of
                      cricket? Are you into cricket betting? {window.location.hostname.split(".")[0]} is
                      one of the most trusted sports betting and{" "}
                      <Link
                        to={"#"}
                        className="text-[#fc6321] hover:text-white"
                      >
                        cricket betting
                      </Link>{" "}
                      sites. It offers you a chance to play cricket online and
                      earn money. We offer various leagues, live matches,
                      real-time betting and real-time winning. Start cricket
                      betting today and earn money. At any given point, you can
                      check live cricket betting rates, and place your bets. You
                      can trust us with your money and we offer daily exchanges.
                      Sign up on {window.location.hostname.split(".")[0]} for cricket betting online.
                      Cricket betting mainly consists placing of wagers on match
                      winners and fancy sessions.
                    </Typography>

                    <Grid container sx={{ py: 2 }}>
                      {cricketdata?.map((crickdata, idx) => (
                        <Grid item xs={12} key={idx}>
                          <Grid
                            container
                            sx={{
                              py: 2,
                              borderBlock:
                                crickdata?.heading === "Match Odds:"
                                  ? "1px solid #cccccc71"
                                  : "",
                            }}
                          >
                            <Typography
                              component="h2"
                              sx={{
                                fontSize: "20.8px",
                                color: "#fff",
                                fontWeight: "600",
                                mb: "10px",
                                textAlign: "left",
                                width: "100%",
                              }}
                            >
                              {crickdata?.heading}
                            </Typography>
                            <Typography
                              component="p"
                              sx={{
                                width: "100%",
                                fontSize: "16px",
                                fontWeight: "600",
                                color: "#fff",
                                mb: "10px",
                                textAlign: "left",
                              }}
                            >
                              {crickdata?.data?.subheading}
                            </Typography>
                            <Grid item xs={12} md={6}>
                              <Typography
                                component="p"
                                sx={{
                                  fontSize: ".85rem",
                                  color: "#fff",
                                  mb: "10px",
                                  textAlign: "left",
                                }}
                              >
                                {crickdata?.data?.titleone}
                              </Typography>
                              <Typography
                                component="p"
                                sx={{
                                  fontSize: ".85rem",
                                  color: "#fff",
                                  mb: "10px",
                                  textAlign: "left",
                                }}
                              >
                                {crickdata?.data?.titletwo}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <Box
                                sx={{
                                  m: "auto",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <img
                                  src={crickdata?.data?.img}
                                  alt=""
                                  className="w-[100%] lg:w-[50%]"
                                />
                              </Box>
                            </Grid>
                          </Grid>
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                </Box>
              </CustomTabPanel>
            </Box>
          </SecondCustomTabPanel>

          <SecondCustomTabPanel value={value} index={1}>
            <Box sx={{ width: "100%" }} className="aboutmarketdatatabpanel">
              <Box className="secondtabparent">
                <Tabs
                  sx={{ minHeight: "auto" }}
                  TabIndicatorProps={{
                    style: {
                      backgroundColor: "#f36c21",
                    },
                  }}
                  value={secondVal}
                  onChange={handleChildTabchange}
                  aria-label="basic tabs example"
                >
                  <Tab
                    disableTouchRipple
                    disableRipple
                    sx={secondTabStyle}
                    label={
                      <Typography
                        component="p"
                        sx={{
                          fontSize: "12px",
                          color: "#9e9e9e",
                          textTransform: "uppercase",
                          fontWeigh: "700",
                        }}
                      >
                        about markets
                      </Typography>
                    }
                    {...secontabprops(0)}
                  />
                </Tabs>
              </Box>

              <CustomTabPanel value={secondVal} index={0}>
                <Box className="aboutdata" sx={{ p: 0 }}>
                  <Typography
                    component="h1"
                    sx={{
                      fontSize: "1.8rem",
                      fontWeight: "600",
                      textAlign: "left",
                      color: "#fff",
                    }}
                  >
                    A whole new level to your football “goals”. Play online
                    today.
                  </Typography>
                  <Box className="roulette">
                    <Typography
                      component="h2"
                      sx={{
                        fontSize: "20.8px",
                        color: "#fff",
                        fontWeight: "600",
                        mb: "10px",
                        textAlign: "left",
                      }}
                    >
                      Football :
                    </Typography>
                    <Typography
                      component="h2"
                      sx={{
                        fontSize: ".85rem",
                        color: "#fff",
                        mb: "10px",
                        textAlign: "left",
                        paddingRight: "20px",
                      }}
                    >
                      On {window.location.hostname.split(".")[0]}, we offer comprehensive football
                      betting on various leagues. Watch online football games
                      and win a lot of money. Bet on all popular leagues and
                      even the lesser known leagues. We are one of the best
                      betting sites for{" "}
                      <Link to={"#"} className="text-[#f36c21]">
                        football.
                      </Link>{" "}
                      Watch online soccer games, play and win cash. Your hard
                      earned money can be doubled in a single game if you bet
                      right on the online football championship. Your money is
                      safe with us and we offer daily exchanges unlike bookies
                      who offer weekly exchanges. Sign up on {window.location.hostname.split(".")[0]}
                      today! Cricket betting mainly consists placing of wagers
                      on match winners and fancy sessions.
                    </Typography>

                    <Grid container sx={{ py: 2 }}>
                      {footballdata?.map((footballdata, idx) => (
                        <Grid item xs={12} key={idx}>
                          <Grid
                            container
                            sx={{
                              py: 2,
                              borderBlock:
                                footballdata?.heading === "Match Odds:"
                                  ? "1px solid #cccccc71"
                                  : "",
                            }}
                          >
                            <Typography
                              component="h2"
                              sx={{
                                fontSize: "20.8px",
                                color: "#fff",
                                fontWeight: "600",
                                mb: "10px",
                                textAlign: "left",
                                width: "100%",
                              }}
                            >
                              {footballdata?.heading}
                            </Typography>
                            <Typography
                              component="p"
                              sx={{
                                width: "100%",
                                fontSize: "16px",
                                fontWeight: "600",
                                color: "#fff",
                                mb: "10px",
                                textAlign: "left",
                              }}
                            >
                              {footballdata?.data?.subheading}
                            </Typography>
                            <Grid item xs={12} md={6}>
                              <Typography
                                component="p"
                                sx={{
                                  fontSize: ".85rem",
                                  color: "#fff",
                                  mb: "10px",
                                  textAlign: "left",
                                }}
                              >
                                {footballdata?.data?.titleone}
                              </Typography>
                              <Typography
                                component="p"
                                sx={{
                                  fontSize: ".85rem",
                                  color: "#fff",
                                  mb: "10px",
                                  textAlign: "left",
                                }}
                              >
                                {footballdata?.data?.titletwo}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <Box
                                sx={{
                                  m: "auto",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <img
                                  src={footballdata?.data?.img}
                                  alt=""
                                  className="w-[100%]"
                                />
                              </Box>
                            </Grid>
                          </Grid>
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                </Box>
              </CustomTabPanel>
            </Box>
          </SecondCustomTabPanel>

          <SecondCustomTabPanel value={value} index={2}>
            <Box sx={{ width: "100%" }} className="aboutmarketdatatabpanel">
              <Box className="secondtabparent">
                <Tabs
                  sx={{ minHeight: "auto" }}
                  TabIndicatorProps={{
                    style: {
                      backgroundColor: "#f36c21",
                    },
                  }}
                  value={secondVal}
                  onChange={handleChildTabchange}
                  aria-label="basic tabs example"
                >
                  <Tab
                    disableTouchRipple
                    disableRipple
                    sx={secondTabStyle}
                    label={
                      <Typography
                        component="p"
                        sx={{
                          fontSize: "12px",
                          color: "#9e9e9e",
                          textTransform: "uppercase",
                          fontWeigh: "700",
                        }}
                      >
                        about markets
                      </Typography>
                    }
                    {...secontabprops(0)}
                  />
                </Tabs>
              </Box>

              <CustomTabPanel value={secondVal} index={0}>
                <Box className="aboutdata" sx={{ p: 0 }}>
                  <Typography
                    component="h1"
                    sx={{
                      fontSize: "1.8rem",
                      fontWeight: "600",
                      textAlign: "left",
                      color: "#fff",
                    }}
                  >
                    The grass is greener on our tennis courts. Play online now.
                  </Typography>
                  <Box className="roulette">
                    <Typography
                      component="h2"
                      sx={{
                        my: 2,
                        fontSize: "20.8px",
                        color: "#fff",
                        fontWeight: "600",
                        textAlign: "left",
                      }}
                    >
                      Tennis:
                    </Typography>
                    <Typography
                      component="h2"
                      sx={{
                        fontSize: ".85rem",
                        color: "#fff",
                        mb: "10px",
                        textAlign: "left",
                        paddingRight: "20px",
                      }}
                    >
                      Looking to play a table tennis online multiplayer game
                      where you can play and earn? We’ve got you covered. Play
                      the online tennis game or the table tennis online game and
                      win real cash. Your money is safe with us and we offer
                      exchange daily. You no longer have to rely on undependable
                      bookies or other online scams. Sign up today, and play
                      tennis online with {window.location.hostname.split(".")[0]}.
                      <br />{" "}
                      <Link
                        to={"#"}
                        className="text-[#f36c21] hover:text-white"
                      >
                        Tennis betting
                      </Link>{" "}
                      consists primarily of match outcome and is a two horse
                      game. Due to its volatility, it's considered one of the
                      most profitable avenues for bettors.
                    </Typography>

                    <Grid container sx={{ py: 2 }}>
                      {tennisdata?.map((tennis, idx) => (
                        <Grid item xs={12} key={idx}>
                          <Grid
                            container
                            sx={{
                              py: 2,
                              borderTop:
                                tennis?.heading === "Match Odds:"
                                  ? "1px solid #cccccc71"
                                  : "",
                            }}
                          >
                            <Typography
                              component="h2"
                              sx={{
                                fontSize: "20.8px",
                                color: "#fff",
                                fontWeight: "600",
                                mb: "10px",
                                textAlign: "left",
                                width: "100%",
                              }}
                            >
                              {tennis?.heading}
                            </Typography>
                            <Typography
                              component="p"
                              sx={{
                                width: "100%",
                                fontSize: "16px",
                                fontWeight: "600",
                                color: "#fff",
                                mb: "10px",
                                textAlign: "left",
                              }}
                            >
                              {tennis?.data?.subheading}
                            </Typography>
                            <Grid item xs={12} md={6}>
                              <Typography
                                component="p"
                                sx={{
                                  fontSize: ".85rem",
                                  color: "#fff",
                                  textAlign: "left",
                                }}
                              >
                                {tennis?.data?.titleone}
                              </Typography>
                              <Typography
                                component="p"
                                sx={{
                                  fontSize: ".85rem",
                                  color: "#fff",
                                  mb: "10px",
                                  textAlign: "left",
                                }}
                              >
                                {tennis?.data?.titletwo}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <Box
                                sx={{
                                  m: "auto",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <img
                                  src={tennis?.data?.img}
                                  alt=""
                                  className="w-[100%]"
                                />
                              </Box>
                            </Grid>
                          </Grid>
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                </Box>
              </CustomTabPanel>
            </Box>
          </SecondCustomTabPanel>
        </Box>
      </Container>
    </Box>
  )
}
export default MarketsTabs
