import { Box, Typography } from "@mui/material"
import { FC } from "react"

const SuspandedComp = () => {
  return (
    <>
      <Box
        sx={{
          position: "absolute",
          zIndex: 9,
          left: { md: 0, xs: "19%" },
          top: 0,
          width: { md: "100%", xs: "81%" },
          backgroundColor: "#999999",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: { md: "0", xs: ".5rem" },
        }}
        className="suspandedbox"
      >
        <Typography
          sx={{
            backgroundColor: "#6a6868",
            color: "#000",
            height: "100%",
            display: "inline-block",
            padding: { md: "6px", xs: "5px" },
          }}
        >
          <Typography
            component="span"
            sx={{
              background: "#e0e0e0",
              width: { md: "115px", xs: "100px" },
              display: "inline-block",
              borderRadius: "20px",
              lineHeight: "30px",
              letterSpacing: "1px",
              textTransform: "uppercase",
              margin: "3px 0",
              fontSize: { md: "12px", xs: "10px" },
              textAlign: "center",
            }}
          >
            suspanded
          </Typography>
        </Typography>
      </Box>
    </>
  )
}
export default SuspandedComp
