import {
  Box,
  Container,
  List,
  ListItem,
  Typography,
  useMediaQuery,
} from "@mui/material"
import { Link } from "react-router-dom"
import HeadingBar from "../../../../common/HeadingBar"
// import aboutbrandimg from "https://s3buket.blr1.cdn.digitaloceanspaces.com/images/brand-ambass-desktop.webp"
// import aboutmobileimg from "https://s3buket.blr1.cdn.digitaloceanspaces.com/images/brand-ambass-mobile.webp"
// import aboutofferimg from "https://s3buket.blr1.cdn.digitaloceanspaces.com/images/offer-desktop.webp"
import AboutDownloadTheApp from "./DownloadTheApp"
import { socialmediadata } from "./socialdata"

const AboutPage = () => {
  const isMobile = useMediaQuery("(max-width:600px)")

  return (
    <Box className="aboutpageparent">
      <HeadingBar title="about us" />

      <Box className="aboutimgholder">
        {!isMobile && (
          <img
            src={
              "https://s3buket.blr1.cdn.digitaloceanspaces.com/images/brand-ambass-desktop.webp"
            }
            alt=""
            className=" h-full max-w-[100%]"
          />
        )}

        {isMobile && (
          <img
            src={
              "https://s3buket.blr1.cdn.digitaloceanspaces.com/images/brand-ambass-mobile.webp"
            }
            alt=""
            className="max-w-[100%] h-full"
          />
        )}
      </Box>

      <Box className="aboutcontentholder">
        <Container maxWidth="xl" sx={{ p: "17px 4px" }}>
          <Box className="aboutcontent">
            <Typography
              component="h2"
              sx={{
                fontSize: "2.125rem",
                lineHeight: "2.5rem",
                letterSpacing: ".0073529412em",
                textAlign: "center",
                fontFamily: "Roboto,sans-serif",
                color: "#fff",
                fontWeight: "700",
              }}
            >
              About us
            </Typography>
            <Typography
              component="p"
              sx={{ fontSize: "14px", color: "#fff", textAlign: "left" }}
            >
              Welcome to {window.location.hostname.split(".")[0]}-India’s most trusted and popular
              betting exchange. Offering a wide variety of sports and
              entertainment leisure gambling, {window.location.hostname.split(".")[0]} was designed to
              be a platform where winning money meets thrill, fun and
              excitement.
            </Typography>
            <Typography
              component="p"
              sx={{ fontSize: "14px", color: "#fff", textAlign: "left", my: 2 }}
            >
              <Link to={"#"} className="text-[#fc6321] underline">
                Cricket
              </Link>{" "}
              is the most loved sport on {window.location.hostname.split(".")[0]}, shortly followed by
              <Link to={"#"} className="text-[#fc6321] underline">
                Football
              </Link>
              ,{" "}
              <Link to={"#"} className="text-[#fc6321] underline">
                Tennis
              </Link>{" "}
              and 30+ other premium sports. A full fledged{" "}
              <Link to={"#"} className="text-[#fc6321] underline">
                live casino
              </Link>{" "}
              and{" "}
              <Link to={"#"} className="text-[#fc6321] underline">
                Card games
              </Link>{" "}
              complete with games such as{" "}
              <Link to={"#"} className="text-[#fc6321] underline">
                Baccarat
              </Link>
              ,{" "}
              <Link to={"#"} className="text-[#fc6321] underline">
                Teen Patti
              </Link>{" "}
              ,
              <Link to={"#"} className="text-[#fc6321] underline">
                Poker
              </Link>
              ,
              <Link to={"#"} className="text-[#fc6321] underline">
                Roulette
              </Link>
              ,
              <Link to={"#"} className="text-[#fc6321] underline">
                Andar-Bahar
              </Link>{" "}
              and numerous more is conducted by real dealers instead of bots,
              giving the player the chance to live the thrill of a live casino
              from the comfort of his home or even commute. All sports matches
              are streamed live on {window.location.hostname.split(".")[0]} for the player’s ease to
              watch and win at the same time! Every new Club member is treated
              to a hefty bonus of 300% on the first deposit and a 50% bonus on
              the second.
            </Typography>
          </Box>

          <Box className="aboutimgholder" sx={{ my: 5 }}>
            <Link to={"#"}>
              <img
                src={
                  "https://s3buket.blr1.cdn.digitaloceanspaces.com/images/offer-desktop.webp"
                }
                alt=""
                className="w-[100%]"
              />
            </Link>
          </Box>

          <Box className="contentbox" sx={{ my: 4 }}>
            <Typography
              component="p"
              sx={{ fontSize: "1rem", color: "#fff", textAlign: "left" }}
            >
              {window.location.hostname.split(".")[0]} endorses and encourages authentic gambling and
              provides state of the art security for the Club members to have
              fun while playing responsibly. The user can withdraw his winnings
              instantly from his {window.location.hostname.split(".")[0]} account within minutes, at no
              extra charge! Download the {window.location.hostname.split(".")[0]} app for a seamless and
              smooth experience on {window.location.hostname.split(".")[0]}. Team {window.location.hostname.split(".")[0]}
              prides itself on being a turning point in the world of safe online
              gambling and making genuine, transparent gambling accessible to
              all enthusiasts.
            </Typography>
          </Box>

          <AboutDownloadTheApp />

          <Box className="contentbox" sx={{ my: 4 }}>
            <Typography
              sx={{
                letterSpacing: ".0178571429em",
                fontSize: ".875rem",
                fontWeight: "400",
                marginBottom: "8px",
                lineHeight: "1.25rem",
                color: "#fff",
                textAlign: "left",
              }}
            >
              At {window.location.hostname.split(".")[0]}, we have a zero tolerance policy towards any
              kind of fraudulent activities. Any user who is found violating any
              terms and conditions will find his account suspended from
              {window.location.hostname.split(".")[0]} Club instantly. {window.location.hostname.split(".")[0]} ensures the
              confidentiality of its users and the security of their information
              at all times. All safety measures are taken with regard to
              customer data and their transactions and no data is ever shared
              with a third party by {window.location.hostname.split(".")[0]}.
            </Typography>
            <Typography
              sx={{
                letterSpacing: ".0178571429em",
                fontSize: ".875rem",
                fontWeight: "400",
                marginBottom: "8px",
                lineHeight: "1.25rem",
                color: "#fff",
                textAlign: "left",
              }}
            >
              For all queries and site related questions, our client support
              team is available 24*7.
            </Typography>
            <Typography
              sx={{
                letterSpacing: ".0178571429em",
                fontSize: ".875rem",
                fontWeight: "400",
                marginBottom: "8px",
                lineHeight: "1.25rem",
                color: "#fff",
                textAlign: "left",
              }}
            >
              Follow us on social media for exciting contests and app updates!
            </Typography>
          </Box>

          <Box className="socialmedia">
            <List sx={{ display: "flex", p: 0 }}>
              {socialmediadata?.map((socialimg, index) => (
                <ListItem key={index} sx={{ p: 0, width: "auto" }}>
                  <Link to={socialimg.url}>
                    <img
                      src={socialimg.img}
                      alt=""
                      className="hover:bg-[#252525] h-[32px] w-[32px] rounded-[100px] p-[5px]"
                    />
                  </Link>
                </ListItem>
              ))}
            </List>
          </Box>
        </Container>
      </Box>
    </Box>
  )
}

export default AboutPage
