import React, { Dispatch, FC, SetStateAction, useState } from "react"
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Typography,
  TextField,
  FormControlLabel,
} from "@mui/material"
import Switch from "@mui/material/Switch"
import CloseIcon from "@mui/icons-material/Close"
import Slide from "@mui/material/Slide"
import { TransitionProps } from "@mui/material/transitions"
interface HorseBetModalProps {
  horseModal: boolean
  setHorseModal: React.Dispatch<React.SetStateAction<boolean>>
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="down" ref={ref} {...props} />
})

const HorseBetModal: FC<HorseBetModalProps> = ({
  horseModal,
  setHorseModal,
}) => {
  const handleClose = () => {
    setHorseModal(!horseModal)
  }

  const [val, setVal] = useState<number>(1.56)
  const [stakeval, setStakeVal] = useState("")
  const [checked, setChecked] = useState(false)

  const handleChange = (e: any) => {
    setVal(e.target.value)
  }

  const handleIncrement = () => {
    setVal(val => val + 1)
  }

  const handleDecrement = () => {
    if (val <= 0) {
      return
    } else {
      setVal(val => val - 1)
    }
  }

  const stakevalarr = [200, 500, 700, 1000]

  const handleStakeVal = (e: any) => {
    setStakeVal(e.target.value)
  }

  const handleChangeChecked = (e: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(e.target.checked)
  }

  return (
    <Dialog
      open={horseModal}
      TransitionComponent={Transition}
      transitionDuration={300}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
      fullWidth
      maxWidth="sm"
      sx={{
        ".MuiDialog-paper": {
          position: "absolute",
          top: 0,
          backgroundColor: "#fff",
          borderRadius: "8px",
          maxWidth: "500px",
        },
      }}
    >
      <DialogTitle
        sx={{
          backgroundColor: "#f36c21",
          padding: "16px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography
            sx={{
              textTransform: "capitalize",
              fontSize: "16px",
              color: "#000",
              fontWeight: "600",
            }}
          >
            Back Rajasthan Royals
          </Typography>
          <Button
            disableRipple
            disableTouchRipple
            onClick={handleClose}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              minWidth: "unset",
              backgroundColor: "transparent",
              ":hover": {
                backgroundColor: "transparent",
              },
            }}
          >
            <CloseIcon sx={{ color: "#fff" }} />
          </Button>
        </Box>
      </DialogTitle>

      <DialogContent sx={{ px: "5px" }}>
        {/* odds */}
        <Box className="number-sec-row" sx={{ pt: 2 }}>
          <Typography
            component="h5"
            sx={{
              fontWeight: "400",
              fontSize: "14px",
              lineHeight: "normal",
              textTransform: "uppercase",
              margin: "4px 0",
              color: "#000",
            }}
          >
            odds
          </Typography>

          <Box
            className="number"
            sx={{ display: "flex", alignItems: "center" }}
          >
            <Typography
              onClick={handleDecrement}
              sx={{
                width: "45px",
                height: "36px",
                backgroundColor: "#f36c21",
                color: "#fff",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
                fontWeight: "600",
                fontSize: "20px",
                userSelect: "none",
              }}
            >
              -
            </Typography>

            <TextField
              size="small"
              value={val}
              type="number"
              onChange={handleChange}
              fullWidth
              sx={{
                margin: 0,
                color: "#000",
                border: "1px solid #ccc",
                borderRadius: "1px",

                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    border: "none",
                  },
                },

                " .MuiInputBase-input": {
                  margin: 0,
                  color: "#000",
                  padding: "10px",
                },
              }}
              variant="outlined"
            />

            <Typography
              onClick={handleIncrement}
              sx={{
                width: "45px",
                height: "36px",
                backgroundColor: "#f36c21",
                color: "#fff",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
                fontWeight: "600",
                fontSize: "20px",
                userSelect: "none",
              }}
            >
              +
            </Typography>
          </Box>
        </Box>
        {/* odds */}

        {/* stake */}
        <Box className="stake">
          <Typography
            component="h5"
            sx={{
              fontWeight: "400",
              fontSize: "14px",
              lineHeight: "normal",
              textTransform: "uppercase",
              margin: "4px 0",
              color: "#000",
            }}
          >
            stake
          </Typography>

          <Box
            className="number"
            sx={{ display: "flex", alignItems: "center" }}
          >
            <TextField
              size="small"
              value={stakeval}
              type="number"
              onChange={handleChange}
              fullWidth
              placeholder="Enter here"
              sx={{
                margin: 0,
                color: "#000",
                border: "1px solid #ccc",
                borderRadius: "1px",
                ":hover": {
                  border: "1px solid #ccc",
                },

                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    border: "none",
                  },
                },

                " .MuiInputBase-input": {
                  margin: 0,
                  color: "#000",
                  padding: "10px",
                },
              }}
              variant="outlined"
            />
          </Box>
        </Box>
        {/* stake */}

        {/* stakebutton */}
        <Box
          className="the-gradient-btn"
          sx={{ display: "flex", alignItems: "center", pt: "6px" }}
        >
          {stakevalarr?.map((stakeval, idx) => (
            <Button
              key={stakeval + idx}
              className="stakebtnval"
              onClick={handleStakeVal}
            >
              <Typography
                component="span"
                sx={{
                  backgroundColor: "#d3ddcc",
                  marginRight: "10px",
                  color: "#000",
                  fontSize: "16px",
                  padding: "5px 20px",
                  border: "1px solid #f36c21",
                  borderRadius: "4px",
                  width: "100%",
                  lineHeight: "10px",
                  ":hover": {
                    backgroundColor: "#d3ddcc",
                    border: "1px solid #f36c21",
                  },
                }}
              >
                {stakeval}
              </Typography>
            </Button>
          ))}
        </Box>
        {/* stakebutton */}

        {/* accept any odds */}

        <Box sx={{ display: "flex", alignItems: "center" }}>
          <FormControlLabel
            control={
              <Switch
                disableTouchRipple
                disableRipple
                sx={{
                  "& .MuiSwitch-thumb": {
                    height: "10px",
                    width: "10px",
                    margin: "5px 0 0 5px",
                    ":hover": {
                      boxShadow: "none",
                    },
                  },
                  "& .MuiSwitch-switchBase.Mui-checked": {
                    color: "#fff",

                    "&:hover": {
                      backgroundColor: "transparent",
                    },
                  },
                  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                    boxShadow: "0 0 0 5px rgba(231,75,8,.25)",
                    backgroundColor: "#f36c21",
                    opacity: 1,
                    border: "1px solid rgba(0, 0, 0, 0.076)",
                  },
                  "& .MuiSwitch-track": {
                    backgroundColor: "#ffffff",
                    border: "1px solid rgba(0,0,0,.25)",
                    height: "15px",
                    width: "50px",
                  },
                }}
                checked={checked}
                onChange={handleChangeChecked}
                name="odds"
              />
            }
            label="Accept any odds"
            sx={{
              "& .MuiFormControlLabel-label": {
                color: "#000",
              },
            }}
          />
        </Box>

        {/* accept any odds */}
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={handleClose}
          size="small"
          sx={{
            border: "1px solid #f36c21",
            padding: "10px 0",
            flex: 1,
            color: "#000",
            fontSize: "16px",
            maxHeight: "41px",
            ":hover": {
              backgroundColor: "#5c636a",
              border: "1px solid #5c636a",
              color: "#fff",
            },
          }}
        >
          Cancel
        </Button>
        <Button
          variant="outlined"
          onClick={handleClose}
          size="small"
          sx={{
            backgroundColor: "#f36c21",
            border: "1px solid #f36c21",
            padding: "10px 0",
            flex: 1,
            maxHeight: "41px",
            color: "#fff",
            fontSize: "16px",
            ":hover": {
              backgroundColor: "#f36c21",
              border: "1px solid #f36c21",
            },
          }}
        >
          Place bet
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default HorseBetModal
