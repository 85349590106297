import * as React from "react"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import { styled } from "@mui/material"
import { Style } from "@mui/icons-material"

interface tableDataCompprops {
  accountStatementData: accountStatementResponse | undefined
}

const style = {
  whiteSpace: "nowrap",
  color: "#fff",
}

const TableDataComp: React.FC<tableDataCompprops> = ({
  accountStatementData,
}) => {
  return (
    <TableContainer
      sx={{
        backgroundColor: "#1e1e1e",
        border: 0,
        boxShadow: "-1px 2px 5px -1px rgba(56,55,55,0.73)",
      }}
    >
      <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell sx={style}>Date</TableCell>
            <TableCell sx={style} align="left">
              Sr no
            </TableCell>
            <TableCell sx={style} align="left">
              Credit
            </TableCell>
            <TableCell sx={style} align="left">
              Debit
            </TableCell>
            <TableCell sx={style} align="left">
              Pts
            </TableCell>
            <TableCell sx={style} align="left">
              Remarks
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {accountStatementData?.data?.dataList?.map((row, idx) => (
            <TableRow
              key={row.sno + idx}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell sx={style} component="td" scope="row">
                {row.date}
              </TableCell>
              <TableCell sx={style}>{row.sno}</TableCell>
              <TableCell sx={style} align="left">
                {row.credit}
              </TableCell>
              <TableCell sx={style} align="left">
                {row.debit}
              </TableCell>
              <TableCell sx={style} align="left">
                {row.pts}
              </TableCell>
              <TableCell sx={style} align="left">
                {row.remark}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default TableDataComp
