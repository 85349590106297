import { Box, Button, Typography, useMediaQuery } from "@mui/material"
import { BsGraphUp } from "react-icons/bs"
import { FaBarsStaggered } from "react-icons/fa6"
import { IoMdShareAlt } from "react-icons/io"
import { AiOutlineEyeInvisible } from "react-icons/ai"
import { AiOutlineEye } from "react-icons/ai"
import { FC, useState } from "react"
import tviconimg from "https://s3buket.blr1.cdn.digitaloceanspaces.com/images/tvico.webp"
import OpenBetsMatchedBetModal from "./OpenBetsMatchedBetModal"

interface IframeScoreBtnHeaderProps {
  handleScoreIframe: () => void
}

const IframeScoreBtnHeader: FC<IframeScoreBtnHeaderProps> = ({
  handleScoreIframe,
}) => {
  const isMobile = useMediaQuery("(max-width:900px)")
  const [score, setScore] = useState(false)
  const [iframe, setIframe] = useState(false)
  const [open, setOpen] = useState(false)
  const handleScoreShow = () => {
    setScore(!score)
    handleScoreIframe()
  }

  const handleIframe = () => {
    setIframe(!iframe)
  }

  const openMatchedBetsModal = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(!open)
  }

  return (
    <>
      <OpenBetsMatchedBetModal open={open} handleClose={handleClose} />
      <Box
        className="iframscorebtnheaderparent"
        sx={{
          background: "linear-gradient(0deg,hsla(0,0%,4%,.5) 50%,#323232)",
          borderBottom: "1px solid #333",
          borderTop: "1px solid #4c4c4c",
          backgroundColor: "#1e1e1e",
          padding: { md: "10px", xs: "5px" },
          marginBottom: "5px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box
          className="marketopenbetholder"
          sx={{
            display: "flex",
            alignItems: "center",
            gap: { md: "15px", xs: "10px" },
          }}
        >
          <Button
            disableTouchRipple
            disableRipple
            sx={{
              borderRadius: "100px",
              borderColor: "#fa7b04",
              boxShadow: "0 0 5px 0 #fa7b04",
              cursor: "pointer",
              padding: "1px 10px",
              background: "none",
              display: "flex",
              alignItems: "center",
              lineHeight: "25px",
              gap: "5px",
              ":hover": {
                background: "none",
              },
            }}
          >
            <Typography
              component="span"
              sx={{
                display: "inline-block",
                background: "#6fae44",
                width: "21px",
                height: "21px",
                padding: "5px",
                borderRadius: "100px",
                lineHeight: "8px",
              }}
            >
              <BsGraphUp fontSize={11} />
            </Typography>

            <Typography
              component="span"
              sx={{
                color: "#fff",
                fontSize: { md: "14px", xs: "12px" },
                textTransform: "capitalize",
              }}
            >
              Markets
            </Typography>
          </Button>

          <Button
            onClick={openMatchedBetsModal}
            disableTouchRipple
            disableRipple
            sx={{
              borderRadius: "100px",
              borderColor: "#6fad47",
              boxShadow: "0 0 5px 0 #6fad47",
              cursor: "pointer",
              padding: "1px 10px",
              background: "none",
              display: "flex",
              alignItems: "center",
              lineHeight: "25px",
              gap: "5px",
              ":hover": {
                borderColor: "#fa7b04",
                boxShadow: "0 0 5px 0 #fa7b04",
                background: "none",
              },
            }}
          >
            <Typography
              component="span"
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                background: "#343434",
                width: "22px",
                height: "22px",
                borderRadius: "100px",
                lineHeight: "normal",
              }}
            >
              <FaBarsStaggered fontSize={11} />
            </Typography>

            <Typography
              component="span"
              sx={{
                color: "#fff",
                fontSize: { md: "14px", xs: "12px" },
                textTransform: "capitalize",
              }}
            >
              openbet
            </Typography>
          </Button>
        </Box>

        <Box
          className="sharescorelivetvholder"
          sx={{ display: "flex", alignItems: "center", gap: "4px" }}
        >
          <Button
            className="sharebtn"
            disableTouchRipple
            disableRipple
            sx={{
              cursor: "pointer",
              display: "inline-block",
              height: "26px",
              minWidth: "unset",
              ":hover": {
                background: "none",
              },
            }}
          >
            <Typography
              component="span"
              sx={{
                background: "#fa7b04",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "4px",
                padding: "1px 12px",
                lineHeight: "normal",
              }}
            >
              <IoMdShareAlt fontSize={20} />
            </Typography>
          </Button>

          <Button
            onClick={handleScoreShow}
            className="scorebtn"
            disableTouchRipple
            disableRipple
            sx={{
              cursor: "pointer",
              background: "#008000",
              padding: "3px 12px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              minWidth: "unset",
              height: "auto",
              ":hover": {
                background: "#008000",
              },
            }}
          >
            <Typography
              component="span"
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                lineHeight: "normal",
              }}
            >
              {!score ? (
                <AiOutlineEyeInvisible fontSize={16} />
              ) : (
                <AiOutlineEye fontSize={16} />
              )}
            </Typography>
            {!isMobile && (
              <Typography
                component="span"
                sx={{
                  ml: "5px",
                  color: "#fff",
                  fontSize: "11px",
                  textTransform: "capitalize",
                }}
              >
                score
              </Typography>
            )}
          </Button>

          <Button
            onClick={handleIframe}
            className="livetvbtn"
            disableTouchRipple
            disableRipple
            sx={{
              cursor: "pointer",
              display: "inline-block",
              height: "26px",
              minWidth: "unset",
              ":hover": {
                background: "none",
              },
            }}
          >
            <img
              src={
                "https://s3buket.blr1.cdn.digitaloceanspaces.com/images/tvico.webp"
              }
              alt=""
              className="w-[23px] h-[23px] lg:w-[25px] lg:h-[25px]"
            />
          </Button>
        </Box>
      </Box>
    </>
  )
}
export default IframeScoreBtnHeader
