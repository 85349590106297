import React from "react"
import { createRoot } from "react-dom/client"
import { Provider } from "react-redux"
import App from "./App"
import "./index.css"
import { store } from "./store"
import { BrowserRouter } from "react-router-dom"
import { ThemeProvider } from "@mui/material"
import { theme } from "./utils/theme"
import { I18nextProvider } from "react-i18next"
import i18n from "./i18n"
import { SnackbarProvider } from "notistack"
import { SnackbarUtilsConfigurator } from "./utils/snackbar"

const container = document.getElementById("root")

if (container) {
  const root = createRoot(container)

  root.render(
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <SnackbarProvider
          anchorOrigin={{ horizontal: "center", vertical: "top" }}
          autoHideDuration={700}
        >
          <I18nextProvider i18n={i18n}>
            <React.StrictMode>
              <Provider store={store}>
                <App />
              </Provider>
            </React.StrictMode>
          </I18nextProvider>
          <SnackbarUtilsConfigurator />
        </SnackbarProvider>
      </ThemeProvider>
    </BrowserRouter>,
  )
} else {
  throw new Error(
    "Root element with ID 'root' was not found in the document. Ensure there is a corresponding HTML element with the ID 'root' in your HTML file.",
  )
}
