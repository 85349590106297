import { Box, Container, Grid, Typography } from "@mui/material"
import SecondHeadingBar from "../../../../common/SecondHeadingBar"
import "./profile.css"

const Profile = () => {
  return (
    <Box className="profile">
      <SecondHeadingBar title="Profile" />

      <Box className="profile-content" sx={{ py: 1.5 }}>
        <Container maxWidth="lg" sx={{ padding: { xs: 0 } }}>
          <Box className="profile-wrapper">
            <Box
              className="profile-header"
              sx={{
                backgroundColor: "#424242",
                padding: { md: "35px", xs: "10px" },
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                component="p"
                sx={{
                  color: "#fff",
                  fontSize: { md: "15px", xs: "12px" },
                  margin: "0 5px 0 0 ",
                  padding: "0 5px 0 0",
                  borderRight: "2px solid gray",
                }}
              >
                Your Current Turnover from (01-01-2023 to 30-06-2023) is
              </Typography>
              <Typography
                component="span"
                sx={{
                  color: "#fff",
                  fontSize: "28px",
                  fontWeight: "600",
                }}
              >
                0
              </Typography>
            </Box>
            <Box
              className="profile-content"
              sx={{
                backgroundColor: "#212121",
                padding: { md: "45px 0 25px", xs: "14px 0 24px" },
              }}
            >
              <div className="wrapper">
                <div className="gauge">
                  <div className="slice-colors">
                    <div className="st slice-item"></div>
                    <div className="st slice-item"></div>
                    <div className="st slice-item"></div>
                    <div className="st slice-item"></div>
                  </div>
                  <div className="needle needle-blue"></div>
                  <div className="gauge-center pt-12">
                    <div> 0 </div>
                    <div className="caption">Your Current Turnover from</div>
                  </div>
                </div>
              </div>

              <Box className="choice-color-loyality-sec" sx={{ mt: 4 }}>
                <Grid container>
                  <Grid item xs={6}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                        padding: "8px",
                      }}
                    >
                      <Typography
                        component="span"
                        sx={{
                          background:
                            "linear-gradient(180deg,#4376cf,#7e9ed9 45.89%,#4376cf)",

                          display: "inline-block",
                          textAlign: "start",
                          width: "14px",
                          height: "14px",
                          borderRadius: "100px",
                          marginRight: "15px",
                        }}
                      ></Typography>
                      <Typography
                        sx={{
                          width: "75px",
                          textAlign: "start",
                          color: "#4376cf",
                          fontSize: "11px",
                          letterSpacing: ".5px",
                        }}
                        component="span"
                      >
                        0 to Blue
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        padding: "8px",
                      }}
                    >
                      <Typography
                        component="span"
                        sx={{
                          background:
                            "linear-gradient(180deg,#7b7b7b,#bebebe 45.89%,#7b7b7b)",

                          display: "inline-block",
                          textAlign: "start",
                          width: "14px",
                          height: "14px",
                          borderRadius: "100px",
                          marginRight: "15px",
                        }}
                      ></Typography>
                      <Typography
                        sx={{
                          width: "75px",
                          textAlign: "start",
                          color: "#b2b2b2",
                          fontSize: "11px",
                          letterSpacing: ".5px",
                        }}
                        component="span"
                      >
                        1K to Silver
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                        padding: "8px",
                      }}
                    >
                      <Typography
                        component="span"
                        sx={{
                          background:
                            "linear-gradient(180deg,#c39b38,#dfba42 45.89%,#c89d37)",
                          display: "inline-block",
                          textAlign: "end",
                          width: "14px",
                          height: "14px",
                          borderRadius: "100px",
                          marginRight: "15px",
                        }}
                      ></Typography>
                      <Typography
                        sx={{
                          width: "75px",
                          textAlign: "start",
                          color: "#edcb49",
                          fontSize: "11px",
                          letterSpacing: ".5px",
                        }}
                        component="span"
                      >
                        10L to Gold
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        padding: "8px",
                      }}
                    >
                      <Typography
                        component="span"
                        sx={{
                          background:
                            "linear-gradient(180deg,#d0d0ce,#aaa8aa 45.89%,#919192)",

                          display: "inline-block",
                          textAlign: "start",
                          width: "14px",
                          height: "14px",
                          borderRadius: "100px",
                          marginRight: "15px",
                        }}
                      ></Typography>
                      <Typography
                        sx={{
                          textAlign: "start",
                          color: "#d0d0ce",
                          fontSize: "11px",
                          letterSpacing: ".5px",
                        }}
                        component="span"
                      >
                        1Cr to Platinum
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Box>
          <Box sx={{ padding: "20px 30px" }}>
            <Typography
              component="h5"
              sx={{ fontSize: "18px", color: "#fff", fontWeight: 500 }}
            >
              clickbet
            </Typography>
          </Box>
        </Container>
      </Box>
    </Box>
  )
}
export default Profile
