import { createSelector, createSlice } from "@reduxjs/toolkit";

const sportdatalength = createSlice({
    name: "sportdatalength",
    initialState: {
        length: 0
    },
    reducers: {
        setSportDataLength: (state, action) => {
            state.length = action.payload.length;
        }
    }
})

export default sportdatalength.reducer
export const { setSportDataLength } = sportdatalength.actions
export const sportdataSelector = createSelector((state) => state.sportdatalength, (state) => state)
