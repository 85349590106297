import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { CardContainerContainer } from "./styledComponents";
import Card from "./card";
import UPIDetailsNew from "./UPIDetailsNew";
import QRcodeComponentNew from "./QRcodeComponentNew";
import BankInfoComponentNew from "./BankInfoComponentNew";
import snackBarUtil from "../../../utils/snackbar";
import { useGetPaymentMethodMutation } from "../../../api/mainApi/mainApiSlice";

export interface BankDetailInterface {
  bankName: string;
  accountNumber: string;
  ifscCode: string;
  accountHolderName: string;
}

export interface UPIDetailsInterface {
  upiId: string;
  displayName: string;
  upiName: string;
}
export interface QRDetailInterface {
  displayName: string;
  qrCodeImage: string;
}


export function PaymentMethods() {
  const [selected, setSelected] = useState("");
  const [getPaymentData, {data:paymentDataNew}]  = useGetPaymentMethodMutation();

  const handleClick = (id: string) => {
    setSelected(id);
  };
  useEffect(() => {
    getPaymentData();
  }, []);


  useEffect(()=>{
   if (paymentDataNew) {
        try {
          if (paymentDataNew.data?.length) {
            setSelected(paymentDataNew.data[0].depositType);
          } else {
            snackBarUtil.error("Sorry no payment Methods Found");
          }
        } catch {
          snackBarUtil.error("Sorry no payment Methods Found");
        }
      }
  }, [paymentDataNew])

  return (
    <>
      <Typography mb={4} mt={1} sx={{
        fontSize:"18px",
        textAlign:"center"
      }}>Pay Manually</Typography>
      <CardContainerContainer>
        
        {paymentDataNew?.data?.map(
          (elem) => (
           
            <Card
              selected={selected === elem.depositType}
              details={{ logo: elem.image, methodName: elem.depositType }}
              handleClick={() => handleClick(elem.depositType)}
            />
          )
          // )
        )}
      </CardContainerContainer>
      {selected.toUpperCase() === "BANK" && (
        <BankInfoComponentNew
          bankDetails={paymentDataNew?.data?.find((i) => i.depositType === selected)}
          phonePe= {false}
        />
      )}
      {/* {selected === "UPI" && (
         <UPIDetails upiDetails={paymentData?.upiDetail} />
       )} */}
      {selected.toUpperCase() === "UPI" && (
        <UPIDetailsNew
          upiDetails={paymentDataNew?.data?.find((i) => i.depositType === "UPI")}
        />
      )}
      {/* {selected === "QR" && (
         <QRcodeComponent qrDetails={paymentData?.qrCode} />
       )} */}
      {selected.toUpperCase() === "QR" && (
        <QRcodeComponentNew
          qrDetails={paymentDataNew?.data?.find((i) => i.depositType === "QR")}
        />
      )}
      {selected.toUpperCase() === "PHONE PE" && (
        <BankInfoComponentNew
          bankDetails={paymentDataNew?.data?.find((i) => i.depositType === selected)}
          phonePe= {true}
        />
      )}
      {selected.toUpperCase() === "G PAY" && (
        <BankInfoComponentNew
          bankDetails={paymentDataNew?.data?.find((i) => i.depositType === selected)}
          phonePe= {true}
        />
      )}
    </>
  );
}
