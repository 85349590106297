import React, { Dispatch, FC, SetStateAction, useState } from "react"
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Fade,
  Box,
  Typography,
  List,
  ListItem,
} from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"

interface InfoDialogProps {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
}

const InfoDialog: FC<InfoDialogProps> = ({ setOpen, open }) => {
  const handleClose = () => {
    setOpen(false)
  }

  return (
    <Dialog
      open={open}
      TransitionComponent={Fade}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
      fullWidth
      maxWidth="sm"
      sx={{
        ".MuiDialog-paper": {
          position: "absolute",
          top: 0,
          backgroundColor: "#fff",
          borderRadius: "8px",
          maxWidth: "500px",
        },
      }}
    >
      <DialogTitle
        sx={{
          backgroundColor: "#f36c21",
          padding: "16px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography
            sx={{
              textTransform: "capitalize",
              fontSize: "16px",
              color: "#000",
              fontWeight: "600",
            }}
          >
            Rules & Results
          </Typography>
          <Button
            disableRipple
            disableTouchRipple
            onClick={handleClose}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              minWidth: "unset",
              backgroundColor: "transparent",
              ":hover": {
                backgroundColor: "transparent",
              },
            }}
          >
            <CloseIcon sx={{ color: "#fff" }} />
          </Button>
        </Box>
      </DialogTitle>
      <DialogContent>
        <List sx={{ listStyle: "disc", fontFamily: "Roboto, sans-serif" }}>
          <ListItem sx={{ display: "list-item", p: 0, color: "#000" }}>
            Please only make deposits to the accounts listed on the next screen.
          </ListItem>
          <ListItem sx={{ display: "list-item", p: 0, color: "#000" }}>
            Deposits made to any other account may not be credited to yours.
          </ListItem>
          <ListItem sx={{ display: "list-item", p: 0, color: "#000" }}>
            Please only make deposits to the accounts listed on the next screen.
          </ListItem>
          <ListItem sx={{ display: "list-item", p: 0, color: "#000" }}>
            Deposits made to any other account may not be credited to yours.
          </ListItem>
          <ListItem sx={{ display: "list-item", p: 0, color: "#000" }}>
            Please only make deposits to the accounts listed on the next screen.
          </ListItem>
          <ListItem sx={{ display: "list-item", p: 0, color: "#000" }}>
            Deposits made to any other account may not be credited to yours.
          </ListItem>
          <ListItem sx={{ display: "list-item", p: 0, color: "#000" }}>
            Please only make deposits to the accounts listed on the next screen.
          </ListItem>
          <ListItem sx={{ display: "list-item", p: 0, color: "#000" }}>
            Deposits made to any other account may not be credited to yours.
          </ListItem>
          <ListItem sx={{ display: "list-item", p: 0, color: "#000" }}>
            Please only make deposits to the accounts listed on the next screen.
          </ListItem>
          <ListItem sx={{ display: "list-item", p: 0, color: "#000" }}>
            Deposits made to any other account may not be credited to yours.
          </ListItem>
        </List>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          sx={{
            backgroundColor: "#f36c21",
            padding: "10px 0",
            flex: 1,
            color: "#fff",
            fontSize: "16px",
            ":hover": {
              backgroundColor: "#f36c21",
            },
          }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default InfoDialog
