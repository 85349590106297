import React, { Dispatch, FC, SetStateAction, useEffect, useState } from "react"
import {
  Box,
  Typography,
  TextField,
  InputAdornment,
  IconButton,
  Button,
  FormControlLabel,
  useMediaQuery,
  CircularProgress,
} from "@mui/material"
import { Add, Remove } from "@mui/icons-material"
import { Link, useNavigate } from "react-router-dom"
import IOSSwitch from "./IosSwitch"
import { IoIosInformationCircle } from "react-icons/io"
import { BetPlace } from "../../components/pages/sportdetails/SportsEventDetail"
import {
  useGetBetPlacedMutation,
  useGetstakebuttonQuery,
} from "../../api/mainApi/mainApiSlice"
import snackBarUtil from "../../utils/snackbar"
import { SnackbarContent } from "notistack"

interface BetModalMobileAndTabletProps {
  handleDesktopModalClose: () => void
  betPlaceData: BetPlace | any
  setBetPlacedData: Dispatch<SetStateAction<BetPlace>>
}

const BetModalMobileAndTablet: FC<BetModalMobileAndTabletProps> = ({
  handleDesktopModalClose,
  betPlaceData,
  setBetPlacedData,
}) => {
  const [value, setValue] = useState(0)
  const [stake, setStake] = useState(0)
  const [checked, setChecked] = useState(false)
  const [getBetPlace, { data: BetPlaced, isError, error, isLoading }] =
    useGetBetPlacedMutation()
  const nav = useNavigate()
  const { data: getstakedata } = useGetstakebuttonQuery({})

  const handleIncrement = () => {
    setValue(prevValue => prevValue + 1)
  }

  const handleDecrement = () => {
    setValue(prevValue => (prevValue > 0 ? prevValue - 1 : 0))
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = Number(event.target.value)
    if (!isNaN(newValue)) {
      setValue(newValue)
    }
  }

  console.log(betPlaceData, "alksjflaksdjf")

  const handleStakeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = Number(event.target.value)
    if (!isNaN(newValue)) {
      setBetPlacedData(prev => ({
        ...prev,
        stake: newValue,
      }))
    }
  }

  const handleStakeSet = (value: number) => {
    setBetPlacedData(prev => ({
      ...prev,
      stake: value,
    }))
  }
  // const handleSwitch = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   setChecked(e.target.checked)
  // }

  const handleBetPlaced = () => {
    getBetPlace(betPlaceData)
  }

  useEffect(() => {
    if (BetPlaced) {
      if (BetPlaced?.status) {
        setBetPlacedData({})
        snackBarUtil.success(
          <SnackbarContent role="alert">
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <Typography
                sx={{ color: "#fff", fontSize: "16px", textAlign: "left" }}
              >
                Success!
              </Typography>
              <Typography
                sx={{ color: "#fff", fontSize: "12px", textAlign: "left" }}
              >
                {BetPlaced?.message}
              </Typography>
            </Box>
          </SnackbarContent>,
        )
      }
    }
  }, [BetPlaced])

  useEffect(() => {
    if (error) {
      setBetPlacedData({})
      snackBarUtil.error(
        <SnackbarContent role="alert">
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Typography
              sx={{ color: "#fff", fontSize: "16px", textAlign: "left" }}
            >
              Error!
            </Typography>
            <Typography
              sx={{ color: "#fff", fontSize: "12px", textAlign: "left" }}
            >
              {"data" in error && (error?.data as { message: string })?.message}
            </Typography>
          </Box>
        </SnackbarContent>,
      )
    }
  }, [error])

  return (
    <Box
      className="mainbetmodal"
      sx={{
        border: betPlaceData?.isBack
          ? "5px solid #a5d9fe"
          : "5px solid #f8d0ce",
        backgroundColor: "#fff",
        borderRadius: "15px",
        padding: { lg: "10px", md: "10px", xs: "5px" },
        margin: "5px",
      }}
    >
      <Box className="open-bet-heads">
        <Typography
          component="h5"
          sx={{
            fontSize: "12px",
            color: "#212529",
            textTransform: "capitalize",
            fontWeight: "600",
          }}
        >
          {betPlaceData?.matchName}
        </Typography>
        <Typography
          component="p"
          sx={{
            fontSize: "13px",
            color: "#212529",
            marginBottom: "14px",
            lineHeight: "15px",
            fontWeight: "600",
          }}
        >
          {betPlaceData?.name}
        </Typography>

        <Box
          className="inputholder"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            gap: "5px",
          }}
        >
          <Box className="textfileleft" sx={{ width: "100%" }}>
            <TextField
              fullWidth
              size="small"
              id="outlined-stake"
              label="Odds"
              placeholder="odds"
              type="number"
              value={betPlaceData?.odds} // Convert to string to prevent initial 0 issue
              onChange={handleChange}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
                sx: {
                  color: "#000 !important",
                },
              }}
              InputProps={{
                inputProps: {
                  min: 0,
                },
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton onClick={handleDecrement} edge="start">
                      <Remove sx={{ color: "#000", fontSize: 14 }} />
                    </IconButton>
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleIncrement} edge="end">
                      <Add sx={{ color: "#000", fontSize: 14 }} />
                    </IconButton>
                  </InputAdornment>
                ),
                sx: {
                  "& ::placeholder": {
                    color: "#000",
                    fontSize: "11px",
                  },
                  "& .MuiOutlinedInput-input": {
                    padding: 0.5,
                    textAlign: "center",
                    color: "#000", // Ensure text color is black
                  },
                },
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "rgba(0, 0, 0, 1)", // Default border color
                  },
                  "&:hover fieldset": {
                    borderColor: "black", // Color on hover
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "blue", // Color when focused
                  },
                },
              }}
            />
          </Box>

          <Box className="textfieldright" sx={{ width: "100%" }}>
            <TextField
              fullWidth
              size="small"
              id="outlined-number"
              label="Stake"
              type="number"
              placeholder="Min.100 Max.1"
              value={betPlaceData?.stake}
              onChange={handleStakeChange}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
                sx: {
                  color: "#f36c21 !important",
                },
              }}
              InputProps={{
                style: {
                  fontSize: "16px",
                },
                inputProps: {
                  min: 0,
                },
                sx: {
                  "& .MuiOutlinedInput-input": {
                    padding: 0.5,
                    textAlign: "center",
                    color: "#000", // Ensure text color is black
                  },
                },
              }}
              sx={{
                "& ::placeholder": {
                  color: "#000",
                  fontSize: "11px",
                },
                textAlign: "center",
                fontSize: "12px",
                color: "#000",
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "#f36c21", // Default border color
                  },
                  "&:hover fieldset": {
                    borderColor: "black", // Color on hover
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "blue", // Color when focused
                  },
                },
              }}
            />
          </Box>
        </Box>

        <Box
          className="linkandtextholder"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            my: 1.5,
          }}
        >
          <Typography
            sx={{ fontSize: { lg: "12px", xs: "0.65rem" }, color: "#706666" }}
          >
            or Choose You Stake size
          </Typography>

          <Button
            onClick={() => nav("/stake-settings")}
            disableRipple
            disableTouchRipple
            className="stakesettingsredirect"
            sx={{
              fontSize: { md: "12px", xs: "10px" },
              textTransform: "uppercase",
              fontWeight: "600",
              color: "#f36c21",
            }}
          >
            edit staked
          </Button>
        </Box>

        <Box
          className="stakenumberholder"
          sx={{
            display: "grid",
            gridTemplateColumns: {
              xs: "auto auto auto auto",
            },
            padding: "3px",
            gap: "3px",
            textAlign: "center",
            width: "100%",
          }}
        >
          {getstakedata &&
            Object.values(getstakedata?.data)?.map((stakeval, idx) => (
              <Button
                onClick={() => handleStakeSet(stakeval)}
                key={idx}
                sx={{
                  borderRadius: "30px",
                  broder: 0,
                  padding: "4px",
                  fontSize: "13px",
                  width: "100%",
                  fontWeight: "600",
                  backgroundColor: "#f36c21",
                  cursor: "pointer",
                  color: "#fff",
                  ":hover": {
                    backgroundColor: "#f36c21",
                  },
                }}
              >
                {stakeval}
              </Button>
            ))}
        </Box>

        <Box
          className="cancelandplacebetbtnholder"
          sx={{
            my: 1.5,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            gap: "10px",
          }}
        >
          <Button
            onClick={handleDesktopModalClose}
            sx={{
              minWidth: "unset",
              margin: "10px 0 0 ",
              fontSize: "11px",
              padding: { lg: "0 37px", md: "0 26px" },
              height: "45px",
              lineHeight: "16px",
              borderRadius: "30px",
              color: "#f36c21",
              textTransform: "uppercase",
              width: "100%",
              border: "1px solid #f36c21",
            }}
          >
            cancel
          </Button>

          <Button
            disabled={Number(betPlaceData?.stake) <= 0}
            onClick={handleBetPlaced}
            sx={{
              minWidth: "unset",
              margin: "10px 0 0",
              fontSize: "11px",
              padding: { lg: "0 37px", md: "0 26px" },
              height: "45px",
              lineHeight: "16px",
              borderRadius: "30px",
              color: Number(betPlaceData?.stake) > 0 ? "#fff" : "#c5c5c5",
              backgroundColor:
                Number(betPlaceData?.stake) > 0 ? "#32a432" : "#e1e1e1",
              textTransform: "uppercase",
              border: stake > 0 ? "1px solid #32a432" : "1px solid #e1e1e1",
              position: "relative",
              width: "100%",
              ":hover": {
                backgroundColor:
                  Number(betPlaceData?.stake) > 0 ? "#32a432" : "#e1e1e1",
              },
            }}
          >
            {isLoading ? (
              <CircularProgress size="20px" color="primary" />
            ) : (
              <div>
                <Typography component="span" sx={{ mx: 0.5, fontSize: "11px" }}>
                  place bet
                </Typography>
                {betPlaceData?.isBack
                  ? betPlaceData.isFancy
                    ? (betPlaceData?.stake * betPlaceData.priceValue) / 100
                    : (betPlaceData?.marketId?.includes("BM") ||
                      betPlaceData?.marketId?.includes("bm") ||
                      betPlaceData?.marketId?.includes("Bm")
                        ? (betPlaceData?.odds * betPlaceData?.stake) / 100
                        : (betPlaceData?.odds - 1) * betPlaceData?.stake
                      ).toFixed(2)
                  : betPlaceData?.stake}
              </div>
            )}
          </Button>
        </Box>

        <Box className="minmaxbettextholder">
          <Typography
            component="p"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <IoIosInformationCircle fontSize={20} color="#f36c21" />
            <Typography
              component="span"
              sx={{ fontSize: "12px", color: "#f36c21" }}
            >
              Min Bet: {betPlaceData?.minBet} Max Bet: {betPlaceData?.maxBet}
            </Typography>
          </Typography>
        </Box>

        {/* <Box
          className="confirmbetbeforeplacing"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography
            sx={{
              color: "#464242",
              fontSize: ".875rem!important",
              fontWeight: "400",
            }}
          >
            Confirm bets before placing
          </Typography>

          <FormControlLabel
            control={<IOSSwitch checked={checked} onChange={handleSwitch} />}
            label=""
          />
        </Box> */}
      </Box>
    </Box>
  )
}

export default BetModalMobileAndTablet
