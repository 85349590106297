import { WithdrawForm } from "./WithdrawForm";
import React, { useEffect, useState } from "react";
import { columns } from "./columns";
import { Box } from "@mui/material";
import { StatusTypography } from "../Deposit";
import { colorHex } from "../../../utils/constants";
import ActivityTable from "../Deposit/ActivityTable";
import { useGetWithdrawClientListMutation } from "../../../api/mainApi/mainApiSlice";
import WithdrawList from "./WithdrawList";

const Withdraw = () => {
  const [getWithdrawList, {data: withdrawList}] = useGetWithdrawClientListMutation()

  useEffect(() => {
    getWithdrawList();

  }, []);
  return (
    <Box
      sx={{
        textAlign: "left",
        py: 2,
      }}
    >
      <Box mx={2}>
        <WithdrawForm getWithdrawList={getWithdrawList} />
      </Box>
      <WithdrawList data={withdrawList?.data}/>

      {/* <ActivityTable
        columns={columns}
        rows={
          withdrawList?.data.map((item: any) => {
            const newItem: any = { ...item };
            newItem.status = <StatusTypography status={item.status} />;
            // newItem.action = item.status === "Pending" && (
            //   <Button
            //     variant="outlined"
            //     onClick={() => setCancelWithdrawl(item.id)}
            //   >
            //     Cancel
            //   </Button>
            // );
            return newItem;
          }) || []
        }
      /> */}
    </Box>
  );
};

export default Withdraw;
