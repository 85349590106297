import { Box, Grid, Typography, useMediaQuery } from "@mui/material"
import LeftMenu from "../../../common/LeftMenu"
import HeadingStrip from "../../../common/HeadingStrip"
import AccordionForMatch from "./AccordionForMatch"

const HorseRacing = () => {
  const isMobile = useMediaQuery("(max-width:'425px)")
  const itemToShow = isMobile ? 12 : 10

  return (
    <Box sx={{ my: 1 }}>
      <Grid container spacing={1}>
        <Grid
          item
          xs={1.8}
          sx={{ display: { xs: "none", sm: "none", md: "block" } }}
        >
          <LeftMenu />
        </Grid>
        <Grid item xs={12} md={itemToShow}>
          <Box sx={{ backgroundColor: "#272727", width: "100%" }}>
            <HeadingStrip title="horse racing" />
            <Box className="matchcardholder" sx={{ p: 1 }}>
              <Typography
                sx={{ color: "#fff", fontSize: "18px", fontWeight: "600" }}
              >
                Upcoming Races
              </Typography>

              <Box sx={{ my: 2 }}>
                <AccordionForMatch />
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}
export default HorseRacing
