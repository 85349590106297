import {
  Box,
  Grid,
  List,
  ListItem,
  Typography,
  useMediaQuery,
} from "@mui/material"
import SportsDetailHeadingBar from "../../../common/SportsDetailHeadingBar"
import SportsMenu from "../../../common/SportsMenu"
import IframeScoreBtnHeader from "./IframeScoreBtnHeader"
import MatchDataDetail from "./MatchDataDetail"
import BottomStrip from "./BottomStrip"
import BetSlipTab from "./BetSlipTab"
import { useEffect, useState } from "react"
import { useLocation, useParams } from "react-router"
import {
  useGetMyIpQuery,
  useSportDetailFancyApiQuery,
} from "../../../api/betFairApi/betFairApiSlice"
import snackBarUtil from "../../../utils/snackbar"
import { SnackbarContent } from "notistack"
import moment from "moment"

interface DiviceInfo {
  userAgent: string
  browser: string
  device: string
  deviceType: string
  os: string
  os_version: string
  browser_version: string
  orientation: string
}

export interface BetPlace {
  isFancy?: boolean
  isBack?: boolean
  odds?: number | string
  stake?: number | string
  name?: string
  marketName?: string
  selectionId?: string | null | number
  priceValue?: number | string
  placeTime?: string
  marketId?: string
  matchId?: string
  userIp?: string
  matchName?: string
  deviceInfo?: DiviceInfo
  maxBet?: string
  minBet?: string
}
export interface HandleModalOpenParams {
  isFancy: boolean
  isBack: boolean
  odds: number | string
  name: string
  marketName: string
  selectionId: string
  priceValue: number | string
  marketId: string
}

const deviceInfo = {
  userAgent:
    "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/126.0.0.0 Safari/537.36",
  browser: "Chrome",
  device: "Macintosh",
  deviceType: "desktop",
  os: "Windows",
  os_version: "windows-10",
  browser_version: "108.0.0.0",
  orientation: "landscape",
}

const SportsEventDetail = () => {
  const token = localStorage.getItem("token")
  const isMobile = useMediaQuery("(max-width:425px)")
  const isTablet = useMediaQuery("(max-width:768px)")
  const isDesktop = useMediaQuery("(min-width:769px)")
  const [scoreiframe, setScoreIframe] = useState(false)
  const { matchid, id } = useParams<{
    matchid: number | any
    id: string
  }>()
  const { state } = useLocation()
  const { data } = useGetMyIpQuery()

  useEffect(() => {
    if (matchid) {
    }
  }, [])

  const mdvalue = isMobile || isTablet ? 12 : 7
  const [Modalopen, setModalOpen] = useState(false)
  const [DesktopModal, setDesktopModal] = useState(false)
  const [betPlaceData, setBetPlacedData] = useState<BetPlace>({
    isFancy: false,
    isBack: true,
    odds: 0,
    stake: 0,
    name: "",
    marketName: "",
    selectionId: "",
    priceValue: 0,
    placeTime: "",
    marketId: "",
    matchId: "",
    userIp: "",
    matchName: "",
    maxBet: "",
    minBet: "",
    deviceInfo,
  })

  const handleModalOpen = () => {
    setModalOpen(true)
  }

  const handleClose = () => {
    setModalOpen(!Modalopen)
  }

  useEffect(() => {
    if (data?.ip)
      setBetPlacedData(prev => ({
        ...prev,
        userIp: data?.ip,
      }))
  }, [data?.ip])

  const date = new Date()

  const handleDesktopModalOpen = (
    isFancy: boolean,
    isBack: boolean,
    odds: string | number,
    name: string,
    marketName: string,
    selectionId: string,
    priceValue: string | number,
    marketId: string,
    matchName: string,
    placeTime: string,
    maxBet: string,
    minBet: string,
  ) => {
    setModalOpen(true)
    setDesktopModal(true)
    setBetPlacedData(prev => ({
      ...prev,
      isFancy,
      isBack,
      odds,
      name,
      marketName,
      selectionId: isFancy ? 0 : selectionId,
      priceValue,
      marketId: marketId || selectionId,
      matchName,
      maxBet,
      minBet,
      placeTime: moment(new Date()).format("YYYY-MM-DD HH:mm:ss.SSS"),
      deviceInfo,
      matchId: matchid,
      userIp: data?.ip,
    }))
  }

  const handleDesktopModalClose = () => {
    setDesktopModal(false)
  }

  const { data: sportDetailOddsData } = useSportDetailFancyApiQuery(matchid, {
    refetchOnMountOrArgChange: true,
    pollingInterval: 1000,
  })

  const handleClickModals = () => {
    if (!token) {
      snackBarUtil.error(
        <SnackbarContent role="alert" style={{ width: "800px" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Typography
              sx={{
                color: "#fff",
                fontSize: "16px",
                textAlign: "left",
                textTransform: "capitalize",
              }}
            >
              Wrong!
            </Typography>
            <Typography
              sx={{ color: "#fff", fontSize: "12px", textAlign: "left" }}
            >
              Please login to place the bets
            </Typography>
          </Box>
        </SnackbarContent>,
      )
      return
    } else {
      handleModalOpen()
    }
  }

  const handleScoreIframe = () => {
    setScoreIframe(!scoreiframe)
  }

  return (
    <Box className="sportsdetailparent">
      <Box className="sportsdetailheader">
        <SportsDetailHeadingBar
          title={
            sportDetailOddsData?.Odds &&
            sportDetailOddsData?.Odds?.[0]?.matchName
          }
        />
      </Box>

      <Box className="sportsdetailmaincomp" sx={{ my: 1 }}>
        <Grid container spacing={0.8}>
          <Grid item md={2} sx={{ display: isDesktop ? "block" : "none" }}>
            <SportsMenu id={id} />
          </Grid>
          <Grid
            item
            xs={12}
            md={mdvalue}
            sx={{
              width: {
                md: `${isDesktop && "100%"}`,
                maxWidth: `${isDesktop && "100%"}`,
              },
            }}
          >
            {scoreiframe && (
              <Box
                className="score-iframe"
                sx={{
                  background: "linear-gradient(180deg,#1f1f1f 35%,#000)",
                  borderRadius: "15px",
                  overflow: "hidden",
                  padding: "20px 15px",
                }}
              >
                <iframe
                  src={`https://score.247idhub.com/index.html/event/${matchid}?theme=crazy-diamond`}
                  allowFullScreen
                  style={{ height: "100%", width: "100%", border: "none" }}
                  title="desktop"
                />
              </Box>
            )}

            <IframeScoreBtnHeader handleScoreIframe={handleScoreIframe} />

            <MatchDataDetail
              token={token}
              handleModalOpen={handleModalOpen}
              handleDesktopModalOpen={handleDesktopModalOpen}
              handleDesktopModalClose={handleDesktopModalClose}
              DesktopModal={DesktopModal}
              sportDetailOddsData={sportDetailOddsData}
              handleClickModals={handleClickModals}
              setBetPlacedData={setBetPlacedData}
              betPlaceData={betPlaceData}
              matchid={matchid}
              state={state}
            />

            <BottomStrip
              token={token}
              setBetPlacedData={setBetPlacedData}
              betPlaceData={betPlaceData}
              matchid={matchid}
              sportDetailOddsData={sportDetailOddsData}
              sportid={id}
              DesktopModal={DesktopModal}
              handleClickModals={handleClickModals}
              handleDesktopModalClose={handleDesktopModalClose}
              handleDesktopModalOpen={handleDesktopModalOpen}
            />
            {/*  */}
          </Grid>
          <Grid item md={3} sx={{ display: isDesktop ? "block" : "none" }}>
            <BetSlipTab
              Modalopen={Modalopen}
              handleClose={handleClose}
              matchid={matchid}
              setBetPlacedData={setBetPlacedData}
              betPlaceData={betPlaceData}
              setModalOpen={setModalOpen}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

export default SportsEventDetail
