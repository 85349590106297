import { Box, Dialog } from "@mui/material"
import { FC } from "react"
import SportsMatchedBetsAccordion from "./SportsMatchedBetsAccordion"
import SportsEventOpenBetsAccordion from "./OpenBetsAccordion"
import { useParams } from "react-router"
import NotUnmatchedBets from "./NotUnmatchedBets"
interface OpenBetsMatchedBetModalProps {
  open: boolean
  handleClose: () => void
}

const OpenBetsMatchedBetModal: FC<OpenBetsMatchedBetModalProps> = ({
  open,
  handleClose,
}) => {
  const { matchid } = useParams()

  return (
    <Box sx={{ backgroundColor: "#272727", p: 0 }}>
      <Dialog
        sx={{
          ".MuiDialog-paper": {
            backgroundColor: "#272727",
            p: 2,
            borderRadius: 2,
          },
        }}
        maxWidth={"md"}
        onClose={handleClose}
        open={open}
        fullWidth
      >
        {/* <SportsMatchedBetsAccordion /> */}
        <SportsEventOpenBetsAccordion matchid={matchid && matchid} />
        {/* <NotUnmatchedBets /> */}
      </Dialog>
    </Box>
  )
}

export default OpenBetsMatchedBetModal
