import {
  Box,
  Button,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material"
import { useState } from "react"
import { FaKey } from "react-icons/fa6"
import { IoCall } from "react-icons/io5"
import { AiFillLock } from "react-icons/ai"
import { FaEye } from "react-icons/fa"
import { FaEyeSlash } from "react-icons/fa"

const ChangeMobileNoForm = () => {
  const mobilecode = ["+91", "+880", "+971", "+977", "+92"]

  const [mobileCode, setMobileCode] = useState(mobilecode[0])
  const [showPass, setShowPass] = useState(false)
  const [inputsVal, setInputsVal] = useState({
    mobilenumber: "",
    enterotp: "",
    oldmobilenumber: "",
    password: "",
  })

  const handleChange = (e: any) => {
    setMobileCode(e.target.value)
  }

  const handleInputChange = (e: any) => {
    const { name, val } = e.target

    setInputsVal(prev => ({
      ...prev,
      [name]: val,
    }))
  }

  const handleShow = () => {
    setShowPass(!showPass)
  }
  return (
    <Box
      sx={{
        border: "2px solid #f36c21",
        background: "#000",
        padding: "15px",
        width: "375px",
        borderRadius: "5px",
        margin: "15px auto",
      }}
    >
      <Typography
        component="h3"
        sx={{
          color: "hsla(0,0%,100%,.7)",
          letterSpacing: ".0071428571em",
          fontSize: "15px",
          textAlign: "center",
          fontWeight: 600,
        }}
      >
        Please Provide New Mobile Number
      </Typography>

      <Box
        className="select-mobile-input-holder"
        sx={{
          my: 2,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: 1,
        }}
      >
        <Box className="select-holder">
          <Select
            size="small"
            value={mobileCode}
            name="mobilecode"
            id="code"
            onChange={handleChange}
          >
            {mobilecode?.map((code, idx) => (
              <MenuItem key={code + idx} value={code}>
                {code}
              </MenuItem>
            ))}
          </Select>
        </Box>

        <Box className="input-holder" sx={{ width: "100%" }}>
          <input
            type="number"
            className="w-full border border-solid border-[#999999] rounded-[4px] py-2 bg-[transparent] focus:outline-none"
            name="mobilenumber"
            value={inputsVal.mobilenumber}
            onChange={handleInputChange}
          />
        </Box>
      </Box>

      <Box
        className="limit-get-otp"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box className="limit">
          <Typography component="span" sx={{ color: "#fff" }}>
            {inputsVal.mobilenumber && inputsVal.mobilenumber.length}/10
          </Typography>
        </Box>

        <Box>
          <Button
            variant="outlined"
            sx={{
              color: "#FFc019",
              border: "1px solid #FFc019",
              fontSize: "1rem",
              padding: "5px 15px",
              background: "#000",
              borderRadius: "5px",
              textTransform: "capitalize",
              ":hover": {
                color: "#fff",
                border: "1px solid #FFc019",
              },
            }}
          >
            get otp
          </Button>
        </Box>
      </Box>

      <Box className="enterotp-oldmobileno-password" sx={{ my: 2 }}>
        <Box
          className="enter-otp"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            gap: 1,
          }}
        >
          <Typography component="span" sx={{ transform: "rotate(-180deg)" }}>
            <FaKey color="#fff" fontSize={"20px"} />
          </Typography>
          <input
            onChange={handleInputChange}
            value={inputsVal.enterotp}
            type="text"
            name="enterotp"
            placeholder="Enter OTP"
            className="bg-transparent my-2 px-3 py-2 w-full border-b-[1px] border-solid border-[#fff] focus-within:border-[#f36c21] placeholder:text-white focus-visible:outline-none"
          />
        </Box>

        {/*  */}

        <Box
          className="enter-otp"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            gap: 1,
          }}
        >
          <Typography component="span">
            <IoCall color="#fff" fontSize={"22px"} />
          </Typography>
          <input
            value={inputsVal.oldmobilenumber}
            onChange={handleInputChange}
            type="text"
            name="oldmobilenumber"
            placeholder="Old Mobile Number"
            className="bg-transparent my-2 px-3 py-2 w-full border-b-[1px] border-solid border-[#fff] focus-within:border-[#f36c21] placeholder:text-white focus-visible:outline-none"
          />
        </Box>

        {/*  */}

        <Box
          className="enter-otp"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            gap: 1,
          }}
        >
          <Typography component="span">
            <AiFillLock color="#fff" fontSize={"22px"} />
          </Typography>
          <Box sx={{ position: "relative", width: "100%" }}>
            <input
              value={inputsVal.password}
              onChange={handleInputChange}
              type={showPass ? "text" : "password"}
              name="password"
              placeholder="Password"
              autoSave="off"
              autoComplete="off"
              className="text-[16px] bg-transparent my-2 px-3 py-2 w-full border-b-[1px] border-solid border-[#fff] focus-within:border-[#f36c21] placeholder:text-white focus-visible:outline-none"
            />
            <Button
              disableRipple
              disableTouchRipple
              sx={{
                position: "absolute",
                right: 0,
                top: "30%",
                background: "transparent",
                ":hover": {
                  background: "transparent",
                },
              }}
              onClick={handleShow}
            >
              {!showPass ? (
                <FaEye fontSize="22px" />
              ) : (
                <FaEyeSlash fontSize="22px" />
              )}
            </Button>
          </Box>
        </Box>

        {/*  */}

        <Box className="next-button-holder" sx={{ mt: 2 }}>
          <Button
            disableRipple
            disableTouchRipple
            sx={{
              backgroundColor: "#353535",
              color: "hsla(0,0%,100%,.7)",
              lineHeight: "35px",
              borderRadius: "30px",
              textTransform: "uppercase",
              fontSize: "14px",
              fontWeight: "600",
              width: "100%",
              border: 0,
              ":hover": {
                backgroundColor: "#353535",
              },
            }}
          >
            Next
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default ChangeMobileNoForm
