import {
  Box,
  Button,
  Grid,
  Grow,
  List,
  ListItem,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material"
// import cashoutimg from "https://s3buket.blr1.cdn.digitaloceanspaces.com/images/cashoutico.webp"
import BetModalMobileAndTablet from "../common/betmodal/BetModalMobileAndTablet"
import type { Dispatch, FC, SetStateAction } from "react"
import { useEffect } from "react"
import SuspandedComp from "../common/SuspandedComp"
// import instantimg from "https://s3buket.blr1.cdn.digitaloceanspaces.com/images/instantimg.webp"
import { BetPlace } from "./pages/sportdetails/SportsEventDetail"
import { posNegPnl } from "../utils/posNegPnl"

const teamnamestyle = {
  whiteSpace: "nowrap",
  fontSize: { md: "0.75rem", xs: "10px" },
  fontFamily: "Roboto,sans-serif ",
  fontWeight: "700",
  color: "#212121",
  opacity: 0.9,
  padding: "0 5px",
}

interface BookmakerCompProps {
  sportDetailOddsData: BetFairSportEventDetailRes | undefined
  handleDesktopModalClose: () => void
  DesktopModal: boolean
  handleClickModals: () => void
  handleDesktopModalOpen: any
  oddspnldata: Record<string, Record<string, number>> | undefined
  betPlaceData: BetPlace
  setBetPlacedData: Dispatch<SetStateAction<BetPlace>>
  token: string | null
}

const BookmakerComp: FC<BookmakerCompProps> = ({
  sportDetailOddsData,
  handleDesktopModalClose,
  DesktopModal,
  handleClickModals,
  handleDesktopModalOpen,
  oddspnldata,
  betPlaceData,
  setBetPlacedData,
  token,
}) => {
  const isDesktop = useMediaQuery("(min-width:769px)")
  const isMobile = useMediaQuery("(max-width:425px)")

  return (
    <>
      <Box className="sportscomp">
        <Box
          sx={{
            overflow: "hidden",
            borderRadius: "15px",
            my: 2,
          }}
        >
          <Grid container>
            <Grid
              item
              xs={5}
              md={4}
              sx={{ backgroundColor: "#f36c21", display: "flex" }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  px: "10px",
                  justifyContent: "flex-start",
                }}
              >
                <Typography
                  component="p"
                  sx={{
                    color: "#f8f9fa",
                    fontSize: { md: "11px", xs: "12px" },
                    ml: 1,
                    fontWeight: "600",
                    fontFamily: "roboto, sans-serif",
                    textTransform: "uppercase",
                  }}
                >
                  Bookmaker
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={7} md={8}>
              <Grid
                container
                className="rightheadstrip"
                sx={{ backgroundColor: "#272727" }}
              >
                <Grid
                  item
                  xs={2}
                  md={2}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    ml: { md: 2, xs: 0 },
                  }}
                >
                  <Button
                    onClick={() => {}}
                    sx={{
                      transition: "all .4s ease 0s",
                      background:
                        "linear-gradient(0deg,#c6a352,#f3e88a 50%,#c6a352 85%)",
                      display: "flex",
                      alignItems: "center",
                      textAlign: "center",
                      textTransform: "uppercase",
                      color: "#000",
                      fontSize: "11px",
                      fontWeight: "500",
                      overflow: "hidden",
                      border: 0,
                      padding: { md: 0, xs: "5px 10px" },
                      width: { md: "85px", xs: "auto" },
                      minWidth: "unset",
                      lineHeight: "25px",
                      minHeight: { md: "25px", xs: "auto" },
                    }}
                  >
                    <Typography component="span">
                      <img
                        src={
                          "https://s3buket.blr1.cdn.digitaloceanspaces.com/images/cashoutico.webp"
                        }
                        alt=""
                        className="w-[10px] h-[10px]"
                      />
                    </Typography>
                    {!isMobile && (
                      <Typography component="span" sx={{ fontSize: "11px" }}>
                        cashout
                      </Typography>
                    )}
                  </Button>
                </Grid>

                <Grid item xs={10} md={9}>
                  <Grid container sx={{ padding: "9px" }}>
                    <Grid item xs={4}>
                      <Typography
                        component="p"
                        sx={{
                          fontSize: { md: "15px", xs: "13px" },
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Tooltip
                          TransitionComponent={Grow}
                          placement="top"
                          slotProps={{
                            tooltip: {
                              sx: {
                                background: "#fff",
                                borderRadius: 1,
                              },
                            },
                          }}
                          sx={{ p: 0, m: 0 }}
                          title={
                            <Box
                              className="tooltip"
                              sx={{
                                width: "100%",
                                backgroundColor: "#fff",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                              }}
                            >
                              <img
                                src={
                                  "https://s3buket.blr1.cdn.digitaloceanspaces.com/images/instantimg.webp"
                                }
                                alt=""
                                className="filter w-[20px] h-[20px]"
                              />
                              <Typography
                                sx={{
                                  fontSize: { md: "14px", xs: "10px" },
                                  ml: 1,
                                  color: "#000",
                                }}
                              >
                                instant betting
                              </Typography>
                            </Box>
                          }
                        >
                          <img
                            src={
                              "https://s3buket.blr1.cdn.digitaloceanspaces.com/images/instantimg.webp"
                            }
                            alt=""
                            className="filter invert w-[20px] h-[20px]"
                          />
                        </Tooltip>
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography
                        sx={{
                          padding: { md: "0 24% 0 0", xs: " 0 12% 0 0" },
                          fontSize: "13px",
                          color: "#fff",
                          textTransform: "capitalize",
                          ml: { md: "auto" },
                          textAlign: { md: "right", xs: "right" },
                        }}
                      >
                        back
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography
                        sx={{
                          color: "#fff",
                          fontSize: "13px",
                          textTransform: "capitalize",
                          textAlign: "left",
                        }}
                      >
                        lay
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            container
            sx={{
              backgroundColor: "#fff",
              borderBottom: "1px solid #e0e0e0",
            }}
          >
            <Grid item xs={12} className="wholegridparent">
              {sportDetailOddsData?.Bookmaker?.map((bookmakerdata, idx) => {
                return (
                  <Grid
                    key={bookmakerdata?.mid + idx}
                    container
                    sx={{
                      backgroundColor: "#fff",
                      borderBottom: "1px solid #e0e0e0",
                      padding: { lg: "3px 0 3px 10px", md: "3px 0 3px 0" },
                    }}
                  >
                    <Grid
                      item
                      xs={6}
                      lg={6}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Box sx={{ mt: "5px" }}>
                        <Typography
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            justifyContent: "center",
                          }}
                          component="span"
                        >
                          <Typography component="span" sx={teamnamestyle}>
                            {bookmakerdata?.nation}
                          </Typography>
                          <Typography component="span" sx={teamnamestyle}>
                            {oddspnldata &&
                              posNegPnl(
                                oddspnldata?.[bookmakerdata?.mid]?.[
                                  bookmakerdata?.sid
                                ],
                              )}
                          </Typography>
                        </Typography>
                      </Box>
                    </Grid>

                    <Grid item xs={6} lg={6} sx={{ position: "relative" }}>
                      {bookmakerdata?.gstatus === "SUSPENDED" &&
                        (bookmakerdata?.b1 == 0 || bookmakerdata?.l1 == 0) && (
                          <SuspandedComp />
                        )}
                      <Box
                        sx={{
                          mt: "5px",
                          width: "100%",
                          display: "flex",
                          justifyContent: "end",
                          height: "42px",
                        }}
                        className="inplaygamelivesec"
                      >
                        <Box className="livebettingbox">
                          <List sx={{ display: "flex", p: 0, pr: "5px" }}>
                            {!isMobile && (
                              <ListItem
                                sx={{
                                  margin: "1px",
                                  p: 0,
                                  overflow: "hidden",
                                  backgroundColor: "#a5d9fe",
                                  borderRadius: { xs: "10px", md: 0 },
                                }}
                              >
                                <button
                                  className={`odd-btnn
                 h-[40px] lg:h-[40px] py-[1px] rounded-[10px] lg:rounded-[0px] px-[6px] text-center text-[11px] lg:w-[59px] text- [#000] inline-block leading-[13px] border-0 overflow-hidden
                   `}
                                >
                                  <Typography
                                    component="span"
                                    sx={{
                                      fontSize: "11px",
                                      color: "#000",
                                      fontFamily: "Roboto,sans-serif",
                                    }}
                                  >
                                    <Typography
                                      component="strong"
                                      sx={{
                                        fontSize: "11px",
                                        fontWeight: "bold",
                                        color: "#000",
                                        fontFamily: "Roboto,sans-serif",
                                      }}
                                    >
                                      -
                                    </Typography>
                                  </Typography>
                                  <br />
                                  <Typography
                                    component="span"
                                    sx={{ fontSize: "11px", color: "#000" }}
                                  >
                                    -
                                  </Typography>
                                </button>
                              </ListItem>
                            )}

                            {!isMobile && (
                              <ListItem
                                sx={{
                                  margin: "1px",
                                  p: 0,
                                  overflow: "hidden",
                                  backgroundColor: "#a5d9fe",
                                  borderRadius: { xs: "10px", md: 0 },
                                }}
                              >
                                <button
                                  className={`
                 h-[35px] lg:h-[40px] py-[1px] px-[6px] text-center text-[11px] w-[50px] lg:w-[59px] text-[#000] inline-block leading-[13px] border-0 overflow-hidden
                   `}
                                >
                                  <Typography
                                    component="span"
                                    sx={{
                                      fontSize: "11px",
                                      color: "#000",
                                      fontFamily: "Roboto,sans-serif",
                                    }}
                                  >
                                    <Typography
                                      component="strong"
                                      sx={{
                                        fontSize: "11px",
                                        fontWeight: "bold",
                                        color: "#000",
                                        fontFamily: "Roboto,sans-serif",
                                      }}
                                    >
                                      -
                                    </Typography>
                                  </Typography>
                                  <br />
                                  <Typography
                                    component="span"
                                    sx={{ fontSize: "11px", color: "#000" }}
                                  >
                                    -
                                  </Typography>
                                </button>
                              </ListItem>
                            )}

                            <ListItem
                              onClick={() => {
                                if (!token) {
                                  handleClickModals()
                                } else {
                                  handleDesktopModalOpen(
                                    false,
                                    true,
                                    bookmakerdata?.b1,
                                    bookmakerdata?.nation,
                                    "Bookmaker",
                                    bookmakerdata?.sid,
                                    bookmakerdata?.b1,
                                    bookmakerdata?.mid,
                                    bookmakerdata?.matchName,
                                    bookmakerdata?.minBet,
                                    bookmakerdata?.maxBet,
                                    sportDetailOddsData?.Bookmaker[0]?.minBet,
                                    sportDetailOddsData?.Bookmaker[0]?.maxBet,
                                  )
                                }
                              }}
                              sx={{
                                margin: "1px",
                                p: 0,
                                overflow: "hidden",
                                backgroundColor: "#a5d9fe",
                                borderRadius: { xs: "10px", md: 0 },
                              }}
                            >
                              <button
                                className={`odd-btnn
                 h-[40px] lg:h-[40px] py-[1px] rounded-[10px] lg:rounded-[0px] px-[6px] text-center text-[11px] lg:w-[59px] text- [#000] inline-block leading-[13px] border-0 overflow-hidden
                   `}
                              >
                                <Typography
                                  component="span"
                                  sx={{
                                    fontSize: "11px",
                                    color: "#000",
                                    fontFamily: "Roboto,sans-serif",
                                  }}
                                >
                                  <Typography
                                    component="strong"
                                    sx={{
                                      fontSize: "11px",
                                      fontWeight: "bold",
                                      color: "#000",
                                      fontFamily: "Roboto,sans-serif",
                                    }}
                                  >
                                    {bookmakerdata?.b1.toFixed(2)}
                                  </Typography>
                                </Typography>
                                <br />
                                <Typography
                                  component="span"
                                  sx={{ fontSize: "11px", color: "#000" }}
                                >
                                  {bookmakerdata?.bs1.toFixed(2)}
                                </Typography>
                              </button>
                            </ListItem>

                            {/* lay------- */}

                            <ListItem
                              onClick={() => {
                                if (!token) {
                                  handleClickModals()
                                } else {
                                  handleDesktopModalOpen(
                                    false,
                                    false,
                                    bookmakerdata?.l1,
                                    bookmakerdata?.nation,
                                    "Bookmaker",
                                    bookmakerdata?.sid,
                                    bookmakerdata?.l1,
                                    bookmakerdata?.mid,
                                    bookmakerdata?.matchName,
                                    bookmakerdata?.minBet,
                                    bookmakerdata?.maxBet,
                                    sportDetailOddsData?.Bookmaker[0]?.minBet,
                                    sportDetailOddsData?.Bookmaker[0]?.maxBet,
                                  )
                                }
                              }}
                              sx={{
                                margin: "1px",
                                p: 0,
                                overflow: "hidden",
                                backgroundColor: "#F8D0CE",
                                borderRadius: { xs: "10px", md: 0 },
                              }}
                            >
                              <button
                                className={`odd-btnn
                 h-[40px] lg:h-[40px] py-[1px] px-[6px] text-center text-[11px]  lg:w-[59px] text-[#000] inline-block leading-[13px] border-0 overflow-hidden
                   `}
                              >
                                <Typography
                                  component="span"
                                  sx={{
                                    fontSize: "11px",
                                    color: "#000",
                                    fontFamily: "Roboto,sans-serif",
                                  }}
                                >
                                  <Typography
                                    component="strong"
                                    sx={{
                                      fontSize: "11px",
                                      fontWeight: "bold",
                                      color: "#000",
                                      fontFamily: "Roboto,sans-serif",
                                    }}
                                  >
                                    {bookmakerdata?.l1.toFixed(2)}
                                  </Typography>
                                </Typography>
                                <br />
                                <Typography
                                  component="span"
                                  sx={{ fontSize: "11px", color: "#000" }}
                                >
                                  {bookmakerdata?.ls1.toFixed(2)}
                                </Typography>
                              </button>
                            </ListItem>

                            {!isMobile && (
                              <ListItem
                                // onClick={handleClickModals}
                                sx={{
                                  margin: "1px",
                                  p: 0,
                                  overflow: "hidden",
                                  backgroundColor: "#F8D0CE",
                                  borderRadius: { xs: "10px", md: 0 },
                                  //   display: `${isMobile && (index === 0 || index === 1) ? "none" : "block"}`,
                                }}
                                // key={index}
                              >
                                <button
                                  className={`odd-btnn
                 h-[40px] lg:h-[40px] py-[1px] rounded-[10px] lg:rounded-[0px] px-[6px] text-center text-[11px] lg:w-[59px] text- [#000] inline-block leading-[13px] border-0 overflow-hidden
                   `}
                                >
                                  <Typography
                                    component="span"
                                    sx={{
                                      fontSize: "11px",
                                      color: "#000",
                                      fontFamily: "Roboto,sans-serif",
                                    }}
                                  >
                                    <Typography
                                      component="strong"
                                      sx={{
                                        fontSize: "11px",
                                        fontWeight: "bold",
                                        color: "#000",
                                        fontFamily: "Roboto,sans-serif",
                                      }}
                                    >
                                      -
                                    </Typography>
                                  </Typography>
                                  <br />
                                  <Typography
                                    component="span"
                                    sx={{ fontSize: "11px", color: "#000" }}
                                  >
                                    -
                                  </Typography>
                                </button>
                              </ListItem>
                            )}

                            {!isMobile && (
                              <ListItem
                                // onClick={handleClickModals}
                                sx={{
                                  margin: "1px",
                                  p: 0,
                                  overflow: "hidden",
                                  backgroundColor: "#F8D0CE",
                                  borderRadius: { xs: "10px", md: 0 },
                                  //   display: `${isMobile && (index === 1 || index === 2) ? "none" : "block"}`,
                                }}
                                // key={index}
                              >
                                <button
                                  className={`odd-btnn
                 h-[40px] lg:h-[40px] py-[1px] px-[6px] text-center text-[11px] lg:w-[59px] text-[#000] inline-block leading-[13px] border-0 overflow-hidden
                   `}
                                >
                                  <Typography
                                    component="span"
                                    sx={{
                                      fontSize: "11px",
                                      color: "#000",
                                      fontFamily: "Roboto,sans-serif",
                                    }}
                                  >
                                    <Typography
                                      component="strong"
                                      sx={{
                                        fontSize: "11px",
                                        fontWeight: "bold",
                                        color: "#000",
                                        fontFamily: "Roboto,sans-serif",
                                      }}
                                    >
                                      -
                                    </Typography>
                                  </Typography>
                                  <br />
                                  <Typography
                                    component="span"
                                    sx={{ fontSize: "11px", color: "#000" }}
                                  >
                                    -
                                  </Typography>
                                </button>
                              </ListItem>
                            )}
                          </List>
                        </Box>
                      </Box>
                    </Grid>
                    {/* modal */}
                    <Grid container>
                      {bookmakerdata?.sid === betPlaceData?.selectionId && (
                        <Grid item xs={12}>
                          {!isDesktop && DesktopModal && (
                            <BetModalMobileAndTablet
                              betPlaceData={betPlaceData}
                              setBetPlacedData={setBetPlacedData}
                              handleDesktopModalClose={handleDesktopModalClose}
                            />
                          )}
                        </Grid>
                      )}
                    </Grid>
                    {/* modal */}
                  </Grid>
                )
              })}
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                gap: "10px",
              }}
            >
              <Typography component="p">
                <Typography
                  component="span"
                  sx={{
                    color: "#212529",
                    fontSize: { md: "13px", xs: "11px" },
                    fontWeight: "600",
                  }}
                >
                  Min:
                </Typography>
                <Typography
                  component="span"
                  sx={{
                    color: "#212529",
                    fontSize: { md: "13px", xs: "11px" },
                    fontWeight: "600",
                  }}
                >
                  {sportDetailOddsData?.Bookmaker?.[0].minBet}
                </Typography>
              </Typography>
              <Typography component="p" sx={{ marginRight: { md: 15, xs: 1 } }}>
                <Typography
                  component="span"
                  sx={{
                    color: "#212529",
                    fontSize: { md: "13px", xs: "11px" },
                    fontWeight: "600",
                  }}
                >
                  Max:
                </Typography>
                <Typography
                  component="span"
                  sx={{
                    color: "#212529",
                    fontSize: { md: "13px", xs: "11px" },
                    fontWeight: "600",
                  }}
                >
                  {sportDetailOddsData?.Bookmaker?.[0].maxBet}
                </Typography>
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  )
}

export default BookmakerComp
