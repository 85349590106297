import { Box } from "@mui/material"
import SecondHeadingBar from "../../../common/SecondHeadingBar"
import NoData from "../../../common/NoData"

const Favoruites = () => {
  return (
    <Box>
      <SecondHeadingBar title="Favorites" />
      <Box>
        <NoData />
      </Box>
    </Box>
  )
}
export default Favoruites
