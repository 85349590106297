import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface CasinoState {
    sport: string;
    casinoData: any;
}

const initialState: CasinoState = {
    sport: '',
    casinoData: [],
};

const stateSlice = createSlice({
    name: 'casinoState',
    initialState,
    reducers: {
        setState: (state, action) => {
            state.sport = action.payload.sport;
            state.casinoData = action.payload.casinoData;
        },
    },
});

export const { setState } = stateSlice.actions;
export default stateSlice.reducer;
