import { Box } from "@mui/material"
import { FC } from "react"

interface HeadingStripProps {
  title: string
}

const HeadingStrip: FC<HeadingStripProps> = ({ title }) => {
  return (
    <Box
      sx={{
        background: "linear-gradient(0deg,hsla(0,0%,4%,.5) 50%,#323232)",
        padding: "10px",
        borderBottom: "1px solid #333",
        borderTop: "1px solid #4c4c4c",
        backgroundColor: "#1e1e1e",
        color: "#fff",
        textTransform: "capitalize",
        fontWeight: "600",
      }}
    >
      {title}
    </Box>
  )
}

export default HeadingStrip
