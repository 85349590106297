import * as React from "react"
import Accordion from "@mui/material/Accordion"
import AccordionSummary from "@mui/material/AccordionSummary"
import AccordionDetails from "@mui/material/AccordionDetails"
import Typography from "@mui/material/Typography"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { Box } from "@mui/material"
import NoData from "../../../../common/NoData"
import TableDataComp from "./TableDataComp"

interface AccAccordionDataProps {
  accountStatementData: accountStatementResponse | undefined
}

const AccAccordionData: React.FC<AccAccordionDataProps> = ({
  accountStatementData,
}) => {
  const [expanded, setExpanded] = React.useState(true)
  return (
    <Accordion
      expanded={expanded}
      defaultExpanded={true}
      onChange={() => setExpanded(!expanded)}
      sx={{
        backgroundColor: "transparent",

        "&.Mui-expanded": {
          margin: 0,
        },
      }}
    >
      <AccordionSummary
        expandIcon={
          <ExpandMoreIcon
            sx={{
              borderRadius: "50%",
              color: "white",
              backgroundColor: "#f36c21",
              padding: "4px",
            }}
          />
        }
        aria-controls="matched-bets-content"
        id="matched-bets-header"
        sx={{
          border: "1px solid #f36c21",
          borderRadius: expanded ? "15px 15px 0 0" : "15px",
          minHeight: "46px !important",

          "&.Mui-expanded": {
            minHeight: "46px !important",
          },
          "& .MuiAccordionSummary-content.Mui-expanded": {
            minHeight: "46px !important",
            margin: "0px",
          },
          "& .MuiAccordionSummary-content": {
            alignItems: "center",
            margin: "0px",
          },
          "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
            transform: "rotate(180deg)",
          },
        }}
      >
        <Typography variant="h6" sx={{ fontSize: "13px", color: "#fff" }}>
          Matched Bets
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          p: 0,
          border: "1px solid #f36c21",
          borderRadius: "0 0 15px 15px",
          overflow: "hidden",
        }}
      >
        {accountStatementData &&
        accountStatementData?.data?.dataList?.length > 0 ? (
          <TableDataComp accountStatementData={accountStatementData} />
        ) : (
          <NoData />
        )}
      </AccordionDetails>
    </Accordion>
  )
}

export default AccAccordionData
