import { Box, Container, Grid, List, ListItem, Typography } from "@mui/material"
import pokerimg from "https://s3buket.blr1.cdn.digitaloceanspaces.com/images/poker1.webp"
import { Link } from "react-router-dom"

const listyle = {
  padding: 0,
  fontSize: "14px",
  color: "#fff",
  display: "list-item",
  listStyleType: "inherit",
}

const list = {
  p: 0,
  listStyleType: "disc",
  paddingInlineStart: "20px",
}

const Poker = () => {
  return (
    <Box className="poker">
      <Container maxWidth="lg" sx={{ py: "10px", m: "17px 59px" }}>
        <Grid container columnSpacing={{ xs: 2 }}>
          <Grid item xs={4}>
            <Typography
              component="h1"
              sx={{
                fontSize: "32px",
                color: "#f36c21",
                fontFamily: "Lato,sans-serif",
                fontWeight: "700",
                paddingBottom: "8px",
                textAlign: "left",
              }}
            >
              Poker
            </Typography>

            <div className="teenpattiimg max-w-[420px] mb-[27px]">
              <img
                src={
                  "https://s3buket.blr1.cdn.digitaloceanspaces.com/images/poker1.webp"
                }
                alt=""
                className="max-w-[100%] h-auto"
              />
            </div>
          </Grid>
          <Grid item xs={8}>
            <Box className="pana">
              <Typography
                component="p"
                sx={{
                  textAlign: "left",
                  color: "#fff",
                  fontSize: "14px",
                  fontFamily: "Lato,sans-serif",
                }}
              >
                A poker game a day, keeps the action still in play. Do you enjoy
                playing casino poker? If yes, then you’re going to love online
                poker too.{" "}
                <Link
                  to={"#"}
                  className="text-[#fc6321] underline hover:text-[#fff]"
                >
                  play poker online,
                </Link>{" "}
                from the comfort of your house and win real cash. Join
                {window.location.hostname.split(".")[0]}! Your money is safe with us and we offer daily
                exchanges too. Sign up on {window.location.hostname.split(".")[0]} today.
              </Typography>
            </Box>
            <Box className="rules">
              <Typography
                component="h2"
                sx={{
                  textAlign: "left",
                  color: "#f36c21",
                  fontSize: "24px",
                  paddingTop: "14px",
                  fontWeight: "500",
                }}
              >
                Rules:
              </Typography>
              <Typography
                component="p"
                sx={{
                  textAlign: "left",
                  color: "#fff",
                  fontSize: "14px",
                  fontFamily: "Lato,sans-serif",
                }}
              >
                At our site you’ll find all the world’s most popular poker
                games, including Texas Hold’em.
              </Typography>
              <Typography
                component="p"
                sx={{
                  textAlign: "left",
                  color: "#fff",
                  fontSize: "14px",
                  fontFamily: "Lato,sans-serif",
                }}
              >
                Our Dealer will deals cards and market will be open for betting
                for specified time. You can choose to either back or lay any
                player.
              </Typography>
            </Box>
            <Box className="howdoyouwin">
              <Typography
                component="h2"
                sx={{
                  textAlign: "left",
                  color: "#f36c21",
                  fontSize: "24px",
                  paddingTop: "14px",
                  fontWeight: "500",
                }}
              >
                How Do You Win?
              </Typography>

              <Typography
                component="p"
                sx={{
                  textAlign: "left",
                  color: "#fff",
                  fontSize: "14px",
                  fontFamily: "Lato,sans-serif",
                }}
              >
                Typically, the winner of each hand of poker is the player that
                holds the highest ranked hand when all cards are shown at the
                end of the hand – known as the ‘showdown’. Not sure whether a
                flush beats a straight? Can’t remember how to make a full house?
                You can find all the information you need to know about hand
                rankings in the table below (click here for more). The strongest
                hands are in the top row, running from left to right, with the
                weakest possible hand being simply a high card.
              </Typography>
              <List sx={list}>
                <ListItem sx={listyle}>Royal Flush</ListItem>
                <ListItem sx={listyle}>Straight Flush</ListItem>
                <ListItem sx={listyle}>Four of a Kind</ListItem>
                <ListItem sx={listyle}>Full House</ListItem>
                <ListItem sx={listyle}>Flush</ListItem>
                <ListItem sx={listyle}>Straight</ListItem>
                <ListItem sx={listyle}>HiThree of a Kind</ListItem>
                <ListItem sx={listyle}>Two Pair</ListItem>
                <ListItem sx={listyle}>One Pair</ListItem>
                <ListItem sx={listyle}>High Card</ListItem>
              </List>
              <Typography
                component="p"
                sx={{
                  textAlign: "left",
                  color: "#fff",
                  fontSize: "14px",
                  fontFamily: "Lato,sans-serif",
                }}
              >
                Now you’ve got the rules, what’s stopping you? bet and win!
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}
export default Poker
