import {
  Box,
  Button,
  Tabs,
  Tab,
  Typography,
  Grid,
  List,
  ListItem,
  useMediaQuery,
  Tooltip,
  Grow,
} from "@mui/material"
import { Dispatch, FC, SetStateAction } from "react"
import SuspandedComp from "../../../common/SuspandedComp"
import BetModalMobileAndTablet from "../../../common/betmodal/BetModalMobileAndTablet"
import { posNegPnl } from "../../../utils/posNegPnl"
import { BetPlace } from "./SportsEventDetail"
import NoData from "../../../common/NoData"

const teamnamestyle = {
  whiteSpace: "nowrap",
  fontSize: { md: "0.75rem", xs: "10px" },
  fontFamily: "Roboto,sans-serif ",
  fontWeight: "700",
  color: "#212121",
  opacity: 0.9,
  padding: "0 5px",
}

interface BottomStripBackLayProps {
  data: any[]
  DesktopModal: boolean
  handleDesktopModalClose: () => void
  handleClickModals: any
  fancypnldata: Record<number, number> | undefined
  betPlaceData: BetPlace
  setBetPlacedData: Dispatch<SetStateAction<BetPlace>>
  handleDesktopModalOpen: any
  token: string | null
}

const BottomStripBackLay: FC<BottomStripBackLayProps> = ({
  data,
  DesktopModal,
  handleDesktopModalClose,
  handleClickModals,
  fancypnldata,
  betPlaceData,
  setBetPlacedData,
  handleDesktopModalOpen,
  token,
}) => {
  const isDesktop = useMediaQuery("(min-width:769px)")
  const isMobile = useMediaQuery("(max-width:425px)")
  // console.log(betPlaceData, "betplace")

  console.log(betPlaceData, "fancyyyy")
  return (
    <Box className="sportscomp">
      {data && data?.length > 0 ? (
        data?.map((ele, idx) => {
          // console.log(ele?.sid, "ele?.sid")
          return (
            <Box
              key={idx}
              sx={{
                overflow: "hidden",
                borderRadius: "15px",
                my: 1,
              }}
            >
              <Grid container>
                <Grid
                  item
                  xs={5}
                  md={4}
                  sx={{ backgroundColor: "#f36c21", display: "flex" }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      px: "10px",
                      justifyContent: "flex-start",
                    }}
                  >
                    <Typography
                      component="p"
                      sx={{
                        color: "#f8f9fa",
                        fontSize: { md: "11px", xs: "12px" },
                        ml: 1,
                        fontWeight: "600",
                        fontFamily: "roboto, sans-serif",
                        textTransform: "uppercase",
                      }}
                    >
                      {ele?.nation}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={7} md={8}>
                  <Grid
                    container
                    className="rightheadstrip"
                    sx={{ backgroundColor: "#272727" }}
                  >
                    <Grid item xs={12} md={12}>
                      <Grid
                        container
                        sx={{ padding: "9px", justifyContent: "flex-end" }}
                      >
                        <Grid item xs={6} md={8} lg={8}></Grid>
                        <Grid item xs={3} md={2} lg={1.5}>
                          <Typography
                            sx={{
                              color: "#fff",
                              fontSize: "13px",
                              textTransform: "capitalize",
                              textAlign: "center",
                            }}
                          >
                            lay
                          </Typography>
                        </Grid>
                        <Grid item xs={3} md={2} lg={1.5}>
                          <Typography
                            sx={{
                              fontSize: "13px",
                              color: "#fff",
                              textTransform: "capitalize",
                              ml: { md: "auto" },
                              textAlign: "center",
                            }}
                          >
                            back
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                container
                sx={{
                  backgroundColor: "#fff",
                  borderBottom: "1px solid #e0e0e0",
                }}
              >
                <Grid item xs={12} className="wholegridparent">
                  <Grid
                    container
                    sx={{
                      backgroundColor: "#fff",
                      borderBottom: "1px solid #e0e0e0",
                      padding: {
                        lg: "3px 0 3px 10px",
                        md: "3px 0 3px 0",
                      },
                    }}
                  >
                    <Grid
                      item
                      xs={5}
                      lg={10}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Box sx={{ mt: "5px" }}>
                        <Typography
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            justifyContent: "center",
                          }}
                          component="span"
                        >
                          <Typography component="span" sx={teamnamestyle}>
                            {ele?.nation}
                          </Typography>
                          <Typography component="span" sx={teamnamestyle}>
                            {fancypnldata &&
                              posNegPnl(fancypnldata?.[ele?.sid])}
                          </Typography>
                        </Typography>
                      </Box>
                    </Grid>

                    <Grid item xs={7} lg={2} sx={{ position: "relative" }}>
                      {(ele?.gstatus === "SUSPENDED" ||
                        (ele?.b1 === 0 && ele?.l1 === 0)) && <SuspandedComp />}

                      <Box
                        sx={{
                          mt: "5px",
                          width: "100%",
                          display: "flex",
                          justifyContent: {
                            md: "flex-end",
                            lg: "space-around",
                            xs: "flex-end",
                          },
                          height: "42px",
                        }}
                        className="inplaygamelivesec"
                      >
                        <Box className="livebettingbox">
                          <List
                            sx={{
                              display: "flex",
                              p: 0,
                              pr: "5px",
                              gap: "0.2rem",
                            }}
                          >
                            {/* lay------- */}

                            <ListItem
                              onClick={() => {
                                if (!token) {
                                  handleClickModals()
                                } else {
                                  handleDesktopModalOpen(
                                    true,
                                    false,
                                    ele?.l1,
                                    ele?.nation,
                                    "Fancy2",
                                    ele?.sid,
                                    ele?.ls1,
                                    ele?.mid,
                                    ele?.nation,
                                    ele?.minBet,
                                    ele?.maxBet,
                                    data?.[0]?.minBet,
                                    data?.[0]?.maxBet,
                                  )
                                }
                              }}
                              sx={{
                                margin: "1px",
                                p: 0,
                                overflow: "hidden",
                                backgroundColor: "#F8D0CE",
                                borderRadius: {
                                  xs: "10px",
                                  md: 0,
                                },
                              }}
                            >
                              <button
                                className={`odd-btnn
h-[40px] lg:h-[40px] py-[1px] px-[6px] text-center text-[11px] lg:w-[59px] text-[#000] inline-block leading-[13px] border-0 overflow-hidden
  `}
                              >
                                <Typography
                                  component="span"
                                  sx={{
                                    fontSize: "11px",
                                    color: "#000",
                                    fontFamily: "Roboto,sans-serif",
                                  }}
                                >
                                  <Typography
                                    component="strong"
                                    sx={{
                                      fontSize: "11px",
                                      fontWeight: "bold",
                                      color: "#000",
                                      fontFamily: "Roboto,sans-serif",
                                    }}
                                  >
                                    {ele?.l1.toFixed(2)}
                                  </Typography>
                                </Typography>
                                <br />
                                <Typography
                                  component="span"
                                  sx={{
                                    fontSize: "11px",
                                    color: "#000",
                                  }}
                                >
                                  {ele?.ls1.toFixed(2)}
                                </Typography>
                              </button>
                            </ListItem>
                            <ListItem
                              onClick={() => {
                                if (!token) {
                                  handleClickModals()
                                } else {
                                  handleDesktopModalOpen(
                                    true,
                                    true,
                                    ele?.b1,
                                    ele?.nation,
                                    "Fancy2",
                                    ele?.sid,
                                    ele?.bs1,
                                    ele?.mid,
                                    ele?.nation,
                                    ele?.minBet,
                                    ele?.maxBet,
                                    data?.[0]?.minBet,
                                    data?.[0]?.maxBet,
                                  )
                                }
                              }}
                              sx={{
                                margin: "1px",
                                p: 0,
                                overflow: "hidden",
                                backgroundColor: "#a5d9fe",
                                borderRadius: {
                                  xs: "10px",
                                  md: 0,
                                },
                              }}
                            >
                              <button
                                className={`odd-btnn
h-[40px] lg:h-[40px] py-[1px] rounded-[10px] lg:rounded-[0px] px-[6px] text-center text-[11px] lg:w-[59px] text- [#000] inline-block leading-[13px] border-0 overflow-hidden
  `}
                              >
                                <Typography
                                  component="span"
                                  sx={{
                                    fontSize: "11px",
                                    color: "#000",
                                    fontFamily: "Roboto,sans-serif",
                                  }}
                                >
                                  <Typography
                                    component="strong"
                                    sx={{
                                      fontSize: "11px",
                                      fontWeight: "bold",
                                      color: "#000",
                                      fontFamily: "Roboto,sans-serif",
                                    }}
                                  >
                                    {ele?.b1.toFixed(2)}
                                  </Typography>
                                </Typography>
                                <br />
                                <Typography
                                  component="span"
                                  sx={{
                                    fontSize: "11px",
                                    color: "#000",
                                  }}
                                >
                                  {ele?.bs1.toFixed(2)}
                                </Typography>
                              </button>
                            </ListItem>
                          </List>
                        </Box>
                      </Box>
                    </Grid>
                    {/* modal */}
                    <Grid container>
                      {ele?.sid === betPlaceData?.marketId && (
                        <Grid item xs={12}>
                          {!isDesktop && DesktopModal && (
                            <BetModalMobileAndTablet
                              setBetPlacedData={setBetPlacedData}
                              betPlaceData={betPlaceData}
                              handleDesktopModalClose={handleDesktopModalClose}
                            />
                          )}
                        </Grid>
                      )}
                    </Grid>
                    {/* modal */}
                  </Grid>
                  {/* map */}
                </Grid>
              </Grid>
            </Box>
          )
        })
      ) : (
        <>
          <NoData />
        </>
      )}
    </Box>
  )
}
export default BottomStripBackLay
