import * as React from "react"
import Accordion from "@mui/material/Accordion"
import AccordionSummary from "@mui/material/AccordionSummary"
import AccordionDetails from "@mui/material/AccordionDetails"
import Typography from "@mui/material/Typography"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { Box, Button, Grid } from "@mui/material"
import { Link } from "react-router-dom"
import horseimg from "https://s3buket.blr1.cdn.digitaloceanspaces.com/images/horseimg.webp"
import AddIcon from "@mui/icons-material/Add"
import RemoveIcon from "@mui/icons-material/Remove"
import HorseBetModal from "./HorseBetModal"

interface MatchedBetsAccordionProps {
  // Add any props you need here
}

const AccordionForMatch: React.FC<MatchedBetsAccordionProps> = () => {
  const [expanded, setExpanded] = React.useState(false)
  const [horseModal, setHorseModal] = React.useState(false)
  const handleToggle = () => {
    setExpanded(!expanded)
  }

  const horseBetModal = () => {
    setHorseModal(true)
  }

  return (
    <Box>
      <HorseBetModal horseModal={horseModal} setHorseModal={setHorseModal} />
      <Accordion
        expanded={expanded}
        defaultExpanded
        onChange={handleToggle}
        sx={{
          backgroundColor: "#fff",
          borderRadius: "10px",
          "&.Mui-expanded": {
            margin: 0,
          },
        }}
      >
        <AccordionSummary
          expandIcon={
            expanded ? (
              <RemoveIcon
                sx={{
                  borderRadius: "50%",
                  color: "#f36c21",
                  border: "1px solid #f36c21",
                  padding: "4px",
                }}
              />
            ) : (
              <AddIcon
                sx={{
                  borderRadius: "50%",
                  color: "#f36c21",
                  border: "1px solid #f36c21",
                  padding: "4px",
                }}
              />
            )
          }
          aria-controls="matched-bets-content"
          id="matched-bets-header"
          sx={{
            borderBottom: `${expanded ? "1px solid #dadada" : ""}`,
            borderBottomLeftRadius: `${expanded ? "4px" : ""}`,
            borderBottomRightRadius: `${expanded ? "4px " : ""}`,
            minHeight: "66px !important",
            "&.Mui-expanded": {
              minHeight: "66px !important",
              borderBottomLeftRadius: `${expanded ? "4px" : ""}`,
              borderBottomRightRadius: `${expanded ? "4px " : ""}`,
            },
            "& .MuiAccordionSummary-content.Mui-expanded": {
              minHeight: "46px !important",
              margin: "0px",
            },
            "& .MuiAccordionSummary-content": {
              alignItems: "center",
              margin: "0px",
            },
            "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
              transform: "rotate(180deg)",
            },
          }}
        >
          <Typography variant="h6" sx={{ fontSize: "13px", color: "#fff" }}>
            <Link to={"/racing-list-details/HORSE_RACE"}>
              <Box
                className="mainholder"
                sx={{ display: "flex", alignItems: "center" }}
              >
                <Box className="imgholder" sx={{ margin: "0 10px" }}>
                  <img
                    src={
                      "https://s3buket.blr1.cdn.digitaloceanspaces.com/images/horseimg.webp"
                    }
                    alt=""
                    className="w-[35px] h-[23px]"
                  />
                </Box>
                <Box className="title-time">
                  <Box
                    className="title"
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <Typography
                      component="h5"
                      sx={{
                        color: "#000!important",
                        fontSize: { md: "16px", xs: "12px" },
                        padding: "0 4px 0 0",
                        float: "none",
                        lineHeight: "22px",
                      }}
                    >
                      Greyville
                    </Typography>
                  </Box>
                  <Box className="time">
                    <Typography
                      component="span"
                      sx={{
                        color: "#746969",
                        fontSize: { md: "14px", xs: "12px" },
                        fontWeight: "400",
                        display: "inherit",
                        lineHeight: "20px",
                        textTransform: "capitalize",
                        marginTop: 0,
                      }}
                    >
                      04:30pm
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Link>
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{ p: 0 }}
          // sx={{
          //   border: "1px solid #f36c21",
          //   borderRadius: "0 0 15px 15px",
          // }}
        >
          <Box className="matched-detailcards">
            <Grid
              container
              sx={{ borderBottom: "1px solid #dadada", p: 1, my: 0 }}
            >
              <Grid item xs={6}>
                <Box
                  className="horse-list-sec-left"
                  sx={{
                    height: "100%",
                    display: "flex",
                    alignItems: "flex-start",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    className="match-head"
                    sx={{
                      color: "#000!important",
                      fontSize: { md: "16px", xs: "15px" },
                      padding: "0 4px 0 0",
                      float: "none",
                      lineHeight: "22px",
                      fontWeight: "600",
                    }}
                    component="h5"
                  >
                    Wade Keeping
                  </Typography>
                  <Typography
                    className="match-head"
                    sx={{
                      color: "#746969",
                      fontSize: { md: "14px", xs: "12" },
                      fontWeight: "400",
                      display: "inherit",
                      lineHeight: "20px",
                      textTransform: "capitalize",
                      marginTop: 0,
                    }}
                    component="h5"
                  >
                    Siphesihle Hlengwa
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box
                  className="oddsbutton"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Button
                    onClick={horseBetModal}
                    sx={{
                      marginRight: "10px",
                      listStyle: "none",
                      background: "#d3ddcc",
                      border: "1px solid #f36c21",
                      borderRadius: "4px",
                      width: "100%",
                      display: "flex",
                      height: "auto",
                      justifyContent: "center",
                      flexDirection: "column",
                      alignItems: "center",
                      textAlign: "center",
                      padding: { md: "5px 20px", xs: "3px 15px" },
                      lineHeight: "10px",
                      fontSize: "16px",
                      ":hover": {
                        background: "#d3ddcc",
                      },
                    }}
                  >
                    <Typography
                      component="span"
                      sx={{
                        color: "#000",
                        fontSize: { md: "16px", xs: "12px" },
                        display: " block",
                        fontWeight: "600",
                      }}
                    >
                      1.56
                    </Typography>
                    <Typography
                      component="span"
                      sx={{
                        color: "#000",
                        fontSize: { md: "14px", xs: "12px" },
                        display: " block",
                      }}
                    >
                      415
                    </Typography>
                  </Button>
                  <Button
                    onClick={horseBetModal}
                    sx={{
                      marginRight: "10px",
                      listStyle: "none",
                      background: "#fff5f7",
                      border: "1px solid #f36c21",
                      borderRadius: "4px",
                      width: "100%",
                      display: "flex",
                      height: "auto",
                      justifyContent: "center",
                      flexDirection: "column",
                      alignItems: "center",
                      textAlign: "center",
                      padding: "5px 20px",
                      lineHeight: "10px",
                      fontSize: "16px",
                      ":hover": {
                        background: "#fff5f7",
                      },
                    }}
                  >
                    <Typography
                      component="span"
                      sx={{
                        color: "#000",
                        fontSize: { md: "16px", xs: "12px" },
                        display: " block",
                        fontWeight: "600",
                      }}
                    >
                      1.56
                    </Typography>
                    <Typography
                      component="span"
                      sx={{
                        color: "#000",
                        fontSize: { md: "14px", xs: "12px" },
                        display: " block",
                      }}
                    >
                      415
                    </Typography>
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  )
}

export default AccordionForMatch
