import { Box, Tab, Tabs } from "@mui/material"
import React, { FC } from "react"
import ProitLossAccordionCard from "./ProfitLossAccordionCard"

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}
function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  )
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  }
}

const tabsdata = ["All", "Match Odds", "Bookmaker", "Fancy"]

interface profitLossTabsProps {
  data: any
}

const ProfitLossTabs: FC<profitLossTabsProps> = ({ data }) => {
  const [tabValue, setTabValue] = React.useState(0)

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue)
  }

  return (
    <Box sx={{ width: "100%" }}>
      <Box className="openbetstabsholder" sx={{ pt: 1 }}>
        <Tabs
          variant="fullWidth"
          TabIndicatorProps={{
            style: {
              backgroundColor: "#f36c21",
              bottom: "9px",
            },
          }}
          value={tabValue}
          onChange={handleTabChange}
          aria-label="basic tabs example"
        >
          {tabsdata?.map((item, idx) => (
            <Tab
              disableRipple
              disableTouchRipple
              key={item + idx}
              label={item}
              sx={{
                color: `${tabValue === idx ? "#f36c21 !important" : "#fff"}`,
                fontSize: { md: "16px", xs: "12px" },
                padding: "0",
                minHeight: "40px",
                backgroundColor: `${tabValue === idx ? "#272727" : ""}`,
                ":hover": {
                  backgroundColor: `${tabValue === idx ? "#272727" : ""}`,
                },
              }}
              {...a11yProps(idx)}
            />
          ))}
        </Tabs>
      </Box>
      {tabsdata?.map((item, idx) => (
        <CustomTabPanel value={tabValue} index={idx} key={item + idx}>
          <ProitLossAccordionCard data={data} />
        </CustomTabPanel>
      ))}
    </Box>
  )
}

export default ProfitLossTabs
