// import promodesk1 from 'https://s3buket.blr1.cdn.digitaloceanspaces.com/images/promotion/promotion-img.webp'
// import promodesk2 from 'https://s3buket.blr1.cdn.digitaloceanspaces.com/images/promotion/promotion-img-2.webp'
// import promodesk3 from 'https://s3buket.blr1.cdn.digitaloceanspaces.com/images/promotion/promotion-img-3.webp'
// import promodesk4 from 'https://s3buket.blr1.cdn.digitaloceanspaces.com/images/promotion/30games.webp'
// import promodesk5 from 'https://s3buket.blr1.cdn.digitaloceanspaces.com/images/promotion/promo-img-1.webp'
// ---------------
// import promomob1 from 'https://s3buket.blr1.cdn.digitaloceanspaces.com/images/promotion/promotion-mobile-img.webp'
// import promomob2 from 'https://s3buket.blr1.cdn.digitaloceanspaces.com/images/promotion/promo-mobile-img-4.webp'
// import promomob3 from 'https://s3buket.blr1.cdn.digitaloceanspaces.com/images/promotion/promo-mobile-img-3.webp'
// import promomob4 from 'https://s3buket.blr1.cdn.digitaloceanspaces.com/images/promotion/refermob.webp'
// import promomob5 from 'https://s3buket.blr1.cdn.digitaloceanspaces.com/images/promotion/live-casino-mobile.webp'


type promotionDataType = {
    title: string,
    imgdesk: string,
    imgmob: string,
    heading: string,
    subcont1?: string,
    subcont2?: string
    subcont3?: string
    subcont4?: string
    subcont5?: string
    subcont6?: string

}[]

export const promotiondata: promotionDataType = [
    {
        title: 'loss-back bonus',
        imgdesk: 'https://s3buket.blr1.cdn.digitaloceanspaces.com/images/promotion/promotion-img.webp',
        imgmob: 'https://s3buket.blr1.cdn.digitaloceanspaces.com/images/promotion/promotion-mobile-img.webp',
        heading: '5% Loss-Back Bonus',
        subcont1: `Win every time even when you lose! Yes, you heard that right. ${ window.location.hostname.split(".")[0] } brings to you a 5% loss-back bonus on every IPL match. You will receive 5% of your total losses back as a bonus.`,
        subcont2: `For example, if your total losses in a match is INR 1000, you will get INR 50 (5% of 1000) as a loss-back bonus. This offer is available for all IPL matches, providing you with a great opportunity to reduce your losses while enjoying the excitement of the game.`
    },
    {
        title: 'first deposit bonus',
        imgdesk: 'https://s3buket.blr1.cdn.digitaloceanspaces.com/images/promotion/promotion-img-2.webp',
        imgmob: 'https://s3buket.blr1.cdn.digitaloceanspaces.com/images/promotion/promo-mobile-img-4.webp',
        heading: '300% First Deposit Bonus',
        subcont1: `Get up to 300% bonus on your first deposit as a ${ window.location.hostname.split(".")[0] } Club member. From Deposit of RS 500-5000, Bonus will be 300% From Deposit of RS 5001- 10000, Bonus will be 200% From Deposit of RS 10001- 1000000, Bonus will be 100%`,
        subcont2: 'NOTE:- A 30x turnover of the bonus amount must be completed to activate the bonus.',
        subcont3: `A New User will not receive the First Deposit Bonus if the same Device (computer, laptop, mobile) is used to make multiple accounts at ${ window.location.hostname.split(".")[0] } Club`,
        subcont4: 'NOTE:- Maximum Bonus you can receive for First Deposit Bonus is 50000'
    },
    {
        title: 'second time deposit bonus',
        imgdesk: 'https://s3buket.blr1.cdn.digitaloceanspaces.com/images/promotion/promotion-img-3.webp',
        imgmob: 'https://s3buket.blr1.cdn.digitaloceanspaces.com/images/promotion/promo-mobile-img-3.webp',

        heading: 'SECOND TIME DEPOSIT BONUS',
        subcont1: `Second time lucky, only on ${ window.location.hostname.split(".")[0] }! Now get a 50% bonus on your second deposit as a { window.location.hostname.split(".")[0] } Club member. Note: Eg: If the user deposits Rs. 1000, he will receive a bonus worth Rs.500. The bonus will be split into (10 or 100) equal parts and can be activated as he completes the turnover. The required turnover for the second deposit bonus is 15x which will also be split accordingly. Eg: 500 (bonus) / 10 (parts) = 50 Turnover: 15x / 10 = 1.5x To redeem the part bonus, The user must place bets worth (1.5 x 100 =) 150`
    },
    {
        title: 'referral bonus',
        imgdesk: 'https://s3buket.blr1.cdn.digitaloceanspaces.com/images/promotion/30games.webp',
        imgmob: 'https://s3buket.blr1.cdn.digitaloceanspaces.com/images/promotion/refermob.webp',

        heading: 'Referral Bonus',
        subcont1: `Share your personal referral code with your friend to start their ${ window.location.hostname.split(".")[0] } Club membership and get a 15% bonus of every single deposit they make thereafter!`,
        subcont2: 'Note: A. The bonus will be authorized once the referred player makes a 3x turnover.',
        subcont3: 'B.To redeem the bonus amount, the user must complete a turnover of 35x on the premium sportsbook.',
        subcont4: `C Bonus will be credited in the form of ${ window.location.hostname.split(".")[0] } points and can be used to place bets or play games across the ${ window.location.hostname.split(".")[0] } platform but cannot be withdrawn.`,
        subcont5: `D. If a Club member is found creating multiple accounts or abusing the referral bonus in any other way, all the accounts in question will be blocked and Team ${ window.location.hostname.split(".")[0] } is not liable to pay the Club member the withstanding amount.`,
        subcont6: 'NOTE:-Maximum Bonus you can receive for Referral Bonus is 50000'
    },
    {
        title: 'crypto deposit bonus',
        imgdesk: 'https://s3buket.blr1.cdn.digitaloceanspaces.com/images/promotion/promo-img-1.webp',
        imgmob: 'https://s3buket.blr1.cdn.digitaloceanspaces.com/images/promotion/live-casino-mobile.webp',
        heading: 'Crypto Deposit Bonus',
        subcont1: '10% of the Crypto deposit is awarded as a bonus. A 3x turnover of the deposit amount must be completed to activate the bonus. Bets placed in Premium sportsbook will be counted as turnover.'
    },
]