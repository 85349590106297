import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  List,
  ListItem,
  Typography,
} from "@mui/material"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import NoData from "../../../../common/NoData"
import { FC, useState } from "react"
import { positiveNegative } from "../../../../utils/positiveNegative"
import ViewBetModal from "./ViewBetModal"

interface profitLossAccordionCardProps {
  data: profitlossRes
}

const ProfitLossAccordionCard: FC<profitLossAccordionCardProps> = ({
  data,
}) => {
  const [expanded, setExpanded] = useState<number | null>(null)
  const [viewBetModal, setViewBetModal] = useState(false)

  const handleExpand = (panel: number) => (event: any, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : null)
  }

  const handleViewBetModal = () => {
    setViewBetModal(!viewBetModal)
  }
 
  return (
    <Box className="profit-loss-accordion-parent">
      {/* <ViewBetModal
        viewBetModal={viewBetModal}
        setViewBetModal={setViewBetModal}
      /> */}

      {data?.data?.market?.map((item, idx) => (
        <Accordion
          key={idx}
          expanded={expanded === idx}
          onChange={handleExpand(idx)}
          sx={{
            mb: 2,
            backgroundColor: "#fff",
            borderRadius: "10px !important",
            "&.Mui-expanded": {
              mb: 2,
              borderRadius: "10px",
            },
          }}
        >
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon
                sx={{
                  fontSize: "30px",
                  color: "#000",
                  padding: "1px",
                }}
              />
            }
            aria-controls={`matched-bets-content-${idx}`}
            id={`matched-bets-header-${idx}`}
            sx={{
              backgroundColor: expanded !== idx ? "#fff" : "#E7F1FF",
              display: "flex",
              flexDirection: "row",
              borderRadius: expanded !== idx ? "10px" : "10px 10px 0 0",
              minHeight: "64px !important",
              "&.Mui-expanded": {
                minHeight: "64px !important",
              },
              "& .MuiAccordionSummary-content.Mui-expanded": {
                minHeight: "46px !important",
                margin: "0px",
              },
              "& .MuiAccordionSummary-content": {
                alignItems: "center",
                margin: "0px",
              },
              "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
                transform: "rotate(180deg)",
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Typography
                variant="h6"
                component="h6"
                sx={{ fontSize: "13px", color: "#000", width: "100%" }}
              >
                {idx + 1}. Matched Bets
              </Typography>
              <Typography
                component="p"
                sx={{
                  fontSize: "13px",
                  color: "#000",
                  width: "100%",
                  display: "flex",
                }}
              >
                Settled:
                <Typography
                  component="span"
                  sx={{ fontSize: "13px", color: "#000", mx: 0.5 }}
                >
                  {item?.createdon}
                </Typography>
              </Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              borderRadius: "0 0 15px 15px",
            }}
          >
            <Box className="main-data-holder">
              <Box
                className="team-list"
                sx={{ borderBottom: "1px solid #70707038" }}
              >
                <List sx={{ display: "flex", alignItems: "center", p: 0 }}>
                  <ListItem
                    sx={{
                      pl: 0,
                      py: 0,
                      listStyle: "none",
                      borderRight: "2px solid #ccc",
                      marginRight: "10px",
                      paddingRight: "10px",
                      width: "130px",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Typography
                      component="p"
                      sx={{
                        color: "#746969",
                        textTransform: "capitalize",
                        fontSize: "15px",
                        lineHeight: "18px",
                        fontWeight: "400",
                        width: "100%",
                        whiteSpace: "nowrap",
                      }}
                    >
                      win & loss
                    </Typography>
                    <Typography
                      component="p"
                      sx={{
                        color: "#746969",
                        width: "100%",
                        textTransform: "capitalize",
                      }}
                    >
                      {positiveNegative(Number(item?.pnl))}
                    </Typography>
                  </ListItem>
                  <ListItem
                    sx={{
                      pl: 0,
                      py: 0,
                      listStyle: "none",
                      borderRight: "2px solid #ccc",
                      marginRight: "10px",
                      paddingRight: "10px",
                      width: "130px",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Typography
                      component="p"
                      sx={{
                        color: "#746969",
                        textTransform: "capitalize",
                        fontSize: "15px",
                        lineHeight: "18px",
                        fontWeight: "400",
                        width: "100%",
                        whiteSpace: "nowrap",
                      }}
                    >
                      CommssionDiya
                    </Typography>
                    <Typography
                      component="p"
                      sx={{
                        color: "#746969",
                        width: "100%",
                        textTransform: "capitalize",
                      }}
                    >
                      {positiveNegative(Number(item?.commssionDiya))}
                    </Typography>
                  </ListItem>
                  <ListItem
                    sx={{
                      pl: 0,
                      py: 0,
                      listStyle: "none",
                      borderRight: "2px solid #ccc",
                      marginRight: "10px",
                      paddingRight: "10px",
                      width: "130px",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Typography
                      component="p"
                      sx={{
                        color: "#746969",
                        textTransform: "capitalize",
                        fontSize: "15px",
                        lineHeight: "18px",
                        fontWeight: "400",
                        width: "100%",
                        whiteSpace: "nowrap",
                      }}
                    >
                      CommssionMila
                    </Typography>
                    <Typography
                      component="p"
                      sx={{
                        color: "#746969",
                        width: "100%",
                        textTransform: "capitalize",
                      }}
                    >
                      {positiveNegative(Number(item?.commssionMila))}
                    </Typography>
                  </ListItem>
                  <ListItem
                    sx={{
                      pl: 0,
                      py: 0,
                      listStyle: "none",
                      marginRight: "10px",
                      paddingRight: "10px",
                      width: "130px",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Typography
                      component="p"
                      sx={{
                        color: "#746969",
                        textTransform: "capitalize",
                        fontSize: "15px",
                        lineHeight: "18px",
                        fontWeight: "400",
                        width: "100%",
                        whiteSpace: "nowrap",
                      }}
                    >
                      Total
                    </Typography>
                    <Typography
                      component="p"
                      sx={{
                        color: "#746969",
                        width: "100%",
                        textTransform: "capitalize",
                      }}
                    >
                      {positiveNegative(Number(item?.pnl))}
                    </Typography>
                  </ListItem>
                </List>
              </Box>
              <Box
                className="result"
                sx={{
                  my: 2,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <ListItem
                  sx={{
                    pl: 0,
                    py: 0,
                    listStyle: "none",
                    marginRight: "10px",
                    paddingRight: "10px",
                  }}
                >
                  <Typography
                    component="span"
                    sx={{
                      color: "#746969",
                      textTransform: "capitalize",
                      fontSize: "14px",
                      lineHeight: "18px",
                      fontFamily: "Roboto, sans-serif",
                    }}
                  >
                    Result:
                  </Typography>
                  <Typography
                    component="span"
                    sx={{
                      ml: 0.5,
                      fontFamily: "Roboto, sans-serif",
                      fontSize: "14px",
                      color: "#746969",
                      fontWeight: "600",
                      textTransform: "capitalize",
                    }}
                  >
                    {item?.matchName}
                  </Typography>
                </ListItem>
                <Box sx={{ display: "flex" }} className="view-bets-btn-holder">
                  <Button
                    onClick={handleViewBetModal}
                    variant="contained"
                    size="medium"
                    sx={{
                      cursor: "default",
                      color: "#fff",
                      minWidth: "unset",
                      fontSize: "14px",
                      whiteSpace: "nowrap",
                      backgroundColor: "#f36c21",
                      borderRadius: "4px",
                      padding: "5px 10px",
                      fontFamily: "Roboto, sans-serif",
                      ":hover": {
                        backgroundColor: "#f36c21",
                        color: "#000",
                      },
                    }}
                  >
                    View Bets
                  </Button>
                </Box>
              </Box>
            </Box>
            {!data?.data?.market && <NoData />}
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  )
}

export default ProfitLossAccordionCard
