import { Box, Typography } from "@mui/material"
import { positiveNegative } from "../../../../utils/positiveNegative"
import { useNavigate } from "react-router"
import NoData from "../../../../common/NoData"
import { FaChevronRight } from "react-icons/fa6"
import { FC } from "react"

interface ProfitLossMatchWiseCardProps {
  profitlossmatchwiseData: profitlossRes | undefined
}

const ProfitLossMatchWiseCard: FC<ProfitLossMatchWiseCardProps> = ({
  profitlossmatchwiseData,
}) => {
  const nav = useNavigate()

  const handleProfitLossDetail = (matchName: string) => {
    nav("/profit-loss-details", {
      state: { profitlossmatchwiseData, matchname: matchName },
    })
  }

  return (
    <Box sx={{ px: 2 }} className="mapcomp">
      {profitlossmatchwiseData &&
        profitlossmatchwiseData?.data?.market?.map((profitdata, idx) => (
          <Box
            key={idx}
            sx={{
              margin: "10px 0",
              padding: "10px 15px",
              backgroundColor: "transparent",
              borderRadius: "5px",
              border: "1px solid #ddadad",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box className="statement-match">
              <Typography
                sx={{ fontSize: "14px", color: "#fff" }}
                className="match-head"
                component="h5"
              >
                {profitdata?.matchName}
              </Typography>

              <Box
                className="timedetail"
                sx={{ display: "flex", alignItems: "center" }}
              >
                <Typography
                  component="span"
                  sx={{ color: "#aaa", fontSize: "14px" }}
                >
                  {profitdata?.createdon}
                </Typography>
                <Typography
                  component="span"
                  sx={{ color: "#aaa", mx: 0.5, fontSize: "14px" }}
                >
                  Win & Loss
                </Typography>
                <Typography
                  sx={{
                    fontSize: "14px",
                  }}
                  className="match-head"
                  component="p"
                >
                  <Typography component="span" sx={{ fontSize: "14px" }}>
                    {positiveNegative(Number(profitdata?.pnl))}
                  </Typography>
                </Typography>
              </Box>
            </Box>

            <Box
              onClick={() => handleProfitLossDetail(profitdata?.matchName)}
              className="profit-loss-view"
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                border: "1px solid #f36c21",
                width: "28px",
                height: "28px",
                borderRadius: "4px",
              }}
            >
              <FaChevronRight color="#fff" />
            </Box>
          </Box>
        ))}
      {!profitlossmatchwiseData?.data && <NoData />}
    </Box>
  )
}

export default ProfitLossMatchWiseCard
