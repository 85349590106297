import { Box } from "@mui/material"
import SecondHeadingBar from "../../../../common/SecondHeadingBar"
import SettledBetsSearchBar from "./SettledBetsSearchBar"

const SettledBets = () => {
  return (
    <Box className="profitloss">
      <SecondHeadingBar title="Settled Bets" />
      <Box>
        <SettledBetsSearchBar />
      </Box>
    </Box>
  )
}
export default SettledBets
