import { Box, Container, Grid, List, ListItem, Typography } from "@mui/material"
// import thirtytwocard from "https://s3buket.blr1.cdn.digitaloceanspaces.com/images/32-card-casino.webp"
import { Link } from "react-router-dom"

const listyle = {
  padding: 0,
  fontSize: "14px",
  color: "#fff",
  display: "list-item",
  listStyleType: "inherit",
}

const list = {
  p: 0,
  listStyleType: "disc",
  paddingInlineStart: "20px",
}

const ThirtytwoCards = () => {
  return (
    <Box className="thirtytwocasino">
      <Container maxWidth="lg" sx={{ py: "10px", m: "17px 59px" }}>
        <Grid container columnSpacing={{ xs: 2 }}>
          <Grid item xs={4}>
            <Typography
              component="h1"
              sx={{
                fontSize: "32px",
                color: "#f36c21",
                fontFamily: "Lato,sans-serif",
                fontWeight: "700",
                paddingBottom: "8px",
                textAlign: "left",
              }}
            >
              32 Card Casino
            </Typography>

            <div className="teenpattiimg max-w-[420px] mb-[27px]">
              <img
                src={
                  "https://s3buket.blr1.cdn.digitaloceanspaces.com/images/32-card-casino.webp"
                }
                alt=""
                className="max-w-[100%] h-auto"
              />
            </div>
          </Grid>
          <Grid item xs={8}>
            <Box className="objective">
              {/* <Typography
                component="p"
                sx={{
                  textAlign: "left",
                  color: "#fff",
                  fontSize: "14px",
                  fontFamily: "Lato,sans-serif",
                }}
              >
                { window.location.hostname.split(".")[0] } offers a wide range of{" "}
                <Link
                  to={"#"}
                  className="text-[#fc6321] underline hover:text-[#fff]"
                >
                  Indian Card Games.
                </Link>{" "}
                If you’re looking to play an eight card decks game, a live Amar
                Akbar Anthony game would be the right pick for you. Play and win
                big, only with { window.location.hostname.split(".")[0] }. Concerned about safety? Don’t
                worry. Your money is safe with { window.location.hostname.split(".")[0] }. We offer daily
                exchanges too. Sign up on { window.location.hostname.split(".")[0] }, the best online
                casino, and win big, win daily.
              </Typography> */}
              <Typography
                component="h2"
                sx={{
                  textAlign: "left",
                  color: "#f36c21",
                  fontSize: "24px",
                  paddingTop: "14px",
                  fontWeight: "500",
                }}
              >
                Objective
              </Typography>
              <List sx={{ p: 0 }}>
                <ListItem sx={listyle}>
                  The objective of 32 Card is to predict the winning player of
                  the game round. 32 Card casino is played with a deck of 32
                  card which does not include A,2,3,4,5 ranked cards. To win,
                  you need to predict if sum of the base value of player and
                  cards dealt to player will be higher, lower of all of them.
                  You can either back or lay any player. On Draw between one or
                  more players, market will be reopned for next cards.
                </ListItem>
              </List>
            </Box>
            <Box className="bettingflow">
              <Typography
                component="h2"
                sx={{
                  textAlign: "left",
                  color: "#f36c21",
                  fontSize: "24px",
                  paddingTop: "14px",
                  fontWeight: "500",
                }}
              >
                Betting flow:
              </Typography>
              <List sx={list}>
                <ListItem sx={listyle}>
                  If a game round is in progress when you enter the table,
                  please wait for the next one, and then, place your bets.
                </ListItem>
                <ListItem sx={listyle}>
                  To place a bet, choose a chip and place it on the betting
                  position.
                </ListItem>
                <ListItem sx={listyle}>
                  You can place several chips on different betting positions
                  simultaneously.
                </ListItem>
                <ListItem sx={listyle}>
                  The timer in the game window shows how much time you have left
                  to place your bets.
                </ListItem>
                <ListItem sx={listyle}>
                  After the No More Bets signal, the game round begins.
                </ListItem>
                <ListItem sx={listyle}>
                  Winnings are paid for the winning bets at the end of each game
                  round.
                </ListItem>
                <ListItem sx={listyle}>
                  To play a game round, place your bets again or use the Rebet
                  button.
                </ListItem>
                <ListItem sx={listyle}>
                  To skip a turn, simply do not place any bets on the table. Two
                  Pair
                </ListItem>
              </List>
            </Box>
            <Box className="gameflow">
              <Typography
                component="h2"
                sx={{
                  textAlign: "left",
                  color: "#f36c21",
                  fontSize: "24px",
                  paddingTop: "14px",
                  fontWeight: "500",
                }}
              >
                Game flow:
              </Typography>
              <List sx={list}>
                <ListItem sx={listyle}>
                  When the first game round starts, No cards are dealt but
                  market is opened for initial Betting.
                </ListItem>
                <ListItem sx={listyle}>
                  After the market is suspended, each player gets one card per
                  head.
                </ListItem>
                <ListItem sx={listyle}>
                  If you lost the round, the bet amount is taken off the table.
                  If you won the round, the winnings are added to your Balance.
                </ListItem>
                <ListItem sx={listyle}>
                  If you lost the round, the bet amount is taken off the table.
                  If you won the round, the winnings are added to your Balance.
                </ListItem>
                <ListItem sx={listyle}>
                  If the there is Tie between any two players, Market is
                  reopened for betting to declare the winner. This will continue
                  till the one winner is declared.
                </ListItem>
              </List>

              <Typography
                component="h2"
                sx={{
                  textAlign: "left",
                  color: "#f36c21",
                  fontSize: "24px",
                  paddingTop: "14px",
                  fontWeight: "500",
                }}
              >
                Limit ranges:
              </Typography>

              <Typography
                component="p"
                sx={{
                  textAlign: "left",
                  color: "#fff",
                  fontSize: "14px",
                  fontFamily: "Lato,sans-serif",
                }}
              >
                If it is possible to bet an amount that could potentially result
                in a win larger than the maximum payout limit, then the bet is
                automatically adjusted to the maximum possible amount for this
                position.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

export default ThirtytwoCards
