import "./App.css"
import Pages from "./components/pages/Pages"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
const App = () => {
  return (
    <div className="App">
      <Pages />
    </div>
  )
}

export default App
