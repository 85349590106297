import * as React from "react"
import Tabs from "@mui/material/Tabs"
import Tab from "@mui/material/Tab"
import Box from "@mui/material/Box"
import BottomStripBackLay from "./BottomStripBackLay"
import { BetPlace } from "./SportsEventDetail"

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
    </div>
  )
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  }
}

type sportsDataType = {
  key: string
  value: any[]
}[]

interface FancyTabProps {
  sportsData: sportsDataType
  DesktopModal: boolean
  handleDesktopModalClose: () => void
  handleClickModals: any
  fancypnldata: Record<number, number> | undefined
  betPlaceData: BetPlace
  setBetPlacedData: React.Dispatch<React.SetStateAction<BetPlace>>
  handleDesktopModalOpen: any
  token: string | null
}

const FancyTab: React.FC<FancyTabProps> = ({
  sportsData,
  DesktopModal,
  handleDesktopModalClose,
  handleClickModals,
  fancypnldata,
  betPlaceData,
  setBetPlacedData,
  handleDesktopModalOpen,
  token,
}) => {
  const [value, setValue] = React.useState(3)
  React.useEffect(() => {
    if (value >= sportsData.length) {
      setValue(0)
    }
  }, [value, sportsData.length])

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  const handleActive = (index: number) => {
    setValue(index)
  }

  return (
    <Box sx={{ width: "100%" }}>
      <Tabs
        sx={{
          overflowX: "auto",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          gap: 1,
        }}
        value={value}
        onChange={handleChange}
        aria-label="sports data tabs"
        variant="scrollable"
        scrollButtons="auto"
        TabIndicatorProps={{ style: { display: "none" } }}
      >
        {sportsData?.map((data, idx) => {
          if (
            data.key == "Odds" ||
            data.key == "Bookmaker" ||
            data.key == "Khado" ||
            data.key == "Meter" ||
            data.key == "Fancy" ||
            data.key == "Fancy3"
          ) {
            return null
          }
          return (
            <Tab
              key={idx}
              label={data?.key}
              {...a11yProps(idx)}
              onClick={() => handleActive(idx)}
              sx={{
                height: "32px",
                minHeight: "unset",
                "&.MuiButtonBase-root": {
                  p: 0,
                  mx: 0.5,
                },
                border: "1px solid #6fad47!important",
                boxShadow: "0 0 5px 0 #6fad47",
                color: value == idx ? "#f36c21" : "#fff",
                background: value == idx ? "#353535" : "#272727",
                padding: "3px 20px !important",
                borderRadius: "0.375rem",
                cursor: "pointer",
                fontSize: "14px",
                textTransform: "capitalize",
                ":hover": {
                  backgroundColor: "#353535",
                },
              }}
            />
          )
        })}
      </Tabs>

      {sportsData &&
        sportsData?.map((paneldata, idx) => {
          if (
            paneldata.key == "Odds" ||
            paneldata.key == "Bookmaker" ||
            paneldata.key == "Khado" ||
            paneldata.key == "Meter" ||
            paneldata.key == "Fancy" ||
            paneldata.key == "Fancy3"
          ) {
            return null
          }
          return (
            <CustomTabPanel value={value} index={idx} key={idx}>
              <BottomStripBackLay
                token={token}
                handleDesktopModalOpen={handleDesktopModalOpen}
                setBetPlacedData={setBetPlacedData}
                betPlaceData={betPlaceData}
                fancypnldata={fancypnldata}
                handleClickModals={handleClickModals}
                DesktopModal={DesktopModal}
                handleDesktopModalClose={handleDesktopModalClose}
                data={paneldata?.value}
              />
            </CustomTabPanel>
          )
        })}
    </Box>
  )
}
export default FancyTab
