import * as React from "react"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import NoData from "../../../../common/NoData"

function createData(
  name: string,
  calories: number,
  fat: number,
  carbs: number,
  protein: number,
) {
  return { name, calories, fat, carbs, protein }
}

const styleth = {
  textAlign: "left",
  color: "#fff",
  textTransform: "capitalize",
}

const styletd = {
  textAlign: "left",
  color: "#fff",
  textTransform: "capitalize",
}

interface SettledBetsTableProps {
  betlisthistoryData: betlisthistoryResponse | undefined
}

const SettledBetsTable: React.FC<SettledBetsTableProps> = ({
  betlisthistoryData,
}) => {
  return (
    <TableContainer
      component={Paper}
      sx={{ backgroundColor: "#1e1e1e", my: 2 }}
    >
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell sx={styleth}>sports</TableCell>
            <TableCell sx={styleth}>event name</TableCell>
            <TableCell sx={styleth}>market name</TableCell>
            <TableCell sx={styleth}>nation</TableCell>
            <TableCell sx={styleth}>user rate</TableCell>
            <TableCell sx={styleth}>Price Value</TableCell>
            <TableCell sx={styleth}>Pnl</TableCell>
            <TableCell sx={styleth}>Amount</TableCell>
            <TableCell sx={styleth}>Place Date</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {betlisthistoryData?.data?.dataList?.map((row, idx) => (
            <TableRow
              key={idx}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell sx={styletd}>{row?.sportName}</TableCell>
              <TableCell sx={styletd}>{row?.eventName}</TableCell>
              <TableCell sx={styletd}>{row?.marketname}</TableCell>
              <TableCell sx={styletd}>{row?.nation}</TableCell>
              <TableCell sx={styletd}>{row?.rate}</TableCell>
              <TableCell sx={styletd}>{row?.price}</TableCell>
              <TableCell sx={styletd}>{row?.pnl}</TableCell>
              <TableCell sx={styletd}>{row?.amount}</TableCell>
              <TableCell sx={styletd}>{row?.time}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default SettledBetsTable
