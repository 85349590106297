import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Container,
  Grid,
  List,
  ListItem,
  Typography,
  useMediaQuery,
} from "@mui/material"
import AddIcon from "@mui/icons-material/Add"
import RemoveIcon from "@mui/icons-material/Remove"
import { useState } from "react"
import { promotiondata } from "./promotiondata"
import HeadingBar from "../../../common/HeadingBar"

const Promotion = () => {
  const [expanded, setExpanded] = useState<string | false>(false)
  const isMobile = useMediaQuery("(max-width:900px)")
  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false)
    }

  return (
    <Box className="tutorialsparent">
      <Box className="tutorials">
        <HeadingBar title="Promotions" />
      </Box>
      <Box className="tutorial-accordion" sx={{ bgcolor: "#000", p: "15px" }}>
        <Container maxWidth="lg" sx={{ padding: "0 !important" }}>
          <Box className="accordionholder">
            {promotiondata?.map((tutorial, idx) => (
              <Accordion
                key={tutorial.title + idx}
                sx={{
                  backgroundColor: "#1e1e1e",
                  borderRadius: "5px",
                  border: "1px solid #2f2f2f !important",
                  my: 2,
                  color: "#fff",
                  fontWeight: "500",
                  "&::before": {
                    display: "none",
                  },
                }}
                expanded={expanded === `panel${idx}`}
                onChange={handleChange(`panel${idx}`)}
              >
                <AccordionSummary
                  expandIcon={
                    expanded !== `panel${idx}` ? <AddIcon /> : <RemoveIcon />
                  }
                  aria-controls={`panel${idx}bh-content`}
                  id={`panel${idx}bh-header`}
                >
                  <Typography
                    component="p"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      color: "#fff",
                      textTransform: "capitalize",
                      fontSize: "1rem",
                      fontWeight: "700",
                    }}
                  >
                    {tutorial?.title}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ p: 2 }}>
                  <Grid container columnSpacing={2}>
                    <Grid item xs={12}>
                      <img
                        loading="lazy"
                        className="w-auto max-w-full h-auto m-auto"
                        src={!isMobile ? tutorial?.imgdesk : tutorial?.imgmob}
                        alt=""
                      />

                      <Typography
                        component="h2"
                        sx={{
                          fontSize: "32px",
                          color: "#fff",
                          fontWeight: "700",
                          lineHeight: "1.2",
                          my: 2,
                        }}
                      >
                        {tutorial?.heading}
                      </Typography>
                      {tutorial?.subcont1 && (
                        <Typography
                          component="p"
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            color: "#fff",
                            textTransform: "capitalize",
                            fontSize: "1rem",
                            fontWeight: "500",
                            my: 1,
                          }}
                        >
                          {tutorial?.subcont1}
                        </Typography>
                      )}
                      {tutorial?.subcont2 && (
                        <Typography
                          component="p"
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            color: "#fff",
                            textTransform: "capitalize",
                            fontSize: "1rem",
                            fontWeight: "500",
                            my: 1,
                          }}
                        >
                          {tutorial?.subcont2}
                        </Typography>
                      )}
                      {tutorial?.subcont3 && (
                        <Typography
                          component="p"
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            color: "#fff",
                            textTransform: "capitalize",
                            fontSize: "1rem",
                            fontWeight: "500",
                            my: 1,
                          }}
                        >
                          {tutorial?.subcont3}
                        </Typography>
                      )}
                      {tutorial?.subcont4 && (
                        <Typography
                          component="p"
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            color: "#fff",
                            textTransform: "capitalize",
                            fontSize: "1rem",
                            fontWeight: "500",
                            my: 1,
                          }}
                        >
                          {tutorial?.subcont4}
                        </Typography>
                      )}
                      {tutorial?.subcont5 && (
                        <Typography
                          component="p"
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            color: "#fff",
                            textTransform: "capitalize",
                            fontSize: "1rem",
                            fontWeight: "500",
                            my: 1,
                          }}
                        >
                          {tutorial?.subcont5}
                        </Typography>
                      )}
                      {tutorial?.subcont6 && (
                        <Typography
                          component="p"
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            color: "#fff",
                            textTransform: "capitalize",
                            fontSize: "1rem",
                            fontWeight: "500",
                            my: 1,
                          }}
                        >
                          {tutorial?.subcont6}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            ))}
          </Box>

          <Box className="rule-regulation" sx={{ margin: "36px 0 58px 0" }}>
            <Typography
              component="h2"
              sx={{
                fontSize: "24px",
                color: "#fff",
                textAlign: "center",
                lineHeight: "2.5rem",
                letterSpacing: ".0073529412em",
              }}
            >
              Rules And Regulations
            </Typography>

            <Container maxWidth="md" sx={{ padding: { xs: 0 } }}>
              <Box
                sx={{
                  border: "2px solid #f4da7b",
                  borderRadius: "24px",
                  padding: "27px",
                  backgroundColor: "#1e1e1e",
                  color: "#fff",
                }}
              >
                <List sx={{ listStyleType: "disc", p: 0 }}>
                  <ListItem
                    sx={{
                      display: "list-item",
                      p: 0,
                      fontSize: "1rem",
                      color: "#fff",
                    }}
                  >
                    {window.location.hostname.split(".")[0]} withholds the rights to terminate or alter
                    the offered promotions, turnover logics and all things
                    related without any prior notification.
                  </ListItem>
                </List>
              </Box>
            </Container>
          </Box>
        </Container>
      </Box>
    </Box>
  )
}

export default Promotion
