import { Box, Container } from "@mui/material"
import ProfiLossBreadcrumb from "./ProfiLossBreadcrumb"
import ProfitLossTabs from "./ProfitLossTabs"
import { useLocation } from "react-router"

const ProfitLossDetails = () => {
  const { state } = useLocation()
  const data = state?.profitlossmatchwiseData

  return (
    <Box className="profit-loss-details">
      <ProfiLossBreadcrumb title={state?.matchname} />

      <Box sx={{ backgroundColor: "#121212" }}>
        <Container maxWidth="lg">
          <ProfitLossTabs data={data} />
        </Container>
      </Box>
    </Box>
  )
}

export default ProfitLossDetails
