import { Box, Grid, Typography } from "@mui/material"
// import downloadapp from https://s3buket.blr1.cdn.digitaloceanspaces.com/images/download-app.webp

const DownloadTheApp = () => {
  return (
    <Box
      sx={{
        paddingBottom: "20px",
        marginTop: "25px",
        position: "relative",
        backgroundColor: "#f36c21",
      }}
    >
      <Grid container>
        <Grid item xs={5}>
          <Box sx={{ textAlign: "end" }}>
            <img
              loading="lazy"
              src="https://s3buket.blr1.cdn.digitaloceanspaces.com/images/download-app.webp"
              alt="downloadappimg"
              className="lg:w-[475px] ml-auto"
            />
          </Box>
        </Grid>
        <Grid item xs={7}>
          <Box className="download-fair-ply absolute top-[30%] flex flex-col items-start">
            <Box className="club-title-name">
              <Typography
                component="h2"
                sx={{
                  marginBottom: "16px",
                  fontSize: { md: "35px", xs: "13px" },
                  fontFamily: "Lato,sans-serif",
                  color: "#fff",
                  fontWeight: "700",
                  textTransform: "uppercase",
                  display: { md: "block", xs: "none" },
                }}
              >
                DOWNLOAD THE APP
              </Typography>
            </Box>
            <Box
              className="download-text"
              sx={{ margin: { md: "auto", xs: "0 10px", textAlign: "center" } }}
            >
              <Typography
                component="span"
                sx={{
                  fontSize: "17px",
                  fontWeight: "700",
                  fontFamily: "Lato,sans-serif",
                  color: "#fff",
                  textAlign: "left",
                  display: { md: "block", xs: "none" },
                }}
              >
                Now download the CO Official app and get easier, quicker access
                to your winnings!
              </Typography>

              <Typography
                component="p"
                sx={{
                  fontSize: { md: "14px", xs: "13px" },
                  textAlign: { md: "left", xs: "center" },
                  color: "#f8f9fa",
                  fontWeight: "600",
                }}
              >
                Download now outside Google Playstore
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default DownloadTheApp
