import {
  useEffect,
  useState,
  type Dispatch,
  type FC,
  type SetStateAction,
} from "react"
import {
  Box,
  Button,
  Grid,
  List,
  ListItem,
  Typography,
  useMediaQuery,
} from "@mui/material"

// import cashoutimg from "https://s3buket.blr1.cdn.digitaloceanspaces.com/images/cashoutico.webp"

import { TiStopwatch } from "react-icons/ti"
import BetModalMobileAndTablet from "../../../common/betmodal/BetModalMobileAndTablet"
import SuspandedComp from "../../../common/SuspandedComp"
import BookmakerComp from "../../BookmakerComp"
import type { AnyListenerPredicate } from "@reduxjs/toolkit"
import { BetPlace } from "./SportsEventDetail"
import {
  useUseroddspnlQuery,
  useWinnerPnlQuery,
} from "../../../api/mainApi/mainApiSlice"
import { posNegPnl } from "../../../utils/posNegPnl"

const teamnamestyle = {
  whiteSpace: "nowrap",
  fontSize: { md: "0.75rem", xs: "10px" },
  fontFamily: "Roboto,sans-serif ",
  fontWeight: "700",
  color: "#212121",
  opacity: 0.9,
  padding: "0 5px",
}

interface MatchDataDetailProps {
  handleDesktopModalOpen: any
  handleDesktopModalClose: () => void
  DesktopModal: boolean
  sportDetailOddsData: BetFairSportEventDetailRes | undefined
  handleClickModals: () => void
  handleModalOpen: any
  setBetPlacedData: Dispatch<SetStateAction<BetPlace>>
  betPlaceData: BetPlace
  matchid: any
  token: string | null
  state: any
}

const MatchDataDetail: FC<MatchDataDetailProps> = ({
  handleModalOpen,
  handleDesktopModalOpen,
  handleDesktopModalClose,
  DesktopModal,
  handleClickModals,
  sportDetailOddsData,
  matchid,
  betPlaceData,
  setBetPlacedData,
  token,
  state,
}) => {
  const isDesktop = useMediaQuery("(min-width:769px)")
  const isMobile = useMediaQuery("(max-width:425px)")

  const { data: oddspnldata } = useUseroddspnlQuery(
    { matchId: matchid },
    { pollingInterval: 1000, skip: !token },
  )
  const [oddsObject, setOddsObject] = useState<{
    [x: string]: { [y: number]: number | string }
  }>({})
  const { data: winnerpnldata } = useWinnerPnlQuery(
    {
      marketId: state?.marketId,
    },
    { pollingInterval: 1000, skip: !token },
  )

  useEffect(() => {
    let resobj = { [state?.marketId]: {} }
    winnerpnldata?.data?.forEach((item: any) => {
      resobj[item.selctionId as number] = item.liability
    })
    setOddsObject(resobj)
  }, [winnerpnldata])

  console.log(sportDetailOddsData?.Odds?.[0]?.matchName, "kakakakakakakkkkkk")

  return (
    <>
      {sportDetailOddsData?.Odds?.map(runnersdata => (
        <Box className="sportscomp" key={runnersdata?.marketId}>
          <Box
            sx={{
              overflow: "hidden",
              borderRadius: "15px",
              my: 2,
            }}
          >
            <Grid container>
              <Grid
                item
                xs={5}
                md={4}
                sx={{ backgroundColor: "#f36c21", display: "flex" }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    px: "10px",
                    justifyContent: "flex-start",
                  }}
                >
                  <Typography
                    component="p"
                    sx={{
                      color: "#f8f9fa",
                      fontSize: { md: "11px", xs: "12px" },
                      ml: 1,
                      fontWeight: "600",
                      fontFamily: "roboto, sans-serif",
                      textTransform: "uppercase",
                    }}
                  >
                    {runnersdata?.Name}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={7} md={8}>
                <Grid
                  container
                  className="rightheadstrip"
                  sx={{ backgroundColor: "#272727" }}
                >
                  <Grid
                    item
                    xs={4}
                    md={3}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Button
                      onClick={() => {}}
                      sx={{
                        transition: "all .4s ease 0s",
                        background:
                          "linear-gradient(0deg,#c6a352,#f3e88a 50%,#c6a352 85%)",
                        display: "flex",
                        alignItems: "center",
                        textAlign: "center",
                        textTransform: "uppercase",
                        color: "#000",
                        fontSize: "11px",
                        fontWeight: "500",
                        overflow: "hidden",
                        border: 0,
                        padding: { md: 0, xs: "5px 10px" },
                        width: { md: "85px", xs: "auto" },
                        minWidth: "unset",
                        lineHeight: "25px",
                        minHeight: { md: "25px", xs: "auto" },
                      }}
                    >
                      <Typography component="span">
                        <img
                          src={
                            "https://s3buket.blr1.cdn.digitaloceanspaces.com/images/cashoutico.webp"
                          }
                          alt=""
                          className="w-[10px] h-[10px]"
                        />
                      </Typography>
                      {!isMobile && (
                        <Typography component="span" sx={{ fontSize: "11px" }}>
                          cashout
                        </Typography>
                      )}
                    </Button>
                  </Grid>

                  <Grid item xs={8} md={9}>
                    <Grid container sx={{ padding: "9px" }}>
                      <Grid item xs={4}>
                        <Typography
                          component="p"
                          sx={{
                            fontSize: { md: "15px", xs: "13px" },
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          {!isMobile && (
                            <Typography
                              component="span"
                              sx={{
                                color: "#fff",
                                fontSize: { md: "15px", xs: "13px" },
                              }}
                            >
                              {runnersdata?.betDelay}
                            </Typography>
                          )}
                          <TiStopwatch color="#fff" fontSize="20px" />
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography
                          sx={{
                            padding: { md: "0 0 0 0" },
                            fontSize: "13px",
                            color: "#fff",
                            textTransform: "capitalize",
                            // margin: "0 10px 0 20px",
                            textAlign: "center",
                          }}
                        >
                          back
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography
                          sx={{
                            color: "#fff",
                            fontSize: "13px",
                            textTransform: "capitalize",
                            textAlign: "left",
                            margin: { md: "0 -30px", xs: "0" },
                          }}
                        >
                          lay
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              container
              sx={{
                backgroundColor: "#fff",
                borderBottom: "1px solid #e0e0e0",
              }}
            >
              <Grid item xs={12} className="wholegridparent">
                {runnersdata?.runners?.map((runner, index) => (
                  <Grid
                    key={runner?.selectionId + index}
                    container
                    sx={{
                      backgroundColor: "#fff",
                      borderBottom: "1px solid #e0e0e0",
                      padding: { lg: "3px 0 3px 10px", md: "3px 0 3px 0" },
                    }}
                  >
                    <Grid
                      item
                      xs={6}
                      lg={6}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Box sx={{ mt: "5px" }}>
                        <Typography
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            justifyContent: "center",
                          }}
                          component="span"
                        >
                          <Typography component="span" sx={teamnamestyle}>
                            {runner?.name}
                          </Typography>
                          <Typography className="pnl" sx={teamnamestyle}>
                            {oddsObject &&
                            oddspnldata &&
                            runnersdata?.Name.includes("Winner")
                              ? posNegPnl(
                                  (oddsObject as any) &&
                                    oddsObject?.[runner?.selectionId],
                                )
                              : posNegPnl(
                                  oddspnldata?.[runnersdata?.marketId]?.[
                                    runner?.selectionId
                                  ],
                                )}
                          </Typography>
                        </Typography>
                      </Box>
                    </Grid>

                    <Grid item xs={6} lg={6} sx={{ position: "relative" }}>
                      {!(runnersdata?.status === "OPEN") ||
                        runnersdata?.runners[0]?.ex?.availableToBack[0]
                          ?.price == 0 ||
                        (runnersdata?.runners[0]?.ex?.availableToLay[0]
                          ?.price == 0 && <SuspandedComp />)}
                      <Box
                        sx={{
                          mt: "5px",
                          width: "100%",
                          display: "flex",
                          justifyContent: "end",
                          height: "42px",
                        }}
                        className="inplaygamelivesec"
                      >
                        <Box className="livebettingbox">
                          <List sx={{ display: "flex", p: 0, pr: "5px" }}>
                            {runner?.ex?.availableToBack
                              ?.map((back, index: number) => {
                                return (
                                  <ListItem
                                    sx={{
                                      textAlign: "center",
                                      margin: "1px",
                                      p: 0,
                                      overflow: "hidden",
                                      backgroundColor: "#a5d9fe",
                                      borderRadius: { xs: "10px", md: 0 },
                                      display: `${isMobile && (index === 1 || index === 2) ? "none" : "block"}`,
                                    }}
                                    key={index}
                                    onClick={() => {
                                      if (!token) {
                                        handleClickModals()
                                      } else {
                                        handleDesktopModalOpen(
                                          false,
                                          true,
                                          back?.price,
                                          runner?.name,
                                          runnersdata?.Name,
                                          runner?.selectionId,
                                          back?.price,
                                          runnersdata?.marketId,
                                          runnersdata?.matchName,
                                          // new Date(),
                                          runnersdata?.maxBet,
                                          runnersdata?.minBet,
                                        )
                                      }
                                    }}
                                  >
                                    <button
                                      className={`odd-btnn h-[40px] lg:h-[40px] py-[1px] rounded-[10px] lg:rounded-[0px] px-[6px] text-center text-[11px] lg:w-[59px] text-[#000] inline-block leading-[13px] border-0 overflow-hidden `}
                                      disabled={index === 1 || index === 2}
                                    >
                                      <Typography
                                        component="span"
                                        sx={{
                                          fontSize: "11px",
                                          color: "#000",
                                          fontFamily: "Roboto,sans-serif",
                                        }}
                                      >
                                        <Typography
                                          component="strong"
                                          sx={{
                                            fontSize: "11px",
                                            fontWeight: "bold",
                                            color: "#000",
                                            fontFamily: "Roboto,sans-serif",
                                          }}
                                        >
                                          {back?.price
                                            ? back?.price.toFixed(2)
                                            : 0}
                                        </Typography>
                                      </Typography>
                                      <br />
                                      <Typography
                                        component="span"
                                        sx={{ fontSize: "11px", color: "#000" }}
                                      >
                                        {back?.size ? back?.size.toFixed(2) : 0}
                                      </Typography>
                                    </button>
                                  </ListItem>
                                )
                              })
                              .reverse()}
                            {runner?.ex?.availableToLay?.map(
                              (lay, index: number) => (
                                <ListItem
                                  onClick={() => {
                                    if (!token) {
                                      handleClickModals()
                                    } else {
                                      handleDesktopModalOpen(
                                        false,
                                        false,
                                        lay?.price,
                                        runner?.name,
                                        runnersdata?.Name,
                                        runner?.selectionId,
                                        lay?.price,
                                        runnersdata?.marketId,
                                        runnersdata?.matchName,
                                        // new Date(),
                                        runnersdata?.maxBet,
                                        runnersdata?.minBet,
                                      )
                                    }
                                  }}
                                  sx={{
                                    margin: "1px",
                                    textAlign: "center",
                                    p: 0,
                                    overflow: "hidden",
                                    backgroundColor: "#F8D0CE",
                                    borderRadius: { xs: "10px", md: 0 },
                                    display: `${isMobile && (index === 1 || index === 2) ? "none" : "block"}`,
                                  }}
                                  key={index}
                                >
                                  <button
                                    className={`odd-btnn h-[40px] lg:h-[40px] py-[1px] rounded-[10px] lg:rounded-[0px] px-[6px] text-center text-[11px] lg:w-[59px] text-[#000] inline-block leading-[13px] border-0 overflow-hidden `}
                                    disabled={index === 1 || index === 2}
                                  >
                                    <Typography
                                      component="span"
                                      sx={{
                                        fontSize: "11px",
                                        color: "#000",
                                        fontFamily: "Roboto,sans-serif",
                                      }}
                                    >
                                      <Typography
                                        component="strong"
                                        sx={{
                                          fontSize: "11px",
                                          fontWeight: "bold",
                                          color: "#000",
                                          fontFamily: "Roboto,sans-serif",
                                        }}
                                      >
                                        {lay?.price ? lay?.price.toFixed(2) : 0}
                                      </Typography>
                                    </Typography>
                                    <br />
                                    <Typography
                                      component="span"
                                      sx={{ fontSize: "11px", color: "#000" }}
                                    >
                                      {lay?.size ? lay?.size.toFixed(2) : 0}
                                    </Typography>
                                  </button>
                                </ListItem>
                              ),
                            )}
                          </List>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid container>
                      {runner?.selectionId === betPlaceData?.selectionId && (
                        <Grid item xs={12}>
                          {!isDesktop && DesktopModal && (
                            <BetModalMobileAndTablet
                              betPlaceData={betPlaceData}
                              setBetPlacedData={setBetPlacedData}
                              handleDesktopModalClose={handleDesktopModalClose}
                            />
                          )}
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                ))}
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  gap: "10px",
                }}
              >
                <Typography component="p">
                  <Typography
                    component="span"
                    sx={{
                      color: "#212529",
                      fontSize: { md: "13px", xs: "11px" },
                      fontWeight: "600",
                    }}
                  >
                    Min:
                  </Typography>
                  <Typography
                    component="span"
                    sx={{
                      color: "#212529",
                      fontSize: { md: "13px", xs: "11px" },
                      fontWeight: "600",
                    }}
                  >
                    {runnersdata?.minBet}
                  </Typography>
                </Typography>
                <Typography
                  component="p"
                  sx={{ marginRight: { md: 15, xs: 1 } }}
                >
                  <Typography
                    component="span"
                    sx={{
                      color: "#212529",
                      fontSize: { md: "13px", xs: "11px" },
                      fontWeight: "600",
                    }}
                  >
                    Max:
                  </Typography>
                  <Typography
                    component="span"
                    sx={{
                      color: "#212529",
                      fontSize: { md: "13px", xs: "11px" },
                      fontWeight: "600",
                    }}
                  >
                    {runnersdata?.maxBet}
                  </Typography>
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
      ))}

      {sportDetailOddsData && sportDetailOddsData?.Bookmaker.length > 0 && (
        <BookmakerComp
          token={token}
          oddspnldata={oddspnldata}
          betPlaceData={betPlaceData}
          setBetPlacedData={setBetPlacedData}
          sportDetailOddsData={sportDetailOddsData}
          handleDesktopModalClose={handleDesktopModalClose}
          DesktopModal={DesktopModal}
          handleClickModals={handleClickModals}
          handleDesktopModalOpen={handleDesktopModalOpen}
        />
      )}
    </>
  )
}

export default MatchDataDetail
