import { Box } from "@mui/material"
import SecondHeadingBar from "../../../../common/SecondHeadingBar"
import ResetPasswordForm from "./ResetPasswordForm"

const ResetPassword = () => {
  return (
    <Box className="reset-password">
      <SecondHeadingBar title="Reset Password" />

      <Box className="reset-password-parent">
        <Box
          className="reset-password-sec"
          sx={{
            border: "thin solid hsla(0,0%,100%,.12)",
            padding: { md: "30px 10px 30px 20px", xs: "30px 10px 30px 10px" },
            overflow: "hidden",
            background: "#1e1e1e",
            margin: { md: "35px auto", xs: "17px 2px" },
            width: { lg: "33%", md: "50%", xs: "auto" },
            borderRadius: "5px",
          }}
        >
          <ResetPasswordForm />
        </Box>
      </Box>
    </Box>
  )
}
export default ResetPassword
