import { Box, Container, Grid, Typography } from "@mui/material"
import LeftMenu from "../../common/LeftMenu"
// import bannerbgimg from "https://s3buket.blr1.cdn.digitaloceanspaces.com/images/desktop-live-casino-banner.webp"

const Matka = () => {
  return (
    <Box className="matkaparent" sx={{ my: 1 }}>
      <Grid container spacing={1}>
        <Grid item xs={1.8}>
          <LeftMenu />
        </Grid>
        <Grid item xs={10.2}>
          <Box
            className="bannerbgcontentparent"
            sx={{
              borderRadius: "10px",
              overflow: "hidden",
              background: "#272727",
            }}
          >
            <Box
              sx={{
                backgroundImage: `url(${"https://s3buket.blr1.cdn.digitaloceanspaces.com/images/desktop-live-casino-banner.webp"})`,
                padding: "106px 0",
              }}
              className="matkabgimage"
            >
              <Box className="game-content-outer">
                <Container maxWidth="md">
                  <Box className="content-here">
                    <Typography
                      component="h5"
                      sx={{
                        color: "transparent",
                        fontSize: "45px",
                        fontWeight: "500",
                        backgroundSize: "200% auto",
                        textTransform: "uppercase",
                        WebkitBackgroundClip: "text !important",
                        lineHeight: "54px",
                        textShadow: "0 4px 4px rgba(0,0,0,.25)",
                        animation: "textclip 3s linear infinite",
                        display: "inline-block",
                        backgroundImage:
                          "linear-gradient(90deg,#6c23a9,#f8a0ef,#da9ef6,#a7b0ff,#afd2fd)",
                      }}
                    >
                      MATKA
                    </Typography>
                    <Typography component="p" sx={{ color: "#fff" }}>
                      Play. Win. Enjoy.
                    </Typography>
                  </Box>
                </Container>
              </Box>
            </Box>
            <Box
              className="contentholder"
              sx={{ display: "flex", alignItems: "flex-start", my: 2 }}
            >
              <Typography
                sx={{
                  color: "#fff",
                  fontSize: "30px",
                  lineHeight: "36px",
                  letterSpacing: ".09em",
                  textShadow: "0 4px 4px rgba(0,0,0,.25)",
                  fontWeight: "600",
                }}
              >
                Matka Market
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}
export default Matka
