
import { createTheme, Theme } from "@mui/material";
// export const font = '"Roboto, sans-serif"';
declare module "@mui/material/styles" {
    interface Palette {
        tertiary: Palette["primary"];
    }

    interface PaletteOptions {
        tertiary?: PaletteOptions["primary"];
    }
}

export const theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 580,
            md: 768,
            lg: 1280,
            xl: 1600,
        },
    },
    palette: {
        mode: "dark",
        background: {
            default: "#121212",
            paper: "#17191C",
        },
        primary: {
            main: "#fff",
        },
        secondary: {
            contrastText: "white",
            main: "#0E684E",
        },
        tertiary: {
            contrastText: "white",
            main: "#762E56",
        },
        text: {
            primary: "#aaafb5",
            secondary: "#aaafb5",
        },
    },
    typography: {
        // fontFamily: font,
        button: {
            textTransform: "none",
        },
    },
    components: {
        MuiAppBar: {
            styleOverrides: {
                root: ({ theme }) => ({
                    backgroundColor: "#000",
                    [theme.breakpoints.down("lg")]: {
                        marginBottom: 32,

                    },
                }),
            },
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    border: "none !important",
                    backgroundColor: "#000",
                    backgroundImage: 'none'
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    padding: 0
                },
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    borderRadius: 0,
                },
            },
        },
        MuiDrawer: {
            styleOverrides: {
                root: {
                    color: '#000'
                }
            }
        },
        MuiSelect: {
            styleOverrides: {
                root: {
                    padding: 0
                }
            }
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    background: 'transparent'
                }
            }
        }

    },
});


