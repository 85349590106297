import * as React from "react"
import AppBar from "@mui/material/AppBar"
import Box from "@mui/material/Box"
import CssBaseline from "@mui/material/CssBaseline"
import Divider from "@mui/material/Divider"
import Drawer from "@mui/material/Drawer"
import IconButton from "@mui/material/IconButton"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"

import Toolbar from "@mui/material/Toolbar"
import Button from "@mui/material/Button"

import { Outlet, useNavigate } from "react-router"
import { Link } from "react-router-dom"
import { BsNewspaper } from "react-icons/bs"
import { IoIosRefresh } from "react-icons/io"
import { renderToStaticMarkup } from "react-dom/server"
import type { IconType } from "react-icons"
import { theme } from "../utils/theme"
// import LanguageSelector from "./LanguageSelector"
import { LeftSideBarData } from "./LeftSideBarData"
import {
  Avatar,
  CircularProgress,
  Typography,
  useMediaQuery,
} from "@mui/material"
import { useAppSelector } from "../hooks/useAppSelector"
import { FaWallet } from "react-icons/fa"
import { IoMdPerson } from "react-icons/io"
import RightDrawer from "./rightdrawer/RightDrawer"
import {
  useGetUserBalanceQuery,
} from "../api/mainApi/mainApiSlice"


const iconToSvg = (IconComponent: IconType, color?: string) => {
  const svgString = renderToStaticMarkup(<IconComponent style={{ color }} />)
  return `data:image/svg+xml;utf8,${encodeURIComponent(svgString)}`
}

const drawerWidth = 274
const navItems = [
  {
    name: "news",
    icon: iconToSvg(BsNewspaper, "#fff"),
  },
  {
    name: "refresh",
    icon: iconToSvg(IoIosRefresh, "#fff"),
  },
  {
    name: "blogs",
    icon: iconToSvg(BsNewspaper, "#fff"),
  },
]

interface HeaderProps {
  isSelfData: isSelfRes | undefined
}

const Header: React.FC<HeaderProps> = ({ isSelfData }) => {
  const [mobileOpen, setMobileOpen] = React.useState(false)
  const token = localStorage.getItem("token")

  const [open, setRightDrawerOpen] = React.useState(false)
  const [favico, setFavIco] = React.useState("")
  let userLocalData: string | null = null
  const loginData = localStorage.getItem("loginData")
  if (loginData) {
    userLocalData = JSON.parse(loginData).userId 
  }

  const rightDrawerToggle = (newOpen: boolean) => () => {
    setRightDrawerOpen(newOpen)
  }

  const {
    data: userBalanceData,
    isFetching,
    isLoading,
  } = useGetUserBalanceQuery({}, { pollingInterval: 5000, skip: !token })

  console.log(userBalanceData, "lkajsdflkjas")

  const navigate = useNavigate()

  const isMobile = useMediaQuery("(max-width:900px)")

  const handleDrawerToggle = () => {
    setMobileOpen(prevState => !prevState)
  }

  const nav = (route: string) => {
    navigate(route)
  }

  React.useEffect(() => {
    if (isSelfData?.data?.favicon) {
      let ico = isSelfData?.data?.favicon
      localStorage.setItem("favico", ico)
    }
  }, [])

  React.useEffect(() => {
    let getfavico = localStorage?.getItem("favico")?.toString()
    setFavIco(getfavico || "")
    const faviconLink = document.getElementById("favico")
    faviconLink?.setAttribute("href", favico)
  }, [favico])

  React.useEffect(() => {
    if (!token) {
      setRightDrawerOpen(false)
    }
  }, [token])

  const drawer = (
    <div>
      <Box className="bg-[#1e1e1e] block p-[14px]">
        {!token ? (
          <Box className="not-login-box">
            <Link to={"/"}>
              <img
                src={isSelfData?.data?.logo}
                alt=""
                className="w-[80px] h-[30px] m-auto max-w-[unset] lg:w-[150px] lg:h-[50px] "
              />
            </Link>
            {
              isSelfData?.data?.selfAllowed && <Button
                onClick={() => nav("/register")}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  flexDirection: "column",
                  margin: "7px auto",
                  width: "100%",
                  maxWidth: { md: "calc(100% - 50%)", xs: "calc(100% - 55%)" },
                  backgroundColor: "#f36c21",
                  textTransform: "uppercase",
                  padding: "8px 20px",
                  color: "#fff",
                  fontWeight: "600",
                  ":hover": {
                    backgroundColor: "#f36c21",
                  },
                }}
              >
                register
              </Button>
            }

          </Box>
        ) : (
          <Box
            className="login-box"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              gap: 2,
            }}
          >
            <Avatar sx={{ backgroundColor: "#fff", width: 50, height: 50 }}>
              {userLocalData?.slice(0, 1).toUpperCase()}
              {/* {demouserName?.slice(0, 1).toUpperCase()} */}
            </Avatar>
            <Typography sx={{}}>
              {userLocalData}
              {/* {demouserName} */}
            </Typography>
          </Box>
        )}
      </Box>
      <Box
        onClick={handleDrawerToggle}
        sx={{ textAlign: "center", overflowY: "scroll", height: "100dvh" }}
      >
        <Divider />
        <List>
          {LeftSideBarData[0].map(item => (
            <ListItem
              key={item.name}
              disablePadding
              sx={{ borderBottom: "1px solid #fff" }}
            >
              <Link
                to={item.link}
                className="group w-full flex items-center p-3  
                 font-semibold hover:bg-orange-500"
              >
                <Box>
                  <item.icon className="text-orange-500 text-lg mr-3 group-hover:text-white" />
                </Box>
                <Typography className="capitalize text-white group-hover:text-white">
                  {item.name}
                </Typography>
              </Link>
            </ListItem>
          ))}
        </List>
        <Divider />
        <List>
          {LeftSideBarData?.[1].map(item => (
            <ListItem
              key={item.name}
              disablePadding
              sx={{ borderBottom: "1px solid #fff" }}
            >
              <Link
                to={item.link}
                className=" w-full flex items-center p-3  
                 font-semibold"
              >
                <Box>
                  <img
                    src={item.icon.toString()}
                    alt=""
                    className="h-[18px] w-[18px] mx-1"
                  />
                </Box>
                <Typography className="capitalize text-white">
                  {item.name}
                </Typography>
              </Link>
            </ListItem>
          ))}
        </List>
        <Button
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexDirection: "column",
            margin: "7px auto",
            width: "100%",
            maxWidth: "calc(100% - 30%)",
            backgroundColor: "#4caf50",
            textTransform: "uppercase",
            padding: "8px 20px",
            color: "#fff",
            fontWeight: "600",
            ":hover": {
              backgroundColor: "#4caf50",
            },
          }}
        >
          download app
        </Button>
      </Box>
    </div>
  )


  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        component="nav"
        sx={{ padding: { md: "10px 0px", xs: "5px 0px" } }}
      >
        <Toolbar sx={{ padding: { md: "0 24px", xs: "0 12px" } }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 0 }}
          >
            <img
              src="https://s3buket.blr1.cdn.digitaloceanspaces.com/images/toggle.webp"
              alt=""
              className="w-[20px] h-[15px] lg:w-[25px] lg:h-[24px]"
            />
          </IconButton>
          <Link to={"/"}>
            <img
              src={isSelfData?.data?.logo}
              alt=""
              className="w-[80px] h-[30px] lg:w-[150px] lg:h-[50px] max-w-[unset]"
            />
          </Link>
          {!isMobile && (
            <Box sx={{ display: { xs: "none", md: "flex" } }}>
              {navItems.map(item => (
                <div key={item.name}>
                  <button
                    className=" bg-[#f36c21] rounded-full h-[35px] w-[35px] p-0
                                 text-white text-sm flex flex-col justify-center items-center mx-1 hover:bg-[#f36c21]"
                  >
                    <img src={item.icon} alt={item.name} />
                  </button>
                  <span className="capitalize text-[10px] mt-3 text-white text-center inline-block w-full">
                    {item.name}
                  </span>
                </div>
              ))}
            </Box>
          )}

          {!token ? (
            <Box sx={{ display: "flex", width: "100%" }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  width: "100%",
                  gap: 1,
                }}
              >
                {/* {!isMobile && <LanguageSelector />} */}
                <Button
                  onClick={() => nav("/login")}
                  sx={{
                    borderRadius: " 30px",
                    padding: { md: "10px 20px", xs: "6px 12px" },
                    textTransform: "uppercase",
                    fontWeight: 600,
                    letterSpacing: ".0892857143em",
                    backgroundColor: "#f36c21",
                    height: "auto",
                    maxHeight: "44px",
                    fontSize: { md: "15px", xs: "13px" },
                    ":hover": {
                      backgroundColor: "#f36c21",
                    },
                  }}
                >
                  Login
                </Button>
                {
                  isSelfData?.data?.selfAllowed && <Button
                    onClick={() => nav("/register")}
                    sx={{
                      borderRadius: "20px",
                      padding: { md: "10px 20px", xs: "6px 12px" },
                      textTransform: "uppercase",
                      fontWeight: 600,
                      letterSpacing: ".0892857143em",
                      backgroundColor: "#4caf50",
                      maxHeight: "44px",
                      height: "auto",
                      fontSize: { md: "15px", xs: "13px" },
                      ":hover": {
                        backgroundColor: "#4caf50",
                      },
                    }}
                  >
                    Register
                  </Button>
                }

              </Box>
            </Box>
          ) : (
            <Box sx={{ ml: "auto" }} className="profilebuttonholder">
              {!isMobile && (
                <Button
                  onClick={() => nav("/bets")}
                  variant="outlined"
                  disableRipple
                  disableTouchRipple
                  sx={{
                    color: "#f36c21",
                    mr: "4px",
                    padding: { md: "6px 21px" },
                    textTransform: "capitalize",
                    borderRadius: "28px",
                    border: "1px solid currentcolor",
                    minWidth: "64px",
                    ":hover": {
                      backgroundColor: "#2c1306",
                      border: "1px solid #f36c21",
                    },
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: "400",
                      color: "#fff",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    open bets
                  </Typography>
                </Button>
              )}
              {
                <>
                  <Button
                    variant="outlined"
                    disableRipple
                    disableTouchRipple
                    sx={{
                      color: "#4caf50",
                      mr: "4px",
                      padding: { md: "6px 21px" },
                      textTransform: "capitalize",
                      borderRadius: "28px",
                      border: "1px solid currentcolor",
                      minWidth: isMobile ? "30px" : "64px", 
                      minHeight: isMobile ? "30px" : "100%", 
                      ":hover": {
                        backgroundColor: "#122a13 ",
                        border: "1px solid #f36c21",
                      },
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: { md: isMobile?"18px":"14px", xs: "12px" },
                        fontWeight: isMobile?"900":"400",
                        color: "#fff",
                        display: "flex",
                        alignItems: "center",
                      }}
                      onClick={() => nav('/deposit')}
                    >
                      {!isMobile && (
                        <img
                          src={
                            "https://s3buket.blr1.cdn.digitaloceanspaces.com/images/searchbar/deposit.webp"
                          }
                          alt="depositimg"
                          className="lg:w-[20px] lg:h-[20px] h-[20px] w-[20px]"
                        />
                      )}
                     {isMobile?"D":"Deposit"}
                    </Typography>
                  </Button>

                  <Button
                    variant="outlined"
                    disableRipple
                    disableTouchRipple
                    sx={{
                      color: "#4caf50",
                      mr: "4px",
                      padding: { md: "6px 21px" },
                      textTransform: "capitalize",
                      borderRadius: "28px",
                      border: "1px solid currentcolor",
                      minWidth: isMobile ? "30px" : "64px", 
                      minHeight: isMobile ? "30px" : "100%", 
                      ":hover": {
                        backgroundColor: "#122a13 ",
                        border: "1px solid #f36c21",
                      },
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: { md: isMobile?"18px":"14px", xs: "12px" },
                        fontWeight: isMobile?"900":"400",
                        color: "#fff",
                        display: "flex",
                        alignItems: "center",
                      }}
                      onClick={() => nav('/withdraw')}
                    >
                      {!isMobile && (
                        <img
                          src={
                            "https://s3buket.blr1.cdn.digitaloceanspaces.com/images/searchbar/deposit.webp"
                          }
                          alt="depositimg"
                          className="lg:w-[20px] lg:h-[20px] h-[20px] w-[20px]"
                        />
                      )}
                       {isMobile?"W":"Withdraw"}
                    </Typography>
                  </Button>
                </>
              }



              <Button
                variant="outlined"
                disableRipple
                disableTouchRipple
                sx={{
                  color: "#f36c21",
                  mr: "4px",
                  padding: { md: "6px 21px", xs: "0px 7px" },
                  textTransform: "capitalize",
                  borderRadius: "28px",
                  border: "1px solid currentcolor",
                  minWidth: "64px",
                  ":hover": {
                    backgroundColor: "#2c1306",
                    border: "1px solid #f36c21",
                  },
                }}
              >
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: "400",
                    color: "#fff",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <span className="text-[12px] mr-1">
                    <FaWallet />
                  </span>
                  <span className="walletbalance text-[12px] lg:text-[14px]">
                    {isLoading && isFetching ? (
                      <CircularProgress size="20px" color="primary" />
                    ) : (
                      userBalanceData?.data?.balance || "0.00"
                    )}
                  </span>
                </Typography>
              </Button>
              <Button
                onClick={rightDrawerToggle(true)}
                variant="outlined"
                disableRipple
                disableTouchRipple
                sx={{
                  color: "#fff",
                  mr: "4px",
                  padding: { md: "6px 21px", xs: "3px 12px" },
                  textTransform: "capitalize",
                  borderRadius: "28px",
                  border: "1px solid currentcolor",
                  minWidth: { md: "64px", xs: "unset" },
                  ":hover": {
                    backgroundColor: "#2c1306",
                    border: "1px solid #fff",
                  },
                }}
              >
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: "400",
                    color: "#fff",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <span className="text-[12px] lg:text-[14px]  lg:mr-1">
                    <IoMdPerson />
                  </span>
                  {!isMobile && (
                    <span className="userprofile text-[12px] lg:text-[14px]">
                      profile
                    </span>
                  )}
                </Typography>
              </Button>
            </Box>
          )}
        </Toolbar>
      </AppBar>
      <nav className="drawer1left-side">
        <Drawer
          // container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              overflow: "hidden",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </nav>

      <nav className="rightdrawer">
        <RightDrawer
          // demouserName={demouserName}
          open={open}
          rightDrawerToggle={rightDrawerToggle}
          userBalanceData={userBalanceData}
          setRightDrawerOpen={setRightDrawerOpen}
        />
      </nav>

      <Box component="main" sx={{ padding: { md: "10.8px", xs: "5.8px " } }}>
        <Toolbar />
      </Box>
    </Box>
  )
}
export default Header
