import { Box, Grid, Typography, useMediaQuery } from "@mui/material"
import LeftMenu from "../../../common/LeftMenu"
import HeadingStrip from "../../../common/HeadingStrip"
import AccordionForMatch from "./AccordionForMatch"
import { CiClock2 } from "react-icons/ci"
import { BsInfoCircle } from "react-icons/bs"
import { useState } from "react"
import InfoDialog from "./InfoDialog"
import MarketOpenBetTab from "./MarketOpenBetTabs"

const GreyHoundRacingDetails = () => {
  const isMobile = useMediaQuery("(max-width:'425px)")
  const itemToShow = isMobile ? 12 : 10
  const [open, setOpen] = useState(false)

  const handleInfoModal = () => {
    setOpen(true)
  }

  return (
    <Box>
      {/* info modal */}
      <InfoDialog open={open} setOpen={setOpen} />

      <Box sx={{ my: 1 }}>
        <Grid container spacing={1}>
          <Grid
            item
            xs={1.8}
            sx={{ display: { xs: "none", sm: "none", md: "block" } }}
          >
            <LeftMenu />
          </Grid>
          <Grid item xs={12} md={itemToShow}>
            <Box sx={{ backgroundColor: "#272727", width: "100%" }}>
              <HeadingStrip title="horse racing" />
              <Box
                sx={{
                  padding: "15px",
                  background: "#005a40",
                  position: "relative",
                }}
                className="horse-detail-wrapper"
              >
                <Box
                  onClick={handleInfoModal}
                  className="infoiconholder"
                  sx={{
                    position: "absolute",
                    top: "35px",
                    right: "15px",
                    cursor: "pointer",
                    height: " 30px",
                    width: " 30px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <BsInfoCircle fontSize={30} color="#fff" />
                </Box>
                <Box
                  className="live-bet-sec"
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <CiClock2 color="#fff" />
                  <Typography
                    component="h5"
                    sx={{
                      fontSize: "14px",
                      color: "#fff",
                      marginLeft: "10px",
                      fontWeight: "400",
                    }}
                  >
                    in 33 minutes
                  </Typography>
                  <Typography
                    component="h5"
                    sx={{
                      fontSize: "14px",
                      color: "#fff",
                      marginLeft: "10px",
                      fontWeight: "400",
                    }}
                  >
                    May 29 6:00 pm
                  </Typography>
                </Box>
                <Box
                  className="live-bet-sec"
                  sx={{ display: "flex", alignItems: "center", mt: "5px" }}
                >
                  <Typography
                    component="h5"
                    sx={{
                      fontSize: "18px",
                      lineHeight: "normal",
                      fontFamily: "Roboto,sans-serif",
                      color: "#fff",
                      marginLeft: "10px",
                      fontWeight: "500",
                      textTransform: "capitalize",
                    }}
                  >
                    7:07 Pm Cannington (AUS) 30th May
                  </Typography>
                </Box>
                <Box
                  className="live-bet-sec"
                  sx={{ display: "flex", alignItems: "center", mt: "0.5rem" }}
                >
                  <Typography
                    component="h5"
                    sx={{
                      fontSize: "14px",
                      lineHeight: "normal",
                      fontFamily: "Roboto,sans-serif",
                      color: "#fff",
                      marginLeft: "10px",
                      fontWeight: "500",
                      textTransform: "capitalize",
                      borderRight: "2px solid #ccc",
                      paddingRight: "5px",
                    }}
                  >
                    Winners: 1
                  </Typography>
                  <Typography
                    component="h5"
                    sx={{
                      fontSize: "14px",
                      lineHeight: "normal",
                      fontFamily: "Roboto,sans-serif",
                      color: "#fff",
                      marginLeft: "10px",
                      fontWeight: "500",
                      textTransform: "capitalize",
                    }}
                  >
                    Matched : 559,776
                  </Typography>
                </Box>
              </Box>

              <Box className="tabholderparent">
                <MarketOpenBetTab />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}
export default GreyHoundRacingDetails
