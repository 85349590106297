import { Accordion, AccordionDetails, AccordionSummary, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import type { FC } from 'react';
import React from 'react'
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import NoData from '../../../common/NoData';
import { StatusTypography } from '.';

interface Props {
    data: DepositList[] | undefined;
    setImageSelected: React.Dispatch<React.SetStateAction<string>>
}

const styleth = {
    textAlign: "left",
    color: "#fff",
    textTransform: "capitalize",
    textWrap: "nowrap",
  }
  
  const styletd = {
    textAlign: "left",
    color: "#fff",
    textTransform: "capitalize",
  }

const DepositList: FC<Props> = ({ data, setImageSelected }) => {
    const [isExpanded, setIsExpanded] = React.useState(true);
    const handleAccordionChange = () => {
        setIsExpanded(!isExpanded)
    }
    return (
        <Accordion
            defaultExpanded
            expanded={isExpanded}
            onChange={handleAccordionChange}
            sx={{
                backgroundColor: "transparent",
                "&.Mui-expanded": {
                    margin: 0,
                },
            }}
        >
            <AccordionSummary
                expandIcon={
                    <ExpandMoreIcon
                        sx={{
                            borderRadius: "50%",
                            color: "white",
                            backgroundColor: "#f36c21",
                            padding: "4px",
                        }}
                    />
                }
                aria-controls="matched-bets-content"
                id="matched-bets-header"
                sx={{
                    "&.MuiPaper-root": {
                        ":before": {
                            backgroundColor: "rgba(255, 255, 255, 0)",
                        },
                    },
                    border: "1px solid #f36c21",
                    borderRadius: isExpanded ? "15px 15px 0 0" : "15px",
                    minHeight: "46px !important",
                    "&.Mui-expanded": {
                        minHeight: "46px !important",
                    },
                    "& .MuiAccordionSummary-content.Mui-expanded": {
                        minHeight: "46px !important",
                        margin: "0px",
                    },
                    "& .MuiAccordionSummary-content": {
                        alignItems: "center",
                        margin: "0px",
                    },
                    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
                        transform: "rotate(180deg)",
                    },
                }}
            >
                <Typography variant="h6" sx={{ fontSize: "13px", color: "#fff" }}>
                    Previous Deposit
                </Typography>
            </AccordionSummary>
            <AccordionDetails
                sx={{
                    p: 0,
                    border: "1px solid #f36c21",
                    borderRadius: "0 0 15px 15px",
                    overflow: "hidden",
                    "& .MuiPaper-root": {
                        backgroundColor: "transparent",
                    },
                }}
            >
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell sx={styleth}>Amount</TableCell>
                                <TableCell sx={styleth}>Image</TableCell>
                                <TableCell sx={styleth}>Date</TableCell>
                                <TableCell sx={styleth}>Status</TableCell>
                               
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data?.map((row:any, idx) => (
                                <TableRow
                                    key={idx}
                                    sx={{
                                        "&:last-child td, &:last-child th": { border: 0 }
                                    }}
                                >
                                    <TableCell sx={styletd}>{row?.amount}</TableCell>
                                    <TableCell sx={styletd} ><img onClick={()=>setImageSelected(row?.image)} src={row?.image} alt='Deposit' width={50} height={50}/></TableCell>
                                    <TableCell sx={styletd}>{row?.time}</TableCell>
                                    <TableCell sx={styletd}><StatusTypography status={row?.status } /></TableCell>
                                   
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                {data?.length === 0 && <NoData />}
            </AccordionDetails>
        </Accordion>
    )
}

export default DepositList