import {
  Box,
  Button,
  Container,
  FormControlLabel,
  Grid,
  Switch,
  Typography,
} from "@mui/material"
import SecondHeadingBar from "../../../../common/SecondHeadingBar"
import { useEffect, useState } from "react"
import EditStakeModal from "./EditStakeModal"
import { useGetstakebuttonQuery } from "../../../../api/mainApi/mainApiSlice"

const editoneclick = [1000, 2000, 3000, 4000]

const StakeSettings = () => {
  const [checked, setChecked] = useState(false)
  const [acitveStakebtn, setActiveStakeBtn] = useState(0)
  const {
    data: getStakeData,
    isError,
    isSuccess,
    error,
  } = useGetstakebuttonQuery({})

  const handleChangeChecked = () => {
    setChecked(!checked)
  }

  const handleSelectStake = (currstakeindex: number) => {
    setActiveStakeBtn(currstakeindex)
  }

  const [editstakeOpen, setEditStakeOpen] = useState<boolean>(false)

  const handleOpenEditStake = () => {
    setEditStakeOpen(true)
  }

  return (
    <Box className="stakesetting">
      <SecondHeadingBar title="Settings" />

      <EditStakeModal
        editstakeOpen={editstakeOpen}
        setEditStakeOpen={setEditStakeOpen}
      />

      <Box className="stakesetting-main">
        <Container maxWidth="lg" sx={{ padding: { md: "25px 0px" } }}>
          <Box
            className="oneclickbetlabel"
            sx={{
              borderBottom: "1px solid #333",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              paddingBottom: "5px",
            }}
          >
            <Typography
              sx={{
                color: "#f36c21",
                fontSize: "17px",
                textTransform: "capitalize",
                fontWeight: 600,
              }}
            >
              one click betting
            </Typography>

            <Box className="label-value">
              {/* <Typography sx={{ color: "#fff" }}>1k</Typography> */}
            </Box>

            <Box className="switch-holder">
              <Switch
                checked={checked}
                onChange={handleChangeChecked}
                disableTouchRipple
                disableRipple
                sx={{
                  width: "63px",
                  "& .MuiSwitch-thumb": {
                    height: "15px",
                    width: "15px",
                    margin: "5px 0 0 5px",
                    "&:hover": {
                      boxShadow: "none",
                    },
                  },
                  "& .MuiSwitch-switchBase": {
                    backgroundColor: "rgb(255 255 255 / 0%)",
                  },
                  "& .MuiSwitch-switchBase.Mui-checked": {
                    color: "#fff",

                    "&:hover": {
                      backgroundColor: "transparent",
                    },
                  },
                  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                    boxShadow: "0 0 0 5px rgba(231,75,8,.25)",
                    backgroundColor: "#f36c21",
                    opacity: 1,
                    border: "1px solid rgba(0, 0, 0, 0.076)",
                  },
                  "& .MuiSwitch-track": {
                    backgroundColor: "#ffffff",
                    border: "1px solid rgba(0,0,0,.25)",
                    height: "18px",
                    width: "50px",
                    borderRadius: "30px",
                  },
                }}
                name="odds"
              />
            </Box>
          </Box>
          {/* edit stakeholder */}
          <Box className="editstakeholder" sx={{ my: 3 }}>
            <Grid container spacing={1}>
              {checked && (
                <Grid item xs={12} lg={6}>
                  {checked && (
                    <Box className="edit-one-click-stakes-left">
                      <Typography
                        sx={{
                          fontSize: "17px",
                          color: "#f36c21",
                          textAlign: "left",
                          textTransform: "capitalize",
                          my: 1,
                        }}
                        component="h5"
                      >
                        Edit One-click Stakes
                      </Typography>
                      <Box
                        className="edit-stake-card"
                        sx={{
                          backgroundColor: "#fff",
                          border: "2px solid #f36c21",
                          borderRadius: "10px",
                          overflow: "hidden",
                          padding: "5px 15px",
                        }}
                      >
                        <Box
                          className="grid-container"
                          sx={{
                            display: "grid",
                            gridTemplateColumns: "auto auto auto",
                            gridGap: "15px",
                            padding: "10px 0",
                          }}
                        >
                          {editoneclick?.map((stakedata, idx: number) => (
                            <Box
                              className="grid-child-container"
                              key={stakedata + idx}
                            >
                              <Button
                                disableRipple
                                disableTouchRipple
                                onClick={() => handleSelectStake(idx)}
                                sx={{
                                  width: "100%",
                                  fontSize: "14px",
                                  padding: "10px",
                                  borderRadius: "5px",
                                  backgroundColor:
                                    acitveStakebtn === idx
                                      ? "#f36c21"
                                      : "#e0e0e0",
                                  borderColor: "#e0e0e0",
                                  color: "#000",
                                  ":hover": {
                                    backgroundColor:
                                      acitveStakebtn === idx
                                        ? "#f36c21"
                                        : "#e0e0e0",
                                  },
                                }}
                              >
                                {stakedata}
                              </Button>
                            </Box>
                          ))}
                        </Box>

                        <Box
                          className="edit-button-holder"
                          sx={{ display: "flex" }}
                        >
                          <Button
                            disableRipple
                            disableTouchRipple
                            sx={{
                              width: "100%",
                              backgroundColor: "#EFEFEF",
                              color: "#000",
                              textTransform: "uppercase",
                              fontSize: "15px",
                              padding: "7px",
                              borderRadius: 0,
                              margin: "0 5px 0 0",
                              border: "1px solid #9e9e9e",
                              ":hover": {
                                backgroundColor: "#EFEFEF",
                              },
                              ":focus": {
                                backgroundColor: "#e1e1e1",
                              },
                            }}
                          >
                            edit
                          </Button>
                          <Button
                            disableRipple
                            disableTouchRipple
                            sx={{
                              width: "100%",
                              backgroundColor: "#EFEFEF",
                              color: "#000",
                              textTransform: "uppercase",
                              fontSize: "15px",
                              padding: "7px",
                              borderRadius: 0,
                              margin: "0 5px 0 0",
                              border: "1px solid #9e9e9e",
                              ":hover": {
                                backgroundColor: "#EFEFEF",
                              },
                              ":focus": {
                                backgroundColor: "#e1e1e1",
                              },
                            }}
                          >
                            save
                          </Button>
                        </Box>
                      </Box>
                    </Box>
                  )}
                </Grid>
              )}
              <Grid item xs={12} lg={6}>
                <Box className="edit-stake-right">
                  <Typography
                    sx={{
                      fontSize: "17px",
                      color: "#f36c21",
                      textAlign: "left",
                      textTransform: "capitalize",
                      my: 1,
                    }}
                    component="h5"
                  >
                    edit stakes
                  </Typography>
                  <Box
                    className="edit-stake-card"
                    sx={{
                      backgroundColor: "#fff",
                      border: "2px solid #f36c21",
                      borderRadius: "10px",
                      overflow: "hidden",
                      padding: "5px 15px",
                    }}
                  >
                    <Box
                      className="grid-container"
                      sx={{
                        display: "grid",
                        gridTemplateColumns: "auto auto auto",
                        gridGap: "15px",
                        padding: "10px 0",
                      }}
                    >
                      {Object.entries(getStakeData?.data || {})?.map(
                        (stakedata, idx: number) => (
                          <Box
                            className="grid-child-container"
                            key={stakedata?.[0] + idx}
                          >
                            <Button
                              disableRipple
                              disableTouchRipple
                              sx={{
                                width: "100%",
                                fontSize: "14px",
                                padding: "10px",
                                borderRadius: "5px",
                                backgroundColor: "#e0e0e0",
                                borderColor: "#e0e0e0",
                                color: "#000",
                                ":hover": {
                                  backgroundColor: "#e0e0e0",
                                },
                              }}
                            >
                              {stakedata?.[1]}
                            </Button>
                          </Box>
                        ),
                      )}
                    </Box>

                    <Box className="edit-button-holder">
                      <Button
                        onClick={handleOpenEditStake}
                        disableRipple
                        disableTouchRipple
                        sx={{
                          width: "100%",
                          backgroundColor: "#EFEFEF",
                          color: "#000",
                          textTransform: "uppercase",
                          fontSize: "15px",
                          padding: "7px",
                          borderRadius: 0,
                          margin: "0 5px 0 0",
                          border: "1px solid #9e9e9e",
                          ":hover": {
                            backgroundColor: "#EFEFEF",
                          },
                          ":focus": {
                            backgroundColor: "#e1e1e1",
                          },
                        }}
                      >
                        edit
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
          {/* edit stakeholder */}
        </Container>
      </Box>
    </Box>
  )
}
export default StakeSettings
