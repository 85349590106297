import { Box, Container, List, ListItem } from "@mui/material"
import { FC } from "react"
import { Link } from "react-router-dom"
// import breadcrumbarrow from "https://s3buket.blr1.cdn.digitaloceanspaces.com/images/breadcrumb-arrow.svg"

interface ProfitLossBreadcrumbProps {
  title: string
}

const firstli = {
  width: "auto",
  padding: "0px 0px 0px 20px",
  position: "relative",
}

const style = {
  width: "auto",
  padding: "0px 0px 0px 20px",
  position: "relative",

  ":before": {
    content: "''",
    backgroundImage: `url('${"https://s3buket.blr1.cdn.digitaloceanspaces.com/images/breadcrumb-arrow.svg"}')`,
    width: "9px",
    height: "9px",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "50%",
    top: "7px",
    position: "absolute",
    left: "6px",
  },
}

const ProfiLossBreadcrumb: FC<ProfitLossBreadcrumbProps> = ({ title }) => {
  return (
    <Box sx={{ backgroundColor: "#272727", padding: "10px 0" }}>
      <Container maxWidth="lg">
        <List sx={{ display: "flex", alignItems: "flex-start", p: 0 }}>
          <ListItem sx={firstli}>
            <Link to="/" className="text-[#fff] text-[14px]">
              Home
            </Link>
          </ListItem>
          <ListItem sx={style}>
            <Link to="/profit-loss" className="text-[#fff] text-[14px]">
              Profit & Loss
            </Link>
          </ListItem>
          <ListItem sx={style}>
            <Link to="#" className="text-[#fff] text-[14px]">
              {title}
            </Link>
          </ListItem>
        </List>
      </Container>
    </Box>
  )
}
export default ProfiLossBreadcrumb
