import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fade,
  Grid,
  TextField,
  Typography,
} from "@mui/material"
import { Dispatch, FC, SetStateAction, useEffect, useState } from "react"
import CloseIcon from "@mui/icons-material/Close"
import {
  useGetstakebuttonQuery,
  useSetstakebuttonMutation,
} from "../../../../api/mainApi/mainApiSlice"
import snackBarUtil from "../../../../utils/snackbar"
import { SnackbarContent } from "notistack"

interface EditStakeModalProps {
  editstakeOpen: boolean
  setEditStakeOpen: Dispatch<SetStateAction<boolean>>
}

const EditStakeModal: FC<EditStakeModalProps> = ({
  editstakeOpen,
  setEditStakeOpen,
}) => {
  const [setStakeTrigger, { data: setStakeData }] = useSetstakebuttonMutation()
  const {
    data: getStakeData,
    isError,
    isSuccess,
    error,
  } = useGetstakebuttonQuery({})
  const [stackValue, setStackValue] = useState<number[]>([])

  const handleClose = () => {
    setEditStakeOpen(!editstakeOpen)
  }

  const handleChangeStake = (e: any, ind: number) => {
    const data = [...stackValue]
    if (e.match(/^[0-9]{0,12}$/)) {
      data[ind] = e
      setStackValue(data)
    }
  }

  useEffect(() => {
    if (getStakeData?.data) {
      const data: number[] = []
      let key: keyof getstakeData
      for (key in getStakeData?.data) {
        data.push(getStakeData?.data[key])
      }
      setStackValue(data)
    }
  }, [getStakeData])

  const updateStakeVal = () => {
    setStakeTrigger({
      stack1: Number(stackValue[0]),
      stack2: Number(stackValue[1]),
      stack3: Number(stackValue[2]),
      stack4: Number(stackValue[3]),
      stack5: Number(stackValue[4]),
      stack6: Number(stackValue[5]),
      stack7: Number(stackValue[6]),
      stack8: Number(stackValue[7]),
      stack9: Number(stackValue[8]),
      stack10: Number(stackValue[9]),
    })
    setEditStakeOpen(!editstakeOpen)
  }

  useEffect(() => {
    if (setStakeData?.status) {
      snackBarUtil.success(
        <SnackbarContent role="alert">
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Typography
              sx={{ color: "#fff", fontSize: "16px", textAlign: "left" }}
            >
              Success!
            </Typography>
            <Typography
              sx={{ color: "#fff", fontSize: "12px", textAlign: "left" }}
            >
              {setStakeData?.message}
            </Typography>
          </Box>
        </SnackbarContent>,
      )
    }
  }, [setStakeData])

  type cancelsavebtnType = string[]

  const cancelsavebtn: cancelsavebtnType = ["cancel", "save"]

  return (
    <Dialog
      open={editstakeOpen}
      TransitionComponent={Fade}
      aria-describedby="stake-setting-edit-stake"
      fullWidth
      maxWidth="md"
      sx={{
        ".MuiDialog-paper": {
          position: "absolute",
          top: 0,
          backgroundColor: "#fff",
          borderRadius: "8px",
        },
      }}
    >
      <DialogTitle
        sx={{
          backgroundColor: "#f36c21",
          padding: "16px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography
            sx={{
              textTransform: "capitalize",
              fontSize: "16px",
              color: "#000",
              fontWeight: "600",
            }}
          >
            Edit Stake
          </Typography>
          <Button
            disableRipple
            disableTouchRipple
            onClick={handleClose}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              minWidth: "unset",
              backgroundColor: "transparent",
              ":hover": {
                backgroundColor: "transparent",
              },
            }}
          >
            <CloseIcon sx={{ color: "#fff" }} />
          </Button>
        </Box>
      </DialogTitle>

      <DialogContent sx={{ p: 2, my: 2 }}>
        <Grid container spacing={1}>
          {stackValue?.map((stack, index) => (
            <Grid item xs={3} key={index}>
              <TextField
                id="outlined-number"
                type="number"
                value={stack}
                placeholder="value"
                onChange={e => handleChangeStake(e.target.value, index)}
                variant="outlined"
                size="small"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                    },
                  },
                  " .MuiInputBase-input": {
                    margin: 0,
                    color: "#000",
                    padding: "5px",
                  },

                  border: "1px solid #ccc",
                  borderRadius: 0,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          ))}
        </Grid>
      </DialogContent>

      <DialogActions
        sx={{
          display: "flex",
          gap: "10px",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {cancelsavebtn?.map(btntype => {
          return (
            <Button
              key={btntype}
              onClick={btntype === "cancel" ? handleClose : updateStakeVal}
              sx={{
                backgroundColor: "#f36c21",
                padding: "10px 15px",
                color: "#fff",
                fontSize: "15px",
                textTransform: "capitalize",
                minWidth: "unset",
                lineHeight: "17px",
                border: "1px solid #f36c21",
                width: "100%",

                ":hover": {
                  color: "#f36c21",
                  border: "1px solid #f36c21",
                  background:
                    "linear-gradient(0deg,hsla(0,0%,4%,.5) 50%,#323232)",
                  backgroundColor: "#1e1e1e",
                },
              }}
            >
              {btntype}
            </Button>
          )
        })}
      </DialogActions>
    </Dialog>
  )
}
export default EditStakeModal
