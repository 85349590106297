import { Box, Button, Typography, useMediaQuery } from "@mui/material"
import type { FC, Dispatch, SetStateAction } from "react"
import { useState } from "react"
import SportsEventOpenBetsAccordion from "./OpenBetsAccordion"
import SportsMatchedBetsAccordion from "./SportsMatchedBetsAccordion"
import BetModal from "../../../common/betmodal/BetModal"
import type { BetPlace } from "./SportsEventDetail"

interface BetSlipTabProps {
  Modalopen: boolean
  handleClose: () => void
  matchid: string
  betPlaceData: BetPlace
  setBetPlacedData: Dispatch<SetStateAction<BetPlace>>
  setModalOpen: Dispatch<SetStateAction<boolean>>
}

const BetSlipTab: FC<BetSlipTabProps> = ({
  Modalopen,
  matchid,
  handleClose,
  betPlaceData,
  setBetPlacedData,
  setModalOpen
}) => {
  const tabNames = ["Betslip", "Open Bets"]
  const [activeTab, setActiveTab] = useState(0)
  const isDesktop = useMediaQuery("(min-width:769px)")

  const handleTabChange = (index: number) => {
    setActiveTab(index)
  }

  return (
    <Box
      className="bet-slip-tab-sec"
      sx={{
        backgroundColor: "#272727",
        borderRadius: "5px",
        padding: "10px 0",
        width: "100%",
        height: "100%",
      }}
    >
      <Box
        className="tabholder"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <Box className="tabs" sx={{ display: "flex", gap: "5px" }}>
          {tabNames.map((tab, idx) => (
            <Button
              disableRipple
              disableTouchRipple
              key={tab + idx}
              onClick={() => handleTabChange(idx)}
              className="tab"
              sx={{
                transition: "all .3s linear",
                borderBottom: `2px solid ${activeTab === idx ? "#f36c21" : "#fff"}`,
                borderRadius: "0",
                color: "#fff",
                ":hover": {
                  background: "none",
                },
              }}
            >
              <Typography
                component="p"
                sx={{
                  padding: "8px 16px",
                  color: "#fff",
                  fontSize: "11px",
                  textAlign: "center",
                }}
              >
                {tab}
              </Typography>
            </Button>
          ))}
        </Box>
        <Box
          className="tab-content"
          sx={{
            marginTop: "20px",
            borderRadius: "5px",
            width: "100%",
            padding: "0px",
          }}
        >
          {activeTab === 0 && (
            <Box className="tabpanel1">
              <Box className="betmodalholder">
                {isDesktop && Modalopen && (
                  <BetModal
                    handleClose={handleClose}
                    betPlaceData={betPlaceData}
                    setBetPlacedData={setBetPlacedData}
                    setModalOpen={setModalOpen}
                  />
                )}
              </Box>
            </Box>
          )}
          {activeTab === 1 && (
            <Box className="tabpanel2">
              <Box sx={{ my: 1, p: 0.5 }}>
                <SportsEventOpenBetsAccordion matchid={matchid} />
              </Box>
              <Box sx={{ my: 1, p: 0.5 }}>
                {/* <SportsMatchedBetsAccordion /> */}
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  )
}

export default BetSlipTab
