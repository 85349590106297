import type { FC } from "react";
import React from "react";
import { PaymentDetailContainer, SpacedBetween } from "./styledComponents";
import { CopyComp } from "./BankInfoComponent";

interface Props {
  bankDetails?: PaymentMethod | undefined;
  phonePe: boolean
}

const BankInfoComponentNew: FC<Props> = ({ bankDetails, phonePe }) => {
  if (!bankDetails) return <></>;

  const { bankName, accountHolderName, accountNumber, ifsc } = bankDetails;
  return (
    <>
      {
        phonePe ? <PaymentDetailContainer>
          <SpacedBetween>
            Mobile No.
            <span>
              {accountHolderName} <CopyComp str={accountHolderName} />
            </span>
          </SpacedBetween>
        </PaymentDetailContainer> : <PaymentDetailContainer>
          <SpacedBetween>
            Bank Name
            <span>
              {bankName} <CopyComp str={bankName || ""} />
            </span>
          </SpacedBetween>
          <SpacedBetween>
            Account Number
            <span>
              {accountNumber} <CopyComp str={accountNumber} />
            </span>
          </SpacedBetween>
          <SpacedBetween>
            IFSC Code
            <span>
              {ifsc} <CopyComp str={ifsc || ""} />
            </span>
          </SpacedBetween>
          <SpacedBetween>
            Account Holder Name
            <span>
              {accountHolderName} <CopyComp str={accountHolderName} />
            </span>
          </SpacedBetween>
        </PaymentDetailContainer>
      }
    </>

  );
};

export default BankInfoComponentNew;
