// import cricimg from 'https://s3buket.blr1.cdn.digitaloceanspaces.com/images/cricket.webp';

export const sportsbardata = [

    {
        name: 'fantasy pro',
        icon: 'https://s3buket.blr1.cdn.digitaloceanspaces.com/images/cricket.webp',
        link: '/'
    },

    {
        name: 'greyhound racing',
        icon: 'https://s3buket.blr1.cdn.digitaloceanspaces.com/images/cricket.webp',
        link: "/racing-category/GREY_HOUND_RACING"
    },
    {
        name: 'sportbook',
        icon: 'https://s3buket.blr1.cdn.digitaloceanspaces.com/images/cricket.webp'
    },
    {
        name: 'live casino',
        link: '/live-casino'
    },
    {
        name: 'crash games',
        link: '/crash-games'

    },
    {
        name: 'live card',
        link: '/live-card'

    },
    {
        name: 'slot games',
        link: '/slot-games'

    },
    {
        name: 'matka',
        link: '/matka'

    },
]