import { Box, Button, Container, Grid, Typography } from "@mui/material"
import { useState } from "react"
import AccordionForMatch from "./AccordionForMatch"
import MatchBetCard from "./MatchBetCard"

const arr = ["market", "open bets"]

const MarketOpenBetTab = () => {
  const [active, setActive] = useState(0)
  const handleActive = (index: number) => {
    setActive(index)
  }

  return (
    <Box className="tabs">
      <Box
        className="tabHolder"
        sx={{
          display: "flex",
          gridGap: "10px",
          backgroundColor: "#fff",
          padding: "10px 0 0 10px",
          margin: "0 0 8px",
        }}
      >
        {arr?.map((tabdata, idx) => (
          <Button
            disableRipple
            disableTouchRipple
            onClick={() => handleActive(idx)}
            key={tabdata + idx}
            sx={{
              background: "linear-gradient(0deg,hsla(0,0%,4%,.5) 50%,#323232)",
              border: "1px solid #4c4c4c",
              backgroundColor: "#1e1e1e",
              borderRadius: "30px",
              padding: "8px",
              fontSize: "16px",
              textTransform: "capitalize",
              maxWidth: "25%",
              width: "100%",
              color: `${active === idx ? "#fa7b04" : "#fff"}`,
              ":hover": {
                backgroundColor: "#1e1e1e",
              },
            }}
          >
            {tabdata == "open bets" ? "open bets (0)" : tabdata}
          </Button>
        ))}
      </Box>
      {active === 0 && (
        <Box className="tabpanel1">
          <Box className="matchcardholder" sx={{ p: 1 }}>
            <MatchBetCard />
          </Box>
        </Box>
      )}

      {active === 1 && (
        <Box className="tabpanel2">
          <Box className="matchcardholder" sx={{ p: 1 }}>
            <Box sx={{ my: 2 }}>
              <Typography
                sx={{
                  color: "#fff",
                  fontSize: "18px",
                  fontWeight: "600",
                  pb: 2,
                }}
              >
                Upcoming Races
              </Typography>
              <AccordionForMatch />
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  )
}

export default MarketOpenBetTab
