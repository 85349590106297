import {
  Box,
  Button,
  CircularProgress,
  MenuItem,
  Radio,
  Select,
  Typography,
  styled,
} from "@mui/material";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { WithdrawInput } from "./styledComponent";
import {
  CardContainerContainer,
  StyledButtonSmall,
} from "../Deposit/styledComponents";
import Card from "../Deposit/card";
import { savedColumns } from "./savedColumns";
import CustomizedDialogs from "./CustomizedDialogs";
import { useGetClientBankMutation, useGetRequestStackMutation, useGetSaveClientMutation, useGetSelfWithdrawMutation, useGetWithtypeMutation } from "../../../api/mainApi/mainApiSlice";
import ActivityTable from "../Deposit/ActivityTable";
import { colorHex } from "../../../utils/constants";

const err = {
  invalidName:
    "The Account Holder Name field may only contain alphabetic characters as well as spaces",
  noName: "The Account Holder Name field is required",
  noBank: "The Bank Name field is required",
  noIfsc: "The IFSC field is required",
  invalidIfsc: "The IFSC field format is invalid",
  noAccount: "The Account Number field is required",
  invalidAccount: "The Account Number field may only contain 8 to 16 digits",
  invalidUpi: "The UPI field format is invalid",
  noAmount: "The Amount field is required",
  invalidAmount: "The Amount field may only contain numeric characters",
};

const LabelText = styled(Typography)(({ theme }) => ({
  color: "text.secondary",
  fontSize: "0.8rem",
  textAlign: "left",
  marginBlock: 4,
}));

const regexByType = {
  bank: /^[0-9]*$/,
  paytm: /^[0-9]*$/,
  upi: /^[a-zA-Z0-9@._-]*$/,
};

export function WithdrawForm({
  getWithdrawList,
}: {
  getWithdrawList: any;
}) {
  const [saveWithdrawData, setSaveWithdrawData] =
    useState<SelfWithdrawPayload | null>(null);



  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const [savedCheck, setSavedCheck] = useState("");

  const [getSelfWithdraw, {data:selfWithdraw}] = useGetSelfWithdrawMutation();
  const [getSaveClient, {data: saveClient}] = useGetSaveClientMutation();
  const [getStack, {data: stack}] = useGetRequestStackMutation();
  const [getTypes, {data: withdrawTypes}] = useGetWithtypeMutation();
  const [getSavedInfo, {data: savedInfo}] = useGetClientBankMutation();

  const {
    values,
    handleChange,
    handleSubmit,
    errors,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: {
      accountHolderName: "",
      bankName: "",
      accountType: "savings",
      accountNumber: "",
      ifsc: "",
      amount: 0,
      withdrawType: "",
      withdrawMode: "NORMAL",
    },
    validate: (values) => {
      const newError = {
        accountHolderName: values.accountHolderName
          ? values.accountHolderName?.match(/^[a-zA-Z ]*$/)
            ? undefined
            : err.invalidName
          : err.noName,
        accountNumber: values.accountNumber
          ? values.withdrawType.toLowerCase() === "upi"
            ? values.accountNumber?.match(
              /^[a-zA-Z0-9.-]{2,256}@[a-zA-Z][a-zA-Z]{2,64}$/
            )
              ? undefined
              : err.invalidUpi
            : values.withdrawType.toLowerCase() === "paytm"
              ? values.accountNumber?.match(/^[0-9]{10}$/)
                ? undefined
                : "Mobile no should be 10 digits."
              : values.accountNumber?.match(/^[0-9]{8,16}$/)
                ? undefined
                : err.invalidAccount
          : err.noAccount,

        amount: values.amount
          ? values.amount.toString()?.match(/^[0-9]*$/)
            ? undefined
            : err.invalidAmount
          : err.noAmount,
        bankName:
          values.withdrawType.toLowerCase() !== "bank"
            ? undefined
            : values.bankName
              ? values.bankName.match(/^(?=.*[a-zA-Z])[a-zA-Z\d ]*$/)
                ? undefined
                : "Bank name should contain atleast one alphabet."
              : err.noBank,
        
      };

      return Object.fromEntries(
        Object.entries(newError).filter(([_, v]) => v != null)
      );
    },
    validateOnChange: true,

    onSubmit: async (values) => {
      const newValues: SelfWithdrawPayload = { ...values };
      setLoading(true);
      newValues.ifsc = newValues.ifsc?.toUpperCase();
      if (newValues.withdrawType.toLowerCase() !== "bank") {
        newValues.accountType = "";
        newValues.ifsc = "";
        newValues.bankName = "";
      }
      newValues.withdrawType =
        withdrawTypes?.data?.find((item:any) => item.withdrawType === values.withdrawType)
          ?.id || "";
      setSaveWithdrawData(newValues);
      getSelfWithdraw(newValues);
      
      setLoading(false);
    },
  });

  useEffect(()=>{
    if (selfWithdraw) {
      if (selfWithdraw?.data?.bankExist === false) {
        setOpen(true);
      }
      resetForm();
      setSavedCheck("");
      getWithdrawList();
    }
  }, [selfWithdraw])

  const saveClientBankDetail = async () => {
    if (loadingSave) return;
    if (saveWithdrawData) {
      getSaveClient(saveWithdrawData) 
    }
  };

 
  useEffect(() => {
    getTypes();
    getStack();
    getSavedInfo();
  }, []);


  useEffect(()=>{
    if (saveClient) {
      setOpen(false);
      getSavedInfo();
    }
  }, [saveClient])



  return (
    <>
      <CustomizedDialogs
        open={open}
        handleClose={() => setOpen(false)}
        title="Save Details"
      >
        <Box textAlign={"center"}>
          <Typography my={5}>Do you want to save these details ??</Typography>
          <Button
            variant="contained"
            onClick={() => saveClientBankDetail()}
            endIcon={loadingSave && <CircularProgress size={"1rem"} />}
            disabled={loadingSave}
          >
            Save
          </Button>
        </Box>
      </CustomizedDialogs>
      <form onSubmit={handleSubmit}>
        <Box
          display={"flex"}
          flexDirection={{ xs: "column", md: "row" }}
          alignItems={"center"}
          justifyContent={"start"}
          
        >
          <Box>
            <Typography m={1} variant="caption">
              Amount
            </Typography>
            <WithdrawInput
              value={Number(values.amount) || ""}
              name="amount"
              error={Boolean(errors.amount)}
              helperText={errors.amount}
              onChange={handleChange}
              onKeyDown={(e) => {
                if ([".", " "].includes(e.key)) {
                  e.preventDefault();
                  e.stopPropagation();
                }
              }}
              margin="dense"
              placeholder="Amount"
            />
          </Box>
          <Box flexWrap={"wrap"} display={"flex"} mt={3} ml={3}>
            {stack?.data?.map(({ key, value }) => (
              <StyledButtonSmall
                key={`${key + value}-button`}
                onClick={() =>
                  setFieldValue("amount", Number(values.amount) + Number(value))
                }
              >
                {key}
              </StyledButtonSmall>
            ))}
          </Box>
        </Box>
        <Box width={"100%"} my={2}>
          <Typography variant="caption" m={1}>
            Withdraw Type
          </Typography>
          <WithdrawInput
            sx={{ width: "100% !important" }}
            value={values.withdrawMode}
            name="withdrawMode"
            placeholder="Select Withdraw Type"
            onChange={handleChange}
            error={Boolean(errors.withdrawMode)}
            helperText={errors.withdrawMode}
            margin="dense"
            select
          >
            <MenuItem sx={{ fontSize: "0.8rem" }} value="NORMAL">
              NORMAL
            </MenuItem>
            <MenuItem sx={{ fontSize: "0.8rem" }} value="INSTANT">
              INSTANT
            </MenuItem>
          </WithdrawInput>
        </Box>
        <CardContainerContainer>
          {withdrawTypes?.data?.map((elem:any) => {
            if (!elem.active) return <></>;
            return (
              <Card
                selected={values.withdrawType === elem.withdrawType}
                details={{
                  methodName: elem.withdrawType,
                  logo: elem.image,
                }}
                handleClick={() => {
                  setFieldValue("accountHolderName", "");
                  setFieldValue("bankName", "");
                  setFieldValue("accountType", "");
                  setFieldValue("accountNumber", "");
                  setFieldValue("ifsc", "");
                  setFieldValue("withdrawType", elem.withdrawType);
                }}
              />
            );
          })}
        </CardContainerContainer>
        <Box
          sx={{
            display: "flex",
            flexDirection: {
              xs: "column",
              lg: "row",
            },
            flexWrap: "wrap",
            alignItems: {
              // lg: "baseline",
            },
            justifyContent: "center",
            gap: 1,
            rowGap: 2,
            my: { lg: 4 },
          }}
        >
          {values.withdrawType.toLowerCase() === "bank" && (
            <>
              <Box>
                <Typography variant="caption" m={1}>
                  Account Number
                </Typography>
                <WithdrawInput
                  value={values.accountNumber}
                  name="accountNumber"
                  onChange={(e) =>
                    e.target.value.match(regexByType.bank) && handleChange(e)
                  }
                  error={Boolean(errors.accountNumber)}
                  helperText={errors.accountNumber}
                  margin="dense"
                  placeholder="Account Number"
                />
              </Box>
              <Box>
                <Typography variant="caption" m={1}>
                  Account Holder Name
                </Typography>
                <WithdrawInput
                  value={values.accountHolderName.trimStart()}
                  name="accountHolderName"
                  onChange={handleChange}
                  error={Boolean(errors.accountHolderName)}
                  helperText={errors.accountHolderName}
                  margin="dense"
                  placeholder="Account Holder Name"
                />
              </Box>
              <Box>
                <Typography variant="caption" m={1}>
                  Bank Name
                </Typography>
                <WithdrawInput
                  value={values.bankName}
                  name="bankName"
                  onChange={handleChange}
                  error={Boolean(errors.bankName)}
                  helperText={errors.bankName}
                  margin="dense"
                  placeholder="Bank Name"
                />
              </Box>
              <Box>
                <Typography variant="caption" m={1}>
                  IFSC
                </Typography>
                <WithdrawInput
                  value={values.ifsc.toUpperCase()}
                  name="ifsc"
                  onChange={handleChange}
                  error={Boolean(errors.ifsc)}
                  helperText={errors.ifsc}
                  margin="dense"
                  placeholder="IFSC Code"
                />
              </Box>
              <Box>
                <Typography variant="caption" m={1}>
                  Account Type / Currency
                </Typography>
                <WithdrawInput
                  value={values.accountType}
                  name="accountType"
                  sx={{}}
                  onChange={handleChange}
                  error={Boolean(errors.accountType)}
                  helperText={errors.accountType}
                  margin="dense"
                  select
                >
                  <MenuItem sx={{ fontSize: "0.8rem" }} value="savings">
                    Savings
                  </MenuItem>
                  <MenuItem sx={{ fontSize: "0.8rem" }} value="current">
                    Current
                  </MenuItem>
                </WithdrawInput>
              </Box>
            </>
          )}
          {values.withdrawType.toLowerCase() === "upi" && (
            <>
              <Box>
                <Typography variant="caption" m={1}>
                  Upi Id
                </Typography>
                <WithdrawInput
                  value={values.accountNumber}
                  name="accountNumber"
                  onChange={(e) =>
                    e.target.value.match(regexByType.upi) && handleChange(e)
                  }
                  error={Boolean(errors.accountNumber)}
                  helperText={errors.accountNumber}
                  margin="dense"
                  placeholder="Account Number"
                />
              </Box>
              <Box>
                <Typography variant="caption" m={1}>
                  Account Holder Name
                </Typography>
                <WithdrawInput
                  value={values.accountHolderName.trimStart()}
                  name="accountHolderName"
                  onChange={handleChange}
                  error={Boolean(errors.accountHolderName)}
                  helperText={errors.accountHolderName}
                  margin="dense"
                  placeholder="Account Holder Name"
                />
              </Box>
            </>
          )}

          {(values.withdrawType.toLowerCase() === "g pay" || values.withdrawType.toLowerCase() === "phone pe") && (
            <>
              <Box>
                <Typography variant="caption" m={1}>
                  Mobile Number
                </Typography>
                <WithdrawInput
                  value={values.accountNumber}
                  name="accountNumber"
                  onChange={(e) =>
                    e.target.value.match(regexByType.paytm) && handleChange(e)
                  }
                  error={Boolean(errors.accountNumber)}
                  helperText={errors.accountNumber}
                  margin="dense"
                  placeholder="Mobile Number"
                />
              </Box>
              <Box>
                <Typography variant="caption" m={1}>
                  Account Holder Name
                </Typography>
                <WithdrawInput
                  value={values.accountHolderName.trimStart()}
                  name="accountHolderName"
                  onChange={handleChange}
                  error={Boolean(errors.accountHolderName)}
                  helperText={errors.accountHolderName}
                  margin="dense"
                  placeholder="Account Holder Name"
                />
              </Box>
            </>
          )}
          {values.withdrawType.toLowerCase() === "paytm" && (
            <>
              <Box>
                <Typography variant="caption" m={1}>
                  Mobile Number
                </Typography>
                <WithdrawInput
                  value={values.accountNumber}
                  name="accountNumber"
                  onChange={(e) =>
                    e.target.value.match(regexByType.paytm) && handleChange(e)
                  }
                  error={Boolean(errors.accountNumber)}
                  helperText={errors.accountNumber}
                  margin="dense"
                  placeholder="Mobile Number"
                />
              </Box>
              <Box>
                <Typography variant="caption" m={1}>
                  Account Holder Name
                </Typography>
                <WithdrawInput
                  value={values.accountHolderName.trimStart()}
                  name="accountHolderName"
                  onChange={handleChange}
                  error={Boolean(errors.accountHolderName)}
                  helperText={errors.accountHolderName}
                  margin="dense"
                  placeholder="Account Holder Name"
                />
              </Box>
            </>
          )}
        </Box>
        {savedInfo?.data?.find(
          (item:any) => item.withdrawType === values.withdrawType
        ) && (
            <Box my={2}>
              <ActivityTable
                onRowClick={(row: any) => {
                  if (row) {
                    setSavedCheck(row.id);
                    setFieldValue("accountHolderName", row.accountHolderName);
                    setFieldValue("bankName", row.bankName);
                    setFieldValue("accountType", row.accountType);
                    setFieldValue("accountNumber", row.accountNumber);
                    setFieldValue("ifsc", row.ifsc);
                    setFieldValue("withdrawType", row.withdrawType);
                  }
                }}
                columns={savedColumns.filter((column) => {
                  if (values.withdrawType.toLowerCase() !== "bank") {
                    if (
                      ["accountHolderName", "accountNumber", "action"].includes(
                        column.id
                      )
                    ) {
                      return true;
                    } else {
                      return false;
                    }
                  } else {
                    return true;
                  }
                })}
                minHeight={1}
                rows={savedInfo?.data?.filter((item:any) => item.withdrawType === values.withdrawType)
                  .map((item:any) => {
                    const newItem: any = { ...item };
                    newItem["action"] = (
                      <Radio checked={newItem.id === savedCheck} />
                    );
                    return newItem;
                  })}
              />
            </Box>
          )}
        <Box textAlign={"center"}>
          <Button
            color="secondary"
            disabled={loading}
            type="submit"
            endIcon={loading && <CircularProgress size={"1rem"} />}
            variant="contained"
            disableElevation
            sx={{
              height: 48,
              borderRadius: "30px",
              width: 156,
              display: "block",
              color: "#fff",
              textTransform: "uppercase",
              fontSize: { md: "13px", xs: "12px" },
              textAlign: "center",
              backgroundColor: "#fc6321",
              fontWeight: "600",
              minHeight: { md: "40px", xs: "35px" },
              border: "2px solid #fc6321",
              // maxWidth: 314,
              margin:"15px auto 0",
              maxWidth:"100%",
              ":hover": {
                backgroundColor: "#fc6321",
                border: "2px solid #fc6321",
              },
            }}
          >
            Submit
          </Button>
        </Box>
      </form>
      <Box
        py={3}
        mt={3}
        borderTop={{ xs: `1px solid ${colorHex.borderLine}`, lg: "none" }}
      >
        <Box display="flex" alignItems="center">
          <LabelText>Show</LabelText>
          <Select
            defaultValue={5000}
            // onChange={handlePageSizeChange}
            // value={pageSize}
            sx={{
              mx: 0.2,
              minWidth: 100,
              textAlign: "start",
              fontSize: { xs: "0.8rem", lg: "1rem" },
              maxHeight: { xs: 30, lg: 36 },
            }}
          >
            <MenuItem value={25}>25</MenuItem>
            <MenuItem value={50}>50</MenuItem>
            <MenuItem value={100}>100</MenuItem>
            <MenuItem value={500}>500</MenuItem>
            <MenuItem value={1000}>1000</MenuItem>
            <MenuItem value={5000}>5000</MenuItem>
          </Select>
          <LabelText>Entries</LabelText>
        </Box>
      </Box>
    </>
  );
}
