import { Box, Container, Typography } from "@mui/material"
import SecondHeadingBar from "../../../common/SecondHeadingBar"
import { IoWarning } from "react-icons/io5"

const RulesAndRegulation = () => {
  return (
    <Box className="rule-and-regulation">
      <SecondHeadingBar title="Rules And Regulations" />
      <Box
        className="rule-and-regulation-content"
        sx={{ padding: "10px 30px" }}
      >
        <Container maxWidth="xl">
          <Box
            sx={{
              borderRadius: "5px",
              display: "flex",
              padding: "15px",
              background: "linear-gradient(0deg,hsla(0,0%,4%,.5) 50%,#323232)",
              borderBottom: "1px solid #333",
              borderTop: "1px solid #4c4c4c",
              backgroundColor: "#1e1e1e",
            }}
          >
            <Box className="icon-alert" sx={{ width: "30px" }}>
              <IoWarning color="#fff" fontSize={25} />
            </Box>
            <Box className="alert-text-box" sx={{ ml: 1 }}>
              <Typography
                className="note"
                component="p"
                sx={{ fontSize: "14px", color: "#fff", fontWeight: 600 }}
              >
                Note:
              </Typography>
              <Typography
                className="note"
                component="p"
                sx={{ fontSize: "14px", color: "#fff", fontWeight: 600 }}
              >
                Players using VPN and login from different IP frequently may
                result to void bets.
              </Typography>
              <Typography
                className="note"
                component="p"
                sx={{ fontSize: "14px", color: "#fff", fontWeight: 600 }}
              >
                And on the basis of different IP from multiple city we can
                suspend the account and void bets.
              </Typography>
            </Box>
          </Box>
        </Container>
      </Box>
    </Box>
  )
}
export default RulesAndRegulation
